// Function to decode a JWT token
export function parseJwt(token) {
  try {
    // Split the token into its parts (header, payload, signature)
    const base64Url = token.split(".")[1];
    // Decode the payload from base64
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    // Parse the JSON payload to access claims
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
}

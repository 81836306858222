import React, { useEffect, useState } from "react";
import { message, Table, Button, Typography, Space, App } from "antd";
import {
  AiTasksApi,
  Configuration,
  HitlApi,
} from "../../../../lib/src/api_client";
import { useAuth0 } from "@auth0/auth0-react";
import { apiBasePath } from "../../config";
import HitlRequestFormModal from "../hitl/hitlResponseFormModal";
import { Link } from "react-router-dom";
import TimestampDisplay from "../misc/timestamp";

const HitlRequestsTable = ({ setRefreshHitlRequestsIndicator }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedTaskObject, setSelectedTaskObject] = useState(null);
  const [schema, setSchema] = useState(null);
  const [taskIds, setTaskIds] = useState([]);
  const [types, setTypes] = useState([]);
  const { message, notification } = App.useApp();

  const fetchRequests = async () => {
    setLoading(true);
    const api = new HitlApi(
      new Configuration({
        accessToken: await getAccessTokenSilently(),
        basePath: apiBasePath,
      }),
    );

    try {
      const response = await api.getHitlRequestsAllV1HitlRequestsGet();
      // set unique task ids and types
      const taskIds = new Set();
      const types = new Set();
      response.data.forEach((request) => {
        taskIds.add(request.request.task_id);
        types.add(request.request.request_type);
        request.request_id = request.request.request_id;
      });
      setRequests(response.data);
      setTaskIds(Array.from(taskIds));
      setTypes(Array.from(types));
    } catch (error) {
      console.log("Api error: ", error);
      setError(error);
      message.error("Could not fetch requests: " + error);
    } finally {
      setLoading(false);
    }
  };

  async function getTaskInfo(taskId) {
    const tasksApi = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    try {
      const response = await tasksApi.getAiTaskV1AiTasksAiTaskIdGet(taskId);
      return response.data;
    } catch (error) {
      message.error("Could not fetch task: " + error);
      return null;
    }
  }

  async function getAndSetSelectedTaskObject(taskId) {
    const task = await getTaskInfo(taskId);
    setSelectedTaskObject(task);
  }

  useEffect(() => {
    fetchRequests();
  }, [getAccessTokenSilently]);

  const handleRespondClick = async (record) => {
    getAndSetSelectedTaskObject(record.request.task_id);
    setSelectedRequest(record.request);
    setSchema(record.response_schema);
  };

  const getRequestContent = (record) => {
    if (record.request.request_type === "text_input") {
      return record.request.text;
    } else if (record.request.request_type === "questions_list") {
      return record.request.questions.join("; ");
    }
  };

  const columns = [
    {
      title: "Timestamp",
      dataIndex: ["request", "timestamp"],
      key: "timestamp",
      width: "10%",
      sorter: (a, b) =>
        new Date(b.request.timestamp).valueOf() -
        new Date(a.request.timestamp).valueOf(),
      render: (text) => <TimestampDisplay timestamp={text} />,
    },
    {
      title: "Connection ID",
      dataIndex: ["request", "connection_id"],
      key: "connection_id",
      width: "10%",
      render: (text) => <Typography.Text code>{text}</Typography.Text>,
    },
    {
      title: "Task ID",
      dataIndex: ["request", "task_id"],
      key: "task_id",
      width: "15%",
      filters: [taskIds.map((id) => ({ text: id, value: id }))].flat(),
      onFilter: (value, record) => record.request.task_id === value,
      filterSearch: true,
      render: (text) => (
        <Typography.Text code>
          <Link to="/tasks/view" state={{ taskId: text }}>
            {text}
          </Link>
        </Typography.Text>
      ),
    },
    {
      title: "Request ID",
      dataIndex: ["request", "request_id"],
      key: "request_id",
      width: "10%",
      render: (text) => <Typography.Text code>{text}</Typography.Text>,
    },
    {
      title: "Type",
      dataIndex: ["request", "request_type"],
      key: "request_type",
      filters: [types.map((id) => ({ text: id, value: id }))].flat(),
      onFilter: (value, record) => record.request.request_type === value,
    },
    {
      title: "Request",
      key: "content",
      render: (_, record) => getRequestContent(record),
    },
    {
      title: "Actions",
      key: "actions",
      width: "10%",
      render: (_, record) => (
        <Space size={"middle"}>
          <Typography.Link onClick={() => handleRespondClick(record)}>
            Respond
          </Typography.Link>
        </Space>
      ),
    },
  ];

  if (error) {
    return <div>Failed to load requests: {error.message}</div>;
  }

  return (
    <>
      <div
        style={{
          padding: "0px",
          height: "100vh",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Table
          columns={columns}
          dataSource={requests}
          rowKey="request_id"
          loading={loading}
          pagination={false}
        />
      </div>
      {selectedRequest && schema && (
        <HitlRequestFormModal
          visible={!!selectedRequest}
          onClose={() => {
            setSelectedRequest(null);
            setSchema(null);
            fetchRequests();
            setRefreshHitlRequestsIndicator(true);
          }}
          request={selectedRequest}
          schema={schema}
          task={selectedTaskObject}
        />
      )}
    </>
  );
};

export default HitlRequestsTable;

import React, { useContext, useEffect, useState } from "react";
import { Switch, Typography, Space, Divider, Descriptions } from "antd";
import { ThemeContext } from "../appMain";
import { AiSystemsApi, Configuration, HealthApi } from "../../api_client";
import { apiBasePath } from "../../config";
import { useAuth0 } from "@auth0/auth0-react";

const { Title } = Typography;

const SettingsPage = () => {
  const {
    themeDark,
    setThemeDark,
    themeCompact,
    setThemeCompact,
    codeHighlight,
    setCodeHighlight,
  } = useContext(ThemeContext);

  const [version, setVersion] = useState("Unknown");

  const { getAccessTokenSilently } = useAuth0();

  // Save settings to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("themeDark", JSON.stringify(themeDark));
    if (themeDark) {
      setCodeHighlight("dark");
      localStorage.setItem("codeHighlight", "dark");
    } else {
      setCodeHighlight("light");
      localStorage.setItem("codeHighlight", "light");
    }
  }, [themeDark]);

  useEffect(() => {
    localStorage.setItem("themeCompact", JSON.stringify(themeCompact));
  }, [themeCompact]);

  async function getVersion() {
    const api = new HealthApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    const response = await api.statuszStatuszGet();

    setVersion(response.data.version);
  }

  useEffect(() => {
    getVersion();
  }, []);

  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Settings</Title>
      <Space direction="vertical" size="large">
        <div>
          <span style={{ marginRight: 8 }}>Dark Theme:</span>
          <Switch checked={themeDark} onChange={setThemeDark} />
        </div>
        <div>
          <span style={{ marginRight: 8 }}>Compact Theme:</span>
          <Switch checked={themeCompact} onChange={setThemeCompact} />
        </div>
        <Divider />
        <div>
          <Descriptions column={1} title="System Info">
            <Descriptions.Item label="UI Version">
              {__APP_VERSION__}
            </Descriptions.Item>
            <Descriptions.Item label="System Version">
              {version}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Space>
    </div>
  );
};

export default SettingsPage;

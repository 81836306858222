import React, { useState, useEffect, useContext } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Spin,
  Alert,
  Descriptions,
  Input,
  Form,
  Collapse,
  List,
  Button,
  Tooltip,
  Typography,
  App,
  Flex,
  Space,
  Menu,
  Tag,
  Table,
  Popconfirm,
} from "antd";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AgentsApi,
  AiSystemsApi,
  AiTasksApi,
  Configuration,
  ConnectionsApi,
} from "../../api_client";
import { apiBasePath } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PlayCircleOutlined,
  RadarChartOutlined,
  StopOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import yaml from "js-yaml";
import StorageContents from "../misc/storageContents";
import { saveAs } from "file-saver";
import TaskInvocationModal from "../modals/invokeTask";
import StatusTag from "../misc/taskStatusTag";
import { ThemeContext } from "../appMain";
import getSyntaxHighlighterStyle from "../misc/syntaxHighlight";

const { Header, Content } = Layout;

const TaskInfoCard = ({ refreshInterval = 5000 }) => {
  const [taskInfo, setTaskInfo] = useState(null);
  const [systemInfo, setSystemInfo] = useState(null);
  const [storageConnectionInfo, setStorageConnectionInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const location = useLocation();
  const taskId = location.state.taskId;
  const navigate = useNavigate();
  const { message, notification } = App.useApp();
  const [invokeTaskModalVisible, setInvokeTaskModalVisible] = useState(false);

  const { codeHighlight } = useContext(ThemeContext);

  const { getAccessTokenSilently } = useAuth0();

  const SaveYamlButton = ({ objectData, fileName }) => {
    const handleSave = () => {
      try {
        const yamlStr = yaml.dump(objectData, { lineWidth: -1 });
        const blob = new Blob([yamlStr], { type: "text/yaml;charset=utf-8" });
        saveAs(blob, fileName);
      } catch (e) {
        console.error("Error converting object to YAML:", e);
      }
    };

    const handleCopy = async () => {
      try {
        const yamlStr = yaml.dump(objectData);
        await navigator.clipboard.writeText(yamlStr);
        message.success("YAML copied to clipboard!");
      } catch (e) {
        console.error("Error copying YAML to clipboard:", e);
        message.error("Failed to copy YAML to clipboard.");
      }
    };

    return (
      <Space>
        <Button type="primary" onClick={handleSave}>
          Download as YAML
        </Button>
        <Button type="default" onClick={handleCopy}>
          Copy YAML to Clipboard
        </Button>
      </Space>
    );
  };

  function getTaskInfo() {
    const tasksApi = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    tasksApi
      .getAiTaskV1AiTasksAiTaskIdGet(taskId)
      .then((response) => {
        setTaskInfo(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        message.error("Could not fetch task: " + error);
      });
  }

  const fetchTaskInfo = async () => {
    if (!storageConnectionInfo) {
      setLoading(true);
    }
    getTaskInfo();
  };

  const refreshTaskInfo = async () => {
    // skip if task loaded and current status is Done or Failed
    if (
      taskInfo &&
      (taskInfo.current_status === "COMPLETED" ||
        taskInfo.current_status === "FAILED")
    ) {
      return;
    } else {
      getTaskInfo();
    }
  };

  useEffect(() => {
    loadSystem();
  }, [taskInfo]);

  useEffect(() => {
    loadConnections();
  }, [systemInfo]);

  // every 5 seconds fetch taskinfo
  useEffect(() => {
    const intervalId = setInterval(refreshTaskInfo, 5000);

    return () => clearInterval(intervalId);
  }, [taskId]);

  const loadSystem = () => {
    if (taskInfo === null) {
      return;
    }

    const systemsApi = new AiSystemsApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    systemsApi
      .getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(
        taskInfo.ai_system_id,
      )
      .then((response) => {
        setSystemInfo(response.data);
      });
  };

  const loadConnections = () => {
    if (systemInfo === null || systemInfo.connections === null) {
      return;
    }
    const connectionsApi = new ConnectionsApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    for (const connection of systemInfo.connections) {
      connectionsApi.getConnectionsV1ConnectionsGet().then((response) => {
        setStorageConnectionInfo((prev) => ({
          ...prev,
          connection: response.data,
        }));
      });
    }
  };

  const handleCancelTask = (taskId) => {
    return async () => {
      const api = new AiTasksApi(
        new Configuration({
          accessToken: getAccessTokenSilently,
          basePath: apiBasePath,
        }),
      );
      try {
        await api.cancelTaskV1AiTasksTaskIdCancelPost(taskId);
        message.success("Task cancelled successfully");
        await fetchTaskInfo();
      } catch (error) {
        message.error("Could not cancel task: " + error);
      }
    };
  };

  const getStorageConnections = () => {
    if (storageConnectionInfo === null) {
      return null;
    }
    // return connections of type TaskCloudStorageConnector
    const storageConnections = storageConnectionInfo.connection
      .filter((connection) => connection.type === "TaskCloudStorageConnector")
      .filter((connection) =>
        systemInfo.connections
          .map((connection) => connection.id)
          .includes(connection.id),
      );
    return storageConnections;
  };

  useEffect(() => {
    fetchTaskInfo();
    // const intervalId = setInterval(fetchTaskInfo, refreshInterval);

    // return () => clearInterval(intervalId);
  }, [taskId, refreshInterval]);

  const handleInvokeTask = async () => {
    // Invoke the task
    const api = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    api
      .invokeV1AiTasksTaskIdInvokePost(taskId, {})
      .then((response) => {
        message.success("Task invoked successfully");
        fetchTaskInfo();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 402) {
          message.error(
            "Can not invoke task: insufficient credit balance. Please contact support.",
          );
          console.error("Error:", error);
        } else {
          message.error("Error invoking task: " + error.message);
          console.error("Error:", error);
        }
      });
  };

  const handleDeleteTask = async (taskId) => {
    const api = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );
    try {
      await api.deleteAiTaskV1AiTasksAiTaskIdDelete(taskId);
      message.success("Task deleted successfully");
      navigate("/tasks");
    } catch (error) {
      message.error("Could not cancel task: " + error);
    }
  };

  const VariablesTable = ({ data }) => {
    if (!data) {
      return null;
    }
    // Convert JSON object into an array of objects suitable for the table
    const dataSource = Object.keys(data).map((key, index) => ({
      key: index, // Unique identifier for the row
      property: key,
      value: data[key],
    }));

    // Define the columns for the table
    const columns = [
      {
        title: "Property",
        dataIndex: "property",
        key: "property",
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
      },
    ];

    return (
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false} // Remove pagination if you want to display all rows
      />
    );
  };

  const handleInputChange = (field, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  if (loading) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Content>
          <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Spin />
          </Row>
        </Content>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Content>
          <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Alert
              message="Error"
              description="Failed to load task information."
              type="error"
            />
          </Row>
        </Content>
      </Layout>
    );
  }

  return (
    <Flex vertical style={{ height: "100vh" }}>
      <Menu mode="horizontal" style={{ textAlign: "center" }}>
        <Menu.Item
          key="status"
          disabled
          style={{ opacity: 1, cursor: "default" }}
        >
          <StatusTag status={taskInfo.current_status} />
        </Menu.Item>
        <Menu.Item
          key="info"
          disabled
          style={{ opacity: 1, cursor: "default" }}
        >
          <Tag>Task: {taskId}</Tag>
        </Menu.Item>
        <Menu.Item
          key="info2"
          disabled
          style={{ opacity: 1, cursor: "default" }}
          onClick={() => {
            navigate("/tasks/view", { state: { taskId: taskId } });
          }}
        >
          <Tag>AI System: {taskInfo.ai_system_id}</Tag>
        </Menu.Item>
        <Menu.Item
          key="graph"
          onClick={() =>
            navigate("/ai_systems/graph", {
              state: {
                taskId: taskInfo.id,
                aiSystemId: taskInfo.ai_system_id,
              },
            })
          }
          icon={<RadarChartOutlined />}
        >
          Show graph view
        </Menu.Item>
        <Menu.Item
          key="cancel"
          disabled={
            taskInfo.current_status === "CANCELLED" ||
            taskInfo.current_status === "COMPLETED" ||
            taskInfo.current_status === "FAILED" ||
            taskInfo.current_status === "TERMINATED" ||
            taskInfo.current_status === "TIME_OUT"
          }
          onClick={handleCancelTask(taskId)}
          icon={<StopOutlined />}
        >
          Cancel task
        </Menu.Item>
        <Menu.Item
          key="invoke"
          onClick={handleInvokeTask}
          disabled={taskInfo.current_status !== "NOT_STARTED"}
          icon={<PlayCircleOutlined />}
        >
          Run task
        </Menu.Item>
        <Menu.Item key="delete" icon={<DeleteOutlined />}>
          <Popconfirm
            title="Are you sure you want to delete this task?"
            onConfirm={() => handleDeleteTask(taskId)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: true }}
          >
            Delete task
          </Popconfirm>
        </Menu.Item>
      </Menu>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <Content style={{ padding: "24px", height: "100%" }}>
          <TaskInvocationModal
            visible={invokeTaskModalVisible}
            initTaskId={taskId}
            systemId={taskInfo.ai_system_id}
            onCancel={() => setInvokeTaskModalVisible(false)}
          />
          <Row gutter={[16, 16]} justify="left">
            <Col xs={24} md={12}>
              <Card title="Task Input" style={{ height: "100%" }} bordered>
                <p style={{ whiteSpace: "pre-line" }}>
                  {taskInfo.input?.messages?.map(
                    (message, index) => message.content,
                  )}
                </p>
                <div style={{ marginTop: 20 }}>
                  <VariablesTable data={taskInfo.input?.variables} />
                </div>
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card title="Task Output" style={{ height: "100%" }} bordered>
                {taskInfo.result && (
                  <>
                    <SaveYamlButton
                      objectData={taskInfo.result}
                      fileName={`task_${taskInfo.id}_result.yaml`}
                    />
                    <SyntaxHighlighter
                      language="yaml"
                      style={getSyntaxHighlighterStyle(codeHighlight)}
                    >
                      {yaml.dump(taskInfo.result, { lineWidth: -1 })}
                    </SyntaxHighlighter>
                  </>
                )}
                {!taskInfo.result && <p>{"No output yet"}</p>}
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card title="Storage" bordered>
                <Descriptions key={"descriptions"} column={1}>
                  {getStorageConnections()?.map((connection) => (
                    <Descriptions.Item
                      key={connection.id}
                      label={connection.name}
                    >
                      <Flex vertical={true}>
                        <div>
                          <Typography.Title level={4} style={{ marginTop: 0 }}>
                            Connection ID: {connection.id}
                          </Typography.Title>
                        </div>
                        <StorageContents
                          connectionId={connection.id}
                          taskId={taskId}
                        />
                      </Flex>
                    </Descriptions.Item>
                  ))}
                  {(!getStorageConnections() ||
                    getStorageConnections().length == 0) && (
                    <Typography.Text key={"none"}>
                      No storage connections found
                    </Typography.Text>
                  )}
                </Descriptions>
              </Card>
            </Col>
          </Row>
          {/*        <Col xs={24} style={{marginTop: 16}}>*/}
          {/*              <Collapse*/}
          {/*                items={[{ key: '1', label: 'Graph', children: <Content style={{width: "100%", height: "95vh"}}><AIComponent taskIdProp={taskId}/></Content> }]}*/}
          {/*/>*/}
          {/*        </Col>*/}
        </Content>
      </div>
    </Flex>
  );
};

export default TaskInfoCard;

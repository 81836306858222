import React, { useEffect, useState } from "react";
import { Content, Header } from "antd/es/layout/layout";
import SpotlightComponent from "./views/spotlight";
import TaskLauncherComponent from "./views/launcher";

export const TaskLauncher = () => {
  return (
    <Content>
      <TaskLauncherComponent></TaskLauncherComponent>
    </Content>
  );
};

export default TaskLauncher;

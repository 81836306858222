import {
  sunburst,
  github,
  dracula,
} from "react-syntax-highlighter/dist/cjs/styles/hljs";

function getSyntaxHighlighterStyle(name) {
  if (name === "dark") {
    // return sunburst;
    return dracula;
  } else if (name === "light") {
    return github;
  } else {
    console.error("Unknown code highlight style: ", name);
    return github;
  }
}

export default getSyntaxHighlighterStyle;

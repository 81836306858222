import React from "react";
import { Layout, App, Flex } from "antd";

import "./spotlight.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import { Typography } from "antd";

const { Content } = Layout;

const ContactComponent = () => {
  const navigate = useNavigate();

  const contents = `
  # Contact Us
  
  For support, feedback, or questions, please contact us at **[info@aetherx.ai](mailto:info@aetherx.ai)**
  
  `;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content
        style={{
          display: "flex",
          // flexDirection: "column",
          // justifyContent: "space-around",
          alignItems: "center",
          // minHeight: "50vh",
        }}
      >
        <Flex
          vertical
          // flexDirection="column"
          align="center"
          style={{
            paddingLeft: 100,
            width: "100%",
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          <div style={{ width: 600 }}>
            <Markdown>{contents}</Markdown>
          </div>
        </Flex>
      </Content>
    </Layout>
  );
};

export default ContactComponent;

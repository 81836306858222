import React from "react";
import { Space, Tag, Spin } from "antd";

const StatusTag = ({ status }) => {
  let color = "default";
  let spin = false;

  if (status === "NOT_STARTED") {
    color = "default";
  } else if (status === "PENDING") {
    color = "warning";
    spin = true;
  } else if (status === "RUNNING") {
    color = "processing";
    spin = true;
  } else if (status === "COMPLETED") {
    color = "success";
  } else if (status === "FAILED") {
    color = "error";
  }

  return (
    (
      <Space>
        <Tag color={color}>
          {status}
          {spin && <Spin style={{ marginLeft: "5px" }} size="small" />}
        </Tag>
      </Space>
    ) || "Unknown"
  );
};

export default StatusTag;

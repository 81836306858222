import React, { useContext, useEffect, useState } from "react";
import { App, Button, Flex, Layout, message } from "antd";
import AceEditor from "react-ace";
import "brace/mode/yaml";
import "brace/theme/monokai";
import "brace/theme/dracula";
import { useAuth0 } from "@auth0/auth0-react";
import { AiSystemsApi, Configuration } from "../../../lib/src/api_client";
import { apiBasePath } from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import yaml from "js-yaml";
import { Typography } from "antd";
import { sunburst } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import getSyntaxHighlighterStyle from "./misc/syntaxHighlight";
import { ThemeContext } from "./appMain";

const { Paragraph, Space, Text } = Typography;

const { Header, Content, Footer } = Layout;

const AiSystemEditor = () => {
  const [yamlContent, setYamlContent] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const aiSystemId = location.state.aiSystemId;
  const { message, notification } = App.useApp();
  const { themeCompact, codeHighlight } = useContext(ThemeContext);

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently();
    const api = new AiSystemsApi(
      new Configuration({
        accessToken: accessToken,
        basePath: apiBasePath,
      }),
    );

    let jsonObj = null;

    try {
      // Convert YAML to JSON before saving
      jsonObj = yaml.load(yamlContent);
    } catch (e) {
      message.error(
        <>
          <Flex gap="middle" vertical>
            <Text>YAML syntax error</Text>
            <SyntaxHighlighter
              customStyle={{ textAlign: "left" }}
              language="text"
              style={getSyntaxHighlighterStyle(codeHighlight)}
            >
              {yaml.dump(e.message)}
            </SyntaxHighlighter>
          </Flex>
        </>,
      );
      console.error(e);
      return;
    }

    try {
      await api.upsertAiSystemV1AiSystemsPost(jsonObj);
      message.success("YAML content saved!");
    } catch (e) {
      if (e.response.status === 422) {
        message.error(
          <>
            <Flex gap="middle" vertical>
              <Text>Validation Error</Text>
              <SyntaxHighlighter
                customStyle={{ textAlign: "left" }}
                language="yaml"
                style={getSyntaxHighlighterStyle(codeHighlight)}
              >
                {yaml.dump(e.response.data.detail)}
              </SyntaxHighlighter>
            </Flex>
          </>,
        );
        console.error(e);
      } else {
        message.error(
          <>
            <Flex gap="middle" vertical>
              <Text>Error</Text>
              <SyntaxHighlighter
                customStyle={{ textAlign: "left" }}
                language="yaml"
                style={getSyntaxHighlighterStyle(codeHighlight)}
              >
                {yaml.dump(e.response.data.error)}
              </SyntaxHighlighter>
            </Flex>
          </>,
        );
        console.error(e);
      }
    }
  };

  const handleGetValue = async () => {
    const accessToken = await getAccessTokenSilently();
    const api = new AiSystemsApi(
      new Configuration({
        accessToken: accessToken,
        basePath: apiBasePath,
      }),
    );

    try {
      const response =
        await api.getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(
          aiSystemId,
        );
      // Convert json to yaml on open
      const yamlString = yaml.dump(response.data, { lineWidth: 100 });
      setYamlContent(yamlString);
    } catch (e) {
      message.error("Error fetching YAML content");
      console.error(e);
    }
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    handleGetValue();
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ color: "#fff" }}>
        <Flex justify={"space-between"} align={"center"}>
          <Button
            type="primary"
            onClick={handleGoBack}
            style={{ marginRight: "8px" }}
          >
            Back
          </Button>
          AI System {aiSystemId} - YAML Editor
          <Button
            type="primary"
            onClick={handleSave}
            style={{ marginRight: "8px" }}
          >
            Save
          </Button>
        </Flex>
      </Header>
      <Content style={{ padding: "24px" }}>
        <AceEditor
          mode="yaml"
          theme={codeHighlight === "dark" ? "dracula" : "textmate"}
          name="yamlEditor"
          onChange={(newValue) => setYamlContent(newValue)}
          fontSize={themeCompact ? 12 : 14}
          showPrintMargin={false}
          // showGutter
          highlightActiveLine
          value={yamlContent}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
          }}
          style={{ width: "100%", height: "100%" }}
        />
      </Content>
    </Layout>
  );
};

export default AiSystemEditor;

import React, { useEffect, useState, useRef } from "react";
import { Modal, Input, Form, message, App, Checkbox, Spin } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AiSystemsApi,
  AiTasksApi,
  Configuration,
} from "../../../../lib/src/api_client";
import { apiBasePath } from "../../config";
import FormComponent, { generateWidgets } from "@rjsf/antd";
import validator from "@rjsf/validator-ajv8";
import { parseJwt } from "../../utils/jwt";

const TaskCreateModal = ({
  visible,
  onCancel,
  systemId,
  initInputText,
  initTaskId,
  initVariables,
}) => {
  const [openAfterRun, setOpenAfterRun] = useState(true);
  const [taskId, setTaskId] = useState("");
  const [inputText, setInputText] = useState("");
  const [invoke, setInvoke] = useState(true);
  const [loading, setLoading] = useState(false);
  const [schema, setSchema] = useState(null);
  const [formData, setFormData] = useState(null);
  const [uiSchema, setUiSchema] = useState({});
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const { message, notification } = App.useApp();
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      // decode and get obj
      const decodedToken = parseJwt(token);

      // get email from aetherx.ai/claims/email
      setUserEmail(decodedToken["aetherx.ai/claims/email"]);
    });
  }, []);

  // Function to generate taskId
  const generateTaskId = () => {
    const now = new Date();
    const datePart = `${now.getFullYear()}${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}`;
    const timePart = `${now.getHours().toString().padStart(2, "0")}${now
      .getMinutes()
      .toString()
      .padStart(
        2,
        "0",
      )}${now.getSeconds().toString().padStart(2, "0")}.${now.getMilliseconds().toString().padStart(3, "0")}`;
    return `${userEmail}.${systemId}-${datePart}-${timePart}`;
  };

  // Effect to fetch schema
  useEffect(() => {
    const fetchSchema = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const api = new AiSystemsApi(
        new Configuration({
          accessToken: accessToken,
          basePath: apiBasePath,
        }),
      );

      try {
        const response =
          await api.getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(
            systemId,
          );
        setSchema(response.data.extra_inputs_schema || null);
        setUiSchema({
          accepted: {},
          text: {
            "ui:widget": "textarea",
          },
          "ui:submitButtonOptions": { norender: true },
        });
      } catch (error) {
        message.error("Error fetching schema: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    if (visible && systemId) {
      fetchSchema();
    }
  }, [visible, systemId]);

  // Effect to set default taskId and focus the input when modal opens
  useEffect(() => {
    setInvoke(true);
    if (visible && systemId) {
      const defaultTaskId = generateTaskId();
      if (!initTaskId) {
        setTaskId(defaultTaskId);
      } else {
        setTaskId(initTaskId);
      }
      setInputText(initInputText);
      setFormData(initVariables);
      setTimeout(() => inputRef.current?.focus(), 100);
    }
  }, [visible, systemId]);

  const handleCreateAndInvoke = async () => {
    if (!taskId) {
      message.error("Please enter a Task ID");
      return;
    }
    if (!inputText) {
      message.error("Please enter the input text");
      return;
    }

    const api = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    try {
      await api.createAiTaskV1AiTasksPost({
        ai_task_id: taskId,
        ai_system_id: systemId,
        input: {
          messages: [
            {
              role: "user",
              content: inputText,
              type: "chat",
            },
          ],
          variables: formData ? formData : {},
        },
      });
      message.success(`Task '${taskId}' created successfully`);

      if (invoke) {
        await api.invokeV1AiTasksTaskIdInvokePost(taskId, {});
        message.success(`Task '${taskId}' started successfully`);

        if (openAfterRun) {
          navigate("/tasks/view", {
            state: {
              taskId: taskId,
            },
          });
        } else {
          setTaskId(generateTaskId());
        }
      } else {
        if (openAfterRun) {
          navigate("/tasks/view", {
            state: {
              taskId: taskId,
            },
          });
        } else {
          setTaskId(generateTaskId());
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 402) {
        message.error(
          "Cannot run task: insufficient credit balance. Please contact support.",
        );
      } else {
        message.error("Error running the task: " + error.message);
      }
    }
  };

  const widgets = generateWidgets();
  widgets.TextWidget = widgets.TextareaWidget;

  return (
    <Modal
      title="Create and Run Task"
      open={visible}
      onOk={handleCreateAndInvoke}
      onCancel={onCancel}
      okText="Create Task"
      cancelText="Cancel"
    >
      {loading ? (
        <Spin />
      ) : (
        <Form layout="vertical" onFinish={handleCreateAndInvoke}>
          <Form.Item label="Task ID">
            <Input
              value={taskId}
              onChange={(e) => setTaskId(e.target.value)}
              placeholder="Enter or generate a Task ID"
            />
          </Form.Item>
          <Form.Item label="Input Text for Task">
            <Input.TextArea
              ref={inputRef}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              rows={4}
              placeholder="Enter the text to be processed by the task"
            />
          </Form.Item>
          {schema && (
            <>
              <Form.Item label="Additional Inputs">
                <FormComponent
                  schema={schema}
                  validator={validator}
                  formData={formData}
                  uiSchema={uiSchema}
                  onSubmit={null}
                  // onSubmit={handleSubmit}
                  ref={inputRef}
                  widgets={widgets}
                  onChange={(e) => setFormData(e.formData)}
                />
              </Form.Item>
            </>
          )}
          <Form.Item label="Invoke">
            <Checkbox
              checked={invoke}
              onChange={(e) => setInvoke(e.target.checked)}
              defaultChecked={true}
            >
              Run task after creation (disable if you want to upload files as
              task input)
            </Checkbox>
            <Checkbox
              checked={openAfterRun}
              onChange={(e) => setOpenAfterRun(e.target.checked)}
              // defaultChecked={true}
            >
              Open task after creation
            </Checkbox>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default TaskCreateModal;

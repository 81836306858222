import React, { useEffect, useState } from "react";
import { App, Button, message, Space, Table, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { AiSystemsApi, Configuration } from "../../../../lib/src/api_client";
import { useAuth0 } from "@auth0/auth0-react";
import { apiBasePath } from "../../config";
import CloneSystemModal from "../modals/cloneAiSystem";
import TaskCreateModal from "../modals/createAndInvokeTask";

const { Title } = Typography;

const SystemsTable = () => {
  const [systems, setSystems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupIds, setGroupIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { message, notification } = App.useApp();

  const fetchSystems = async () => {
    setLoading(true);
    const api = new AiSystemsApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    api
      .getAiSystemsV1AiSystemsGet()
      .then((response) => {
        setSystems(response.data);
        // set users and groups to all unique ids
        const groupIds = new Set();
        const userIds = new Set();
        response.data.forEach((system) => {
          system.groups.forEach((group) => groupIds.add(group[0]));
          system.users.forEach((user) => userIds.add(user[0]));
        });
        setGroupIds(Array.from(groupIds));
        setUserIds(Array.from(userIds));
        setLoading(false);
      })
      .catch((error) => {
        console.log("Api error: ", error);
        setLoading(false);
        setError(error);
        message.error("Could not fetch systems: " + error);
      });
  };

  useEffect(() => {
    fetchSystems();
  }, []);

  const columns = [
    {
      title: "System",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: "30%",
      render: (_, record) => (
        <>
          <Title level={5} style={{ marginBottom: 0, marginTop: 0 }}>
            {record.name}
          </Title>
          <p>{record.description}</p>
        </>
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      width: "10%",
      key: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (val) => <Typography.Text code>{val}</Typography.Text>,
    },
    // {
    //   title: "Nodes",
    //   dataIndex: ["system", "nodes"],
    //   key: "nodes",
    //   render: (nodes) => nodes.map((node) => node.name).join(", "),
    // },
    {
      title: "Access: groups",
      key: "access",
      dataIndex: "groups",
      filters: [groupIds.map((id) => ({ text: id, value: id }))].flat(),
      onFilter: (value, record) =>
        record.groups.some((group) => group[0] === value),
      render: (groups) => (
        <>
          <Space size={"middle"}>
            {groups.map((group) => (
              <Tag key={`${group[0]}:${group[1]}`}>
                {group[0]}: {group[1]}
              </Tag>
            ))}
          </Space>
        </>
      ),
    },
    {
      title: "Access: users",
      key: "users",
      dataIndex: "users",
      filters: [userIds.map((id) => ({ text: id, value: id }))].flat(),
      onFilter: (value, record) =>
        record.users.some((user) => user[0] === value),
      render: (users, record) => (
        <>
          {record.groups.length > 0 && record.groups[0][0] == "public" ? (
            "-"
          ) : (
            <Space size={"middle"}>
              {users.map((user) => (
                <Tag key={user[0] + user[1]}>
                  {user[0]}: {user[1]}
                </Tag>
              ))}
            </Space>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size={"middle"}>
          <Typography.Link
            onClick={() =>
              navigate("/ai_systems/graph", {
                state: {
                  // taskId: record.id,
                  aiSystemId: record.id,
                },
              })
            }
          >
            Show Graph
          </Typography.Link>
          <Typography.Link
            onClick={() =>
              navigate("/ai_systems/edit", {
                state: {
                  // taskId: record.id,
                  aiSystemId: record.id,
                },
              })
            }
          >
            Edit as code
          </Typography.Link>
          <Typography.Link onClick={() => handleCloneSystem(record)}>
            Clone
          </Typography.Link>
          <Typography.Link onClick={() => handleCreateTask(record)}>
            Create Task
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const [createTaskModalVisible, setCreateTaskModalVisible] = useState(false);
  const [cloneSystemModalVisible, setCloneSystemModalVisible] = useState(false);
  const [modalSystemId, setModalSystemId] = useState(false);
  const [modalSystem, setModalSystem] = useState(false);

  const handleCreateTask = (record) => {
    setCreateTaskModalVisible(true);
    setModalSystemId(record.id);
  };

  const handleCloneSystem = (record) => {
    setCloneSystemModalVisible(true);
    setModalSystem(record);
  };

  if (error) {
    return <div>Failed to load systems: {error.message}</div>;
  }

  return (
    <>
      <TaskCreateModal
        visible={createTaskModalVisible}
        // onOk={() => setModalVisible(false)}
        onCancel={() => setCreateTaskModalVisible(false)}
        systemId={modalSystemId}
      />
      <CloneSystemModal
        visible={cloneSystemModalVisible}
        onOk={() => {
          setCloneSystemModalVisible(false);
          fetchSystems();
        }}
        onCancel={() => setCloneSystemModalVisible(false)}
        system={modalSystem}
      />
      <div
        style={{
          padding: "0px",
          height: "100vh",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Table
          columns={columns}
          dataSource={systems}
          rowKey="id"
          loading={loading}
          // pagination={{
          //     pageSize: 10, // Adjust number of items per page according to your preference
          // }}
        />
      </div>
    </>
  );
};

export default SystemsTable;

import { useContext } from "react";
import { ThemeContext } from "../appMain";
import logo_black from "../../assets/logo_black.png";
import logo_white from "../../assets/logo_white.png";
import logo from "../../assets/logo.png";

function getLogo() {
  const { themeDark } = useContext(ThemeContext);

  if (themeDark) {
    return logo;
  } else {
    return logo;
  }
}

export default getLogo;

import React, { useEffect, useState } from "react";
import { App, message, Table } from "antd";
import { Configuration, ConnectionsApi } from "../../../../lib/src/api_client";
import { useAuth0 } from "@auth0/auth0-react";
import { apiBasePath } from "../../config";

const ConnectionsTable = () => {
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const { message, notification } = App.useApp();

  useEffect(() => {
    const fetchConnections = async () => {
      setLoading(true);
      const api = new ConnectionsApi(
        new Configuration({
          accessToken: getAccessTokenSilently,
          basePath: apiBasePath,
        }),
      );

      api
        .getConnectionsV1ConnectionsGet()
        .then((response) => {
          setConnections(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Api error: ", error);
          setLoading(false);
          setError(error);
          message.error("Could not fetch connections: " + error);
        });
    };
    fetchConnections();
  }, []);

  const columns = [
    {
      title: "Connection ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => <></>,
    },
  ];

  if (error) {
    return <div>Failed to load connections: {error.message}</div>;
  }

  return (
    <>
      <div
        style={{
          padding: "0px",
          height: "100vh",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Table
          columns={columns}
          dataSource={connections}
          rowKey="id"
          loading={loading}
          // pagination={{
          //     pageSize: 10, // Adjust number of items per page according to your preference
          // }}
        />
      </div>
    </>
  );
};

export default ConnectionsTable;

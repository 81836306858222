import React, { useState } from "react";
import {
  Modal,
  Button,
  message,
  Form as AntdForm,
  Input,
  Descriptions,
  Card,
  App,
  Tag,
} from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Configuration, HitlApi } from "../../../../lib/src/api_client";
import { apiBasePath } from "../../config";
import Form, { generateWidgets } from "@rjsf/antd";
import validator from "@rjsf/validator-ajv8";
import Markdown from "react-markdown";

import { Typography } from "antd";

const { Text, Title } = Typography;

import "./style.css";
import { Link } from "react-router-dom";

const HitlRequestFormModal = ({ visible, onClose, request, schema, task }) => {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { message, notification } = App.useApp();

  schema.title = "";

  const handleSubmitForm = async ({ formData }) => {
    setLoading(true);
    const api = new HitlApi(
      new Configuration({
        accessToken: await getAccessTokenSilently(),
        basePath: apiBasePath,
      }),
    );

    try {
      await api.hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost(
        request.connection_id,
        request.request_id,
        formData,
      );
      message.success("Response submitted successfully");
      onClose();
    } catch (error) {
      console.log("Submit error: ", error);
      message.error("Failed to submit response: " + error);
    } finally {
      setLoading(false);
    }
  };

  const widgets = generateWidgets();

  widgets.TextWidget = widgets.TextareaWidget;

  return (
    <Modal
      title="Human-In-The-Loop Request"
      open={visible}
      onCancel={onClose}
      footer={[
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type={"primary"}
            form="hitl-form"
            key="submit"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
        </>,
      ]}
    >
      <Descriptions layout="vertical" bordered column={1}>
        <Descriptions.Item label="From task">
          <Title level={5} style={{ marginBottom: 0, marginTop: 0 }}>
            {task?.system.name}
          </Title>
          <Link to="/tasks/view" state={{ taskId: request.task_id }}>
            {/*<Tag>*/}
            ID: <Text code>{request.task_id}</Text>
            {/*</Tag>*/}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Input">
          <Typography.Paragraph
            style={{ whiteSpace: "pre-wrap" }}
            ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
          >
            {task?.input.messages
              ? task?.input.messages
                  .map((message) => message.content)
                  .join(", ")
              : "-"}
          </Typography.Paragraph>
        </Descriptions.Item>
      </Descriptions>
      {request.text && (
        <Card style={{ marginTop: 16, marginBottom: 16 }}>
          <Text style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            <Markdown>{request.text}</Markdown>
          </Text>
        </Card>
      )}
      <Form
        schema={schema}
        id={"hitl-form"}
        Title={"Title"}
        onSubmit={handleSubmitForm}
        validator={validator}
        widgets={widgets}
        uiSchema={{
          accepted: {
            // "ui:widget": "radio",
          },
          request_id: {
            "ui:disabled": true,
            "ui:widget": "hidden",
          },
          connection_id: {
            "ui:disabled": true,
            "ui:widget": "hidden",
          },
          request_type: {
            "ui:disabled": true,
            "ui:widget": "hidden",
          },
          timestamp: {
            "ui:disabled": true,
            "ui:widget": "hidden",
          },
          text: {
            "ui:title": "Response text",
            "ui:widget": "textarea",
            "ui:autoFocus": true,
            "ui:placeholder": "Enter your response here",
          },
          answers: {
            "ui:title": "Answers",
          },
          "ui:submitButtonOptions": { norender: true },
        }}
      ></Form>
    </Modal>
  );
};

export default HitlRequestFormModal;

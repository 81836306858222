// components/TimestampDisplay.js
import React from "react";
import { Tooltip, Typography } from "antd";
import moment from "moment-timezone";

export function formatTimestamp(timestamp) {
  const now = moment();
  const time = moment.tz(timestamp, "UTC").local(); // Convert from UTC to local time
  const diffMinutes = now.diff(time, "minutes");

  if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  } else if (diffMinutes < 1440) {
    return `${Math.floor(diffMinutes / 60)} hours ago`;
  } else {
    return time.format("YYYY-MM-DD HH:mm:ss");
  }
}

const TimestampDisplay = ({ timestamp }) => {
  if (!timestamp) {
    return <span>-</span>;
  }
  const formattedTimestamp = formatTimestamp(timestamp);
  const fullTimestamp = moment
    .tz(timestamp, "UTC")
    .local()
    .format("YYYY-MM-DD HH:mm:ss");

  return (
    <Tooltip title={fullTimestamp}>
      <Typography.Text>{formattedTimestamp}</Typography.Text>
    </Tooltip>
  );
};

export default TimestampDisplay;

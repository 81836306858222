import React, { useState, useRef, useEffect } from "react";
import { Layout, Input, Select, Flex, App, Image } from "antd";

import "./spotlight.css";
import TextArea from "antd/es/input/TextArea";
import { AiSystemsApi, AiTasksApi, Configuration } from "../../api_client";
import { apiBasePath } from "../../config";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import logo_white from "../../assets/logo_white.png";
import getLogo from "../misc/logo";

const { Content } = Layout;

const SpotlightComponent = () => {
  const [query, setQuery] = useState("");
  const [selectedSystem, setSelectedSystem] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const [aiSystems, setSystems] = useState([]);
  const { getAccessTokenSilently, user } = useAuth0();
  const { message, notification } = App.useApp();
  const navigate = useNavigate();

  const generateTaskId = (systemId) => {
    const now = new Date();
    const datePart = `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}`;
    const timePart = `${now.getHours().toString().padStart(2, "0")}${now.getMinutes().toString().padStart(2, "0")}${now.getSeconds().toString().padStart(2, "0")}`;
    return `${user.sub}.${systemId}-${datePart}-${timePart}`; // e.g., system1-20230503-143501
  };

  const inputRef = useRef(null);
  const selectRef = useRef(null);

  useEffect(() => {
    const fetchSystems = async () => {
      const api = new AiSystemsApi(
        new Configuration({
          accessToken: getAccessTokenSilently,
          basePath: apiBasePath,
        }),
      );

      api
        .getAiSystemsV1AiSystemsGet()
        .then((response) => {
          setSystems(response.data);
        })
        .catch((error) => {
          console.log("Api error: ", error);
          message.error("Could not fetch systems: " + error);
        });
    };
    fetchSystems();
  }, []);

  const handleCreateAndInvoke = async (systemId) => {
    // First, create the task

    const api = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    // const invokeApi = new Inv
    const taskId = generateTaskId(systemId);

    try {
      await api.createAiTaskV1AiTasksPost({
        ai_task_id: taskId,
        ai_system_id: systemId,
      });
      await api.invokeV1AiTasksTaskIdInvokePost(taskId, {
        messages: [
          {
            role: "user",
            content: query,
            type: "chat",
          },
        ],
      });
      notification.info({
        message: `Task created successfully`,
        description: "",
        onClick: () => {
          navigate("/tasks/view", { state: { taskId: taskId } });
        },
      });
    } catch (error) {
      if (error.response?.status === 402) {
        message.error(
          "Can not invoke task: insufficient credit balance. Please contact support.",
        );
        console.error("Error:", error);
      } else {
        // message.error("Error invoking task: " + error.message);
        console.error("Error:", error);
      }
    }

    // navigate("/ai_systems/graph", {
    //   state: {
    //     taskId: taskId,
    //     aiSystemId: systemId,
    //   },
    // });
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      selectRef.current.focus();
      setSelectOpen(true);
    }
  };

  const handleSelectKeyDown = (e) => {
    if (e.key === "Enter") {
      inputRef.current.focus();
    }
  };

  const handleSelectChange = (value) => {
    // setSelectedSystem(value);
  };

  const handleSubmit = (value) => {
    setSelectedSystem(value);
    handleCreateAndInvoke(value);
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        // backgroundColor: "rgba(0, 0, 0, 0.5)"
      }}
    >
      <div style={{ height: "100vh", overflowY: "auto", padding: "16px" }}>
        <Content
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Image
            style={{ width: 400, marginTop: "5vh", marginBottom: "40px" }}
            preview={false}
            src={getLogo()}
          />
          <div
            className={"spotlight-box"}
            style={{ width: "100%", maxWidth: "600px", marginBottom: "45vh" }}
          >
            <Flex vertical>
              <TextArea
                placeholder="Type the input for your task here..."
                autoSize={{ minRows: 2 }}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleInputKeyDown}
                ref={inputRef}
                style={{
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
              <Select
                showSearch
                placeholder={"Select AI System"}
                open={selectOpen}
                value={selectedSystem}
                onChange={handleSelectChange}
                onSelect={handleSubmit}
                onDropdownVisibleChange={setSelectOpen}
                // onKeyDown={handleSelectKeyDown}
                ref={selectRef}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={aiSystems.map((system) => ({
                  label: system.name,
                  value: system.id,
                }))}
              />
            </Flex>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default SpotlightComponent;

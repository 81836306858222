// ScopeProvider.js
import React, {createContext, useContext, useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {parseJwt} from "../src/utils/jwt";

// Create a Context for the scopes
const ScopeContext = createContext({
  scopes: [],
  hasScope: () => false,
});

// Create a Provider component
export const ScopeProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [scopes, setScopes] = useState([]);

  useEffect(() => {
    const getScopes = async () => {
      if (isAuthenticated) {
        try {
          // Get the access token
          const token = await getAccessTokenSilently();
          // Decode the token to get the scopes
          const decodedToken = parseJwt(token);
          const scope = decodedToken.scope || '';
          const scopeArray = scope.split(' ').filter(Boolean);
          setScopes(scopeArray);
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      }
    };

    getScopes();
  }, [getAccessTokenSilently, isAuthenticated]);

  // Helper method to check if a scope exists
  const hasScope = (scopeToCheck) => scopes.includes(scopeToCheck);

  return (
    <ScopeContext.Provider value={{ scopes, hasScope }}>
      {children}
    </ScopeContext.Provider>
  );
};

// Custom hook to use the ScopeContext
export const useScopes = () => useContext(ScopeContext);
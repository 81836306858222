import React, { useState, useRef, useEffect, useContext } from "react";
import { Card, Collapse, Descriptions } from "antd";
import { EventComponent, mergeTokens } from "../../utils/llm_output_parser";
import { Handle, Position } from "reactflow";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import yaml from "react-syntax-highlighter/dist/esm/languages/hljs/yaml";
import { sunburst } from "react-syntax-highlighter/dist/esm/styles/hljs"; // Using the docco style, choose any other
import { default as YAML } from "yaml";
import getSyntaxHighlighterStyle from "../misc/syntaxHighlight";
import { ThemeContext } from "../appMain";
import { useScopes } from "../../../providers/scopeProvider";

SyntaxHighlighter.registerLanguage("yaml", yaml);

const AgentNodeComponent = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const outputRef = useRef(null);
  const lastItemRef = useRef(null);

  const { codeHighlight } = useContext(ThemeContext);
  const { hasScope } = useScopes();

  const handleToggle = () => setIsExpanded(!isExpanded);

  const relevantNode = data.system.nodes.find(
    (node) => node.name === data.nodeId,
  );
  const relevantAgent = data.agents.find(
    (agent) => agent.id === relevantNode.agent_id,
  );
  const relevantTools = data.tools.filter((tool) =>
    relevantAgent?.tool_ids.includes(tool.id),
  );
  const relevantConnections = data.connections.filter((connection) =>
    relevantTools.some((tool) => tool.connection_ids.includes(connection.id)),
  );

  // Style for the output panel
  const outputStyle = {
    height: "400px",
    overflowY: "auto",
    padding: "4px",
  };
  const processedEvents = mergeTokens(data.llm); // Process to merge adjacent tokens and prepare events for display

  useEffect(() => {
    if (outputRef.current && data.autoScroll) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [processedEvents, data.autoScroll]);

  return (
    <Card title={data.label} bordered={true} style={{ width: 700 }}>
      <Handle type="target" position={Position.Top} />
      <Collapse
        className={"react-flow__allow_select"}
        defaultActiveKey={["4"]}
        onChange={handleToggle}
      >
        <Collapse.Panel header="Agent Details" key="1">
          {relevantAgent ? (
            <div>
              {hasScope("full_access") && (
                <Descriptions size="small" column={1} bordered>
                  <Descriptions.Item label="Type">
                    {relevantAgent.type}
                  </Descriptions.Item>
                  <Descriptions.Item label="LLM Type">
                    {relevantAgent.llm_type}
                  </Descriptions.Item>
                </Descriptions>
              )}

              <Collapse defaultActiveKey={["2"]} ghost>
                {hasScope("full_access") && (
                  <Collapse.Panel
                    header={`Config`}
                    key={"prompt" + relevantAgent.id}
                  >
                    <SyntaxHighlighter
                      className={["nowheel nodrag react-flow__allow_select"]}
                      language="yaml"
                      style={getSyntaxHighlighterStyle(codeHighlight)}
                    >
                      {YAML.stringify(relevantAgent.config)}
                    </SyntaxHighlighter>
                  </Collapse.Panel>
                )}
                <Collapse.Panel
                  header={`Token usage`}
                  key={"token" + relevantAgent.id}
                >
                  <Descriptions size="small" column={1} bordered>
                    <Descriptions.Item label="Total Completion Tokens">
                      {data.usage?.completionTokens ?? 0}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Prompt Tokens">
                      {data.usage?.promptTokens ?? 0}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Completion Credits">
                      {data.usage?.completionCost?.toFixed(6) ?? "0.000000"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Prompt Credits">
                      {data.usage?.promptCost?.toFixed(6) ?? "0.000000"}
                    </Descriptions.Item>
                  </Descriptions>
                </Collapse.Panel>
                {relevantTools.map(
                  (tool, index) =>
                    hasScope("full_access") && (
                      <Collapse.Panel
                        header={`Tool Details: ${tool.type} [${tool.id}]`}
                        key={index}
                      >
                        <SyntaxHighlighter
                          language="yaml"
                          style={getSyntaxHighlighterStyle(codeHighlight)}
                        >
                          {YAML.stringify(tool.config)}
                        </SyntaxHighlighter>
                        <Collapse defaultActiveKey={["3"]} ghost>
                          {relevantConnections
                            .filter((conn) =>
                              tool.connection_ids.includes(conn.id),
                            )
                            .map((connection, idx) => (
                              <Collapse.Panel
                                header={`Connection Details: ${connection.type} [${connection.id}]`}
                                key={idx}
                              >
                                <p>
                                  <strong>Type:</strong> {connection.type}
                                </p>
                                <SyntaxHighlighter
                                  language="yaml"
                                  style={getSyntaxHighlighterStyle(
                                    codeHighlight,
                                  )}
                                >
                                  {YAML.stringify(connection.config)}
                                </SyntaxHighlighter>
                              </Collapse.Panel>
                            ))}
                        </Collapse>
                      </Collapse.Panel>
                    ),
                )}
              </Collapse>
            </div>
          ) : (
            <p>No relevant agent found.</p>
          )}
        </Collapse.Panel>
        <Collapse.Panel header="Output" key="4">
          <div ref={outputRef} className={"nowheel"} style={outputStyle}>
            {processedEvents?.map((event, index) => (
              <EventComponent key={index} event={event} />
            ))}
          </div>
        </Collapse.Panel>
      </Collapse>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
};

export default AgentNodeComponent;

import React, { useContext, useState } from "react";
import { Divider, Collapse } from "antd";
import Markdown from "react-markdown";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { sunburst } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { default as YAML } from "yaml";
import getSyntaxHighlighterStyle from "../components/misc/syntaxHighlight";
import { ThemeContext } from "../components/appMain";

const { Panel } = Collapse;

// Parses and displays JSON content for complex structures
const parseJSONContent = (content) => {
  if (!Array.isArray(content)) {
    console.error("Expected content to be an array but got:", typeof content);
    return null;
  }

  const { codeHighlight } = useContext(ThemeContext);

  return content.map((item, idx) => {
    if (!Array.isArray(item)) {
      item = [item];
    }

    return (
      <div key={idx}>
        {item.map((subItem, subIdx) => (
          <p key={subIdx}>
            <strong>
              {subItem?.kwargs.role} {subItem?.kwargs.name}{" "}
              {subItem?.id[3] === "SystemMessage" ||
              subItem?.id[3] === "ChatGeneration"
                ? "System"
                : ""}{" "}
              :
            </strong>{" "}
            <SyntaxHighlighter
              language="json"
              style={getSyntaxHighlighterStyle(codeHighlight)}
              wrapLines={true}
              lineProps={{ style: { whiteSpace: "pre-wrap" } }}
            >
              {subItem?.kwargs.content ||
                JSON.stringify(
                  subItem?.kwargs.additional_kwargs ||
                    subItem?.kwargs.message.kwargs.content ||
                    subItem?.kwargs.message.kwargs.tool_calls[0].args,
                  null,
                  2,
                )}
              {JSON.stringify(subItem?.kwargs, null, 2)}
            </SyntaxHighlighter>
          </p>
        ))}
      </div>
    );
  });
};

// Event Component: Renders different types of events
const EventComponent = ({ event }) => {
  const [activeKey, setActiveKey] = useState([]);

  const { codeHighlight } = useContext(ThemeContext);

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const renderContent = (content) => {
    if (!Array.isArray(content) || content.length === 0) {
      return <p>No content to display.</p>;
    }

    content = content[0];

    const lastIndex = content.length - 1;
    return (
      <Collapse
        ghost={true}
        defaultActiveKey={["2"]}
        onChange={handlePanelChange}
      >
        {content.length > 1 && (
          <Panel header="Previous Input" key="1">
            {content.slice(0, lastIndex).map((item, idx) => (
              <div key={idx}>{parseJSONContent([item])}</div>
            ))}
          </Panel>
        )}
        <div>{parseJSONContent([content[lastIndex]])}</div>
      </Collapse>
    );
  };

  switch (event.type) {
    case "chat_model_start":
    case "tool_error":
    case "tool_end":
      return (
        <>
          <Divider />
          <div>
            <strong>
              {event.tool ? `[tool: ${event.tool}] ` : ""}
              {event.type.replace(/_/g, " ")}:
            </strong>
            {Array.isArray(event.content) ? (
              renderContent(event.content)
            ) : (
              <SyntaxHighlighter
                language="text"
                style={getSyntaxHighlighterStyle(codeHighlight)}
                wrapLines={true}
                lineProps={{ style: { whiteSpace: "pre-wrap" } }}
              >
                {event.content}
              </SyntaxHighlighter>
            )}
          </div>
        </>
      );
    case "merged_llm_tokens":
      return (
        <>
          <Divider />
          <div>
            <strong>
              {event.tool ? `[tool: ${event.tool}] ` : ""}LLM Stream Output:{" "}
            </strong>
            <Markdown>{event.content}</Markdown>
          </div>
        </>
      );
    case "llm_end":
      return (
        <>
          <Divider />
          <div>
            <strong>
              {event.tool ? `[tool: ${event.tool}] ` : ""}LLM Output:{" "}
            </strong>
            <p>{parseJSONContent(event.content)}</p>
          </div>
        </>
      );
    case "merged_llm_function_calls":
      return (
        <>
          <Divider />
          <div>
            <strong>
              {event.tool ? `[tool: ${event.tool}] ` : ""}LLM Stream Function
              Call:
            </strong>
            <p>{event.content}</p>
            <SyntaxHighlighter
              language="text"
              style={getSyntaxHighlighterStyle(codeHighlight)}
              wrapLines={true}
              lineProps={{ style: { whiteSpace: "pre-wrap" } }}
            >
              {event.functionName + "\n\n" + event.functionArgs}
            </SyntaxHighlighter>
          </div>
        </>
      );
    default:
      return null; // Handle other event types as necessary
  }
};

// Helper function to merge new token events
const mergeTokens = (events) => {
  if (events === undefined) {
    return [];
  }
  const mergedEvents = [];
  let currentTokens = [];
  let currentArgs = [];
  let isFunctionCallInvolved = false;
  let functionName = "";
  let lastEventType = null;

  events.forEach((event) => {
    if (
      event.type === "llm_new_token" ||
      event.type === "llm_new_token__function_call"
    ) {
      if (event.type === "llm_new_token__function_call") {
        isFunctionCallInvolved = true;
        functionName = event.function || functionName;
      }
      const contentToAdd =
        event.type === "llm_new_token__function_call" ? "" : event.content;
      currentTokens.push(contentToAdd);
      if (
        event.arguments &&
        event.arguments !== currentArgs[currentArgs.length - 1]
      ) {
        currentArgs.push(event.arguments);
      }
      lastEventType = event.type;
    } else {
      if (
        event.type === "llm_end" &&
        (lastEventType === "llm_new_token" ||
          lastEventType === "llm_new_token__function_call")
      ) {
        lastEventType = event.type;
        return;
      }

      if (currentTokens.length > 0 || currentArgs.length > 0) {
        mergedEvents.push({
          type: isFunctionCallInvolved
            ? "merged_llm_function_calls"
            : "merged_llm_tokens",
          content: currentTokens.join(""),
          functionName: functionName,
          functionArgs: currentArgs.join(""),
        });
        currentTokens = [];
        currentArgs = [];
        isFunctionCallInvolved = false;
        functionName = "";
      }

      mergedEvents.push(event);
      lastEventType = event.type;
    }
  });

  if (currentTokens.length > 0 || currentArgs.length > 0) {
    mergedEvents.push({
      type: isFunctionCallInvolved
        ? "merged_llm_function_calls"
        : "merged_llm_tokens",
      content: currentTokens.join(""),
      functionName: functionName,
      functionArgs: currentArgs.join(""),
    });
  }

  return mergedEvents;
};

export { EventComponent, mergeTokens, parseJSONContent };

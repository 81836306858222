import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AiTasksApi,
  Configuration,
  HitlApi,
} from "../../../../lib/src/api_client";
import { apiBasePath } from "../../config";
import { EventSourcePolyfill } from "event-source-polyfill";
import HitlRequestFormModal from "./hitlResponseFormModal";
import { App } from "antd";

const HITLSSESubscription = ({
  platform,
  setOpenHitlRequests,
  refreshHitlRequestsIndicator,
  setRefreshHitlRequestsIndicator,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedTaskObject, setSelectedTaskObject] = useState(null);
  const [schema, setSchema] = useState(null);
  const [loading, setLoading] = useState(false);
  const { message, notification } = App.useApp();

  const eventSourceRef = useRef(null);
  const reconnectAttemptsRef = useRef(0); // Keep track of reconnection attempts

  const fetchRequestsREST = async () => {
    const api = new HitlApi(
      new Configuration({
        accessToken: await getAccessTokenSilently(),
        basePath: apiBasePath,
      }),
    );

    try {
      const response = await api.getHitlRequestsAllV1HitlRequestsGet();
      if (response.data.length > 0) {
        setOpenHitlRequests(true);
      } else {
        setOpenHitlRequests(false);
      }
    } catch (error) {
      console.log("Api error: ", error);
      message.error("Could not fetch HITL requests: " + error);
    }
  };

  useEffect(() => {
    setRefreshHitlRequestsIndicator(false);
    fetchRequestsREST();
  }, [refreshHitlRequestsIndicator]);

  useEffect(() => {
    let isCancelled = false;
    let retryTimeoutId = null;

    const setupSSE = async () => {
      const token = await getAccessTokenSilently();

      const headers = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      try {
        console.log("Connecting to HITL requests stream...");
        eventSourceRef.current = new EventSourcePolyfill(
          `${apiBasePath}/v1/hitl/requests/stream`,
          headers,
        );

        // Reset reconnect attempts on successful connection
        reconnectAttemptsRef.current = 0;
      } catch (error) {
        console.error("Failed to create EventSource:", error);
        message.error(
          "Failed to create a streaming connection for HITL requests",
        );
        return;
      }

      const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + "...";
        }
        return text;
      };

      const getRequestContent = (request) => {
        if (request.request_type === "text_input") {
          return request.text;
        } else if (request.request_type === "questions_list") {
          return request.questions.join("; ");
        }
      };

      eventSourceRef.current.onmessage = async (event) => {
        if (isCancelled) return;
        const newRequest = JSON.parse(event.data);

        setOpenHitlRequests(true);

        console.log("New HITL request:", newRequest);

        const task = await getTaskInfo(newRequest.request.task_id);

        const NOTIFICATION_TITLE = `HITL Request - ${task?.system.name}`;
        const NOTIFICATION_BODY = `Request from task "${newRequest.request.task_id}":\n"${truncateText(
          getRequestContent(newRequest.request),
          100,
        )}"`;

        if (platform === "electron") {
          new window.Notification(NOTIFICATION_TITLE, {
            body: NOTIFICATION_BODY,
          }).onclick = () => {
            handleNotificationClick(newRequest);
          };
        }

        notification.info({
          message: NOTIFICATION_TITLE,
          description: NOTIFICATION_BODY,
          onClick: (e) => handleNotificationClick(newRequest, e),
          duration: -1,
          key: newRequest.request.request_id,
        });
      };

      eventSourceRef.current.onerror = (error) => {
        if (isCancelled) return;
        console.error("HITL EventSource failed:", error);
        eventSourceRef.current.close();

        reconnectAttemptsRef.current += 1;

        if (reconnectAttemptsRef.current === 1) {
          // First failure: reconnect immediately without user notification
          console.log("Attempting immediate reconnection to HITL stream...");
          setupSSE();
        } else {
          // Subsequent failures: wait before reconnecting and notify the user
          const retryDelay = 5000; // Wait 5 seconds before reconnecting
          console.error(
            `HITL streaming connection lost. Retrying in ${retryDelay / 1000} seconds...`,
          );
          message.error(
            "HITL streaming connection lost. Retrying in 5 seconds...",
          );
          retryTimeoutId = setTimeout(() => {
            if (!isCancelled) {
              setupSSE();
            }
          }, retryDelay);
        }
      };
    };

    setupSSE();

    return () => {
      isCancelled = true;
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      if (retryTimeoutId) {
        clearTimeout(retryTimeoutId);
      }
    };
  }, [getAccessTokenSilently]);

  async function getTaskInfo(taskId) {
    const tasksApi = new AiTasksApi(
      new Configuration({
        accessToken: await getAccessTokenSilently(),
        basePath: apiBasePath,
      }),
    );

    try {
      const response = await tasksApi.getAiTaskV1AiTasksAiTaskIdGet(taskId);
      return response.data;
    } catch (error) {
      setLoading(false);
      message.error("Could not fetch task: " + error);
      return null;
    }
  }

  async function getAndSetSelectedTaskObject(taskId) {
    const task = await getTaskInfo(taskId);
    setSelectedTaskObject(task);
  }

  const handleNotificationClick = (newRequest, e) => {
    getAndSetSelectedTaskObject(newRequest.request.task_id);
    setSelectedRequest(newRequest.request);
    setSchema(newRequest.response_schema);
    notification.destroy(newRequest.request.request_id);
  };

  if (!!selectedRequest) {
    return (
      <HitlRequestFormModal
        visible={!!selectedRequest}
        onClose={() => {
          setSelectedRequest(null);
          setSchema(null);
          setRefreshHitlRequestsIndicator(true);
        }}
        request={selectedRequest}
        schema={schema}
        task={selectedTaskObject}
      />
    );
  }

  return null;
};

export default HITLSSESubscription;

import React, { useEffect, useState } from "react";
import {
  Table,
  Typography,
  message,
  Button,
  Tooltip,
  Tag,
  Space,
  Spin,
  App,
  Dropdown,
  Menu,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AiTasksApi, Configuration } from "../../../../lib/src/api_client";
import { useAuth0 } from "@auth0/auth0-react";
import { apiBasePath } from "../../config";
import TimestampDisplay from "../misc/timestamp";
import { RadarChartOutlined, RedoOutlined } from "@ant-design/icons";
import TaskCreateModal from "../modals/createAndInvokeTask";
import StatusTag from "../misc/taskStatusTag";
import { Modal, Popconfirm } from "antd";
import { DownOutlined } from "@ant-design/icons";

const { Title } = Typography;

const TasksTable = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [currentTaskInput, setCurrentTaskInput] = useState(null);
  const [currentTaskVariables, setCurrentTaskVariables] = useState(null);
  const [currentTaskSystemId, setCurrentTaskSystemId] = useState(null);
  const [systemIds, setSystemIds] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const { message, notification } = App.useApp();

  const showInvokeModal = (taskId, systemId, taskInput, taskVariables) => {
    setCurrentTaskId(taskId);
    setCurrentTaskSystemId(systemId);
    setCurrentTaskInput(taskInput);
    setCurrentTaskVariables(taskVariables);
    setIsModalVisible(true);
  };

  const handleCancelTask = async (taskId) => {
    const api = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );
    try {
      await api.cancelTaskV1AiTasksTaskIdCancelPost(taskId);
      message.success("Task cancelled successfully");
      fetchTasks();
    } catch (error) {
      message.error("Could not cancel task: " + error);
    }
  };

  const handleDeleteTask = async (taskId) => {
    console.log("Deleting task...");
    const api = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );
    try {
      await api.deleteAiTaskV1AiTasksAiTaskIdDelete(taskId);
      message.success("Task deleted successfully");
      fetchTasks();
    } catch (error) {
      message.error("Could not cancel task: " + error);
    }
  };

  const fetchTasks = async () => {
    setLoading(true);
    const api = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    api
      .getAiTasksV1AiTasksGet()
      .then((response) => {
        setTasks(response.data);
        // get all unique system ids
        const systemIds = new Set();
        response.data.forEach((task) => systemIds.add(task.ai_system_id));
        setSystemIds(Array.from(systemIds));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        message.error("Could not fetch tasks: " + error);
      });
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const columns = [
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      // width: "150px",
      sorter: (a, b) =>
        new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
      render: (_, record) => <TimestampDisplay timestamp={record.created_at} />,
    },
    {
      title: "Status",
      dataIndex: "current_status",
      key: "current_status",
      width: 110,
      align: "center",
      filters: [
        { text: "NOT_STARTED", value: "NOT_STARTED" },
        { text: "PENDING", value: "PENDING" },
        { text: "RUNNING", value: "RUNNING" },
        { text: "COMPLETED", value: "COMPLETED" },
        { text: "FAILED", value: "FAILED" },
      ],
      onFilter: (value, record) => record.current_status === value,
      render: (text) => <StatusTag status={text} />,
    },
    {
      title: "AI System",
      dataIndex: "ai_system_id",
      key: "ai_system_id",
      sorter: (a, b) => a.id.localeCompare(b.id),
      filters: [systemIds.map((id) => ({ text: id, value: id }))].flat(),
      onFilter: (value, record) => record.ai_system_id === value,
      filterSearch: true,
      render: (text) => (
        <Typography.Text code>
          <Link to="/ai_systems/graph" state={{ aiSystemId: text }}>
            {text}
          </Link>
        </Typography.Text>
      ),
    },
    {
      title: "Task",
      key: "id",
      // width: "15%",
      render: (_, record) => (
        <Typography.Paragraph
          code
          style={{ wordBreak: "break-all", minWidth: 170 }}
        >
          <Link
            to="/tasks/view"
            state={{
              taskId: record.id,
              aiSystemId: record.ai_system_id,
            }}
          >
            {record.id}
          </Link>
        </Typography.Paragraph>
      ),
    },
    // {
    //   title: "Created",
    //   dataIndex: "created_at",
    //   key: "created",
    //   render: (ts) => <TimestampDisplay timestamp={ts} />,
    // },
    {
      title: "Input",
      dataIndex: ["input", "messages"],
      key: "task_input",
      minWidth: 200,
      // width: "50%",
      // ellipsis: true,
      // maxWidth: "50%",
      render: (messages) => (
        <Typography.Paragraph
          style={{ maxWidth: 600, minWidth: 200 }}
          ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
        >
          {messages
            ? messages.map((message) => message.content).join(", ")
            : "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "",
      key: "actions",
      width: 50,
      render: (_, record) => {
        const menuItems = [
          {
            key: "details",
            label: "Details",
            onClick: () =>
              navigate("/tasks/view", {
                state: {
                  taskId: record.id,
                  aiSystemId: record.ai_system_id,
                },
              }),
          },
          {
            key: "graph",
            label: "Graph",
            onClick: () =>
              navigate("/ai_systems/graph", {
                state: {
                  taskId: record.id,
                  aiSystemId: record.ai_system_id,
                },
              }),
          },
          {
            key: "clone",
            label: "Clone",
            onClick: () =>
              showInvokeModal(
                getUpdatedRecordId(record.id),
                record.ai_system_id,
                record.input?.messages[0].content,
                record.input?.variables,
              ),
          },
          {
            key: "cancel",
            label: "Cancel",
            disabled:
              record.current_status === "CANCELLED" ||
              record.current_status === "COMPLETED" ||
              record.current_status === "FAILED" ||
              record.current_status === "TIME_OUT",
            onClick: () => handleCancelTask(record.id),
          },
          {
            key: "delete",
            label: (
              <Popconfirm
                title="Are you sure you want to delete this task?"
                onConfirm={() => handleDeleteTask(record.id)}
                okText="Yes"
                cancelText="No"
                okButtonProps={{ danger: true }}
              >
                Delete
              </Popconfirm>
            ),
            onClick: (e) => e.domEvent.stopPropagation(), // Prevent menu from closing
          },
        ];

        return (
          <Dropdown menu={{ items: menuItems }}>
            <Button>
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  function getUpdatedRecordId(id) {
    const timestamp = Date.now();
    const idPattern = /(_re-run-\d+)$/;
    let newId;

    if (idPattern.test(id)) {
      // If the suffix exists, replace it
      newId = id.replace(idPattern, `_re-run-${timestamp}`);
    } else {
      // If the suffix does not exist, append it
      newId = id + `_re-run-${timestamp}`;
    }
    return newId;
  }

  if (error) {
    return <div>Failed to load tasks: {error.message}</div>;
  }

  const handleRowClick = (event, record, rowIndex) => {
    // const targetTag = event.target.tagName.toLowerCase();
    // if (
    //   (targetTag === 'span' && !event.target.closest('button')) ||
    //   targetTag === 'code' ||
    //   targetTag === 'div' ||
    //   targetTag === 'td'
    // ) {
    //   navigate("/tasks/view", {
    //     state: {
    //       taskId: record.id,
    //       aiSystemId: record.ai_system_id,
    //     },
    //   })
    //   // console.log("Clicked element:", targetTag);
    //   // console.log("Row clicked:", record);
    //   // console.log(event)
    // }
  };

  return (
    <>
      <TaskCreateModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        initTaskId={currentTaskId}
        systemId={currentTaskSystemId}
        initInputText={currentTaskInput}
        initVariables={currentTaskVariables}
      />
      <div
        style={{
          padding: "0px",
          height: "100vh",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Table
          columns={columns}
          dataSource={tasks}
          rowKey="id"
          loading={loading}
          pagination={false}
          style={{ minWidth: "750px" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => handleRowClick(event, record, rowIndex),
            };
          }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: event => {
          //       navigate("/tasks/view", {state: {taskId: record.id}});
          //     }, // click row
          //   };
          // }}
        />
      </div>
    </>
  );
};

export default TasksTable;

import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { App, Button, Card, Flex, Image, Layout, theme } from "antd";
import { SideBar } from "./sideBar";
import Home from "./home";
import TasksTable from "./lists/tasksList";
import SystemsTable from "./lists/systemsList";
import AIComponent from "./graph";
import AISystemEditor from "./aiSystemEditor";
import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ConnectionsTable from "./lists/connectionsList";
import ToolsTable from "./lists/toolsList";
import AgentsTable from "./lists/agentsList";
import HitlRequestsTable from "./lists/hitlRequests";
import HITLSSESubscription from "./hitl/subscription";
import { useEffect, useState } from "react";
import UsageDetails from "./usage";

import logo from "../assets/logo.png";
import TaskInfoCard from "./views/task";
import ChangelogComponent from "./views/changelog";
import TaskLauncher from "./taskLauncher";
import Settings from "./misc/settings";
import SettingsPage from "./misc/settings";
import { ScopeProvider } from "../../providers/scopeProvider";
import ContactComponent from "./views/contact";
import { Content } from "antd/es/layout/layout";

export const AppContent = (props) => {
  const {
    isAuthenticated,
    loginWithPopup,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const [openHitlRequests, setOpenHitlRequests] = useState(false);
  const [refreshHitlRequestsIndicator, setRefreshHitlRequestsIndicator] =
    useState(false);
  const [loginAttempted, setLoginAttempted] = useState(false);

  // fetch token on mount
  useEffect(() => {
    async function fetchToken() {
      const sleep = (milliseconds) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      };
      await sleep(3000);
      getAccessTokenSilently()
        .then((token) => {})
        .catch((error) => {
          console.log("Error fetching token: ", error);
          if (!loginAttempted) {
            if (props.platform === "electron") {
              loginWithPopup();
            } else {
              // Uncomment to automatically redirect to login page
              // loginWithRedirect();
            }
            setLoginAttempted(true);
          }
        });
      if (isAuthenticated) {
        setLoginAttempted(true);
      }
    }
    fetchToken();
  }, []);

  if (isAuthenticated) {
    return (
      // <HashRouter>
      <BrowserRouter>
        <ScopeProvider>
          <Layout style={{ minHeight: "100vh" }}>
            <SideBar
              platform={props.platform}
              openHitlRequests={openHitlRequests}
            />
            <Layout>
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/task_launcher" element={<TaskLauncher />} />
                <Route path="/tasks" element={<TasksTable />} />
                <Route path="/tasks/view" element={<TaskInfoCard />} />
                <Route path="/connections" element={<ConnectionsTable />} />
                <Route path="/tools" element={<ToolsTable />} />
                <Route path="/agents" element={<AgentsTable />} />
                <Route
                  path="/hitl/requests"
                  element={
                    <HitlRequestsTable
                      setRefreshHitlRequestsIndicator={
                        setRefreshHitlRequestsIndicator
                      }
                    />
                  }
                />
                <Route path="/ai_systems" element={<SystemsTable />} />
                <Route path="/ai_systems/graph" element={<AIComponent />} />
                <Route path="/ai_systems/edit" element={<AISystemEditor />} />
                <Route path="/usage" element={<UsageDetails />} />
                <Route path="/settings" element={<SettingsPage />} />

                <Route path="/changelog" element={<ChangelogComponent />} />
                <Route path="/contact" element={<ContactComponent />} />
              </Routes>
            </Layout>
            <HITLSSESubscription
              setOpenHitlRequests={setOpenHitlRequests}
              setRefreshHitlRequestsIndicator={setRefreshHitlRequestsIndicator}
              refreshHitlRequestsIndicator={refreshHitlRequestsIndicator}
            />
          </Layout>
        </ScopeProvider>
      </BrowserRouter>
      // </HashRouter>
    );
  } else {
    return (
      <Layout>
        <Content>
          <Flex
            gap="middle"
            align="center"
            justify="center"
            style={{ height: "100vh" }}
            vertical
          >
            <Image style={{ width: 300 }} preview={false} src={logo} />
            <div style={{ width: 200 }}>
              <Button
                onClick={() =>
                  props.platform === "electron"
                    ? loginWithPopup()
                    : loginWithRedirect()
                }
                style={{ width: "100%", marginTop: 100 }}
                size={"lg"}
              >
                Login
              </Button>
            </div>
          </Flex>
        </Content>
      </Layout>
    );
  }
};

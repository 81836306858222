import React, { useEffect, useState } from "react";
import {
  App,
  Button,
  Flex,
  Progress,
  Tooltip,
  Tree,
  Typography,
  Upload,
} from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Configuration, ConnectionsApi } from "../../api_client";
import { apiBasePath } from "../../config";
import { CloudDownloadOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";

const StorageContents = ({ taskId, connectionId }) => {
  const [connectionContents, setConnectionContents] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [errorLoadingData, setErrorLoadingData] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { message, notification } = App.useApp();

  const buildTree = (paths) => {
    const tree = [];

    paths.forEach(({ key, download_url, size }) => {
      const parts = key.split("/");
      let currentLevel = tree;

      parts.forEach((part, index) => {
        let existingPath = currentLevel.find((item) => item.title === part);

        if (!existingPath) {
          existingPath = {
            title: part,
            key: parts.slice(0, index + 1).join("/"),
            children: [],
            isLeaf: index === parts.length - 1,
            download_url: index === parts.length - 1 ? download_url : undefined,
            size: index === parts.length - 1 ? size : undefined,
          };
          currentLevel.push(existingPath);
        }

        currentLevel = existingPath.children;
      });
    });

    return tree;
  };

  const formatSize = (size) => {
    if (size === undefined) return "";
    const units = ["B", "KB", "MB", "GB", "TB"];
    let unitIndex = 0;
    let fileSize = size;

    while (fileSize >= 1024 && unitIndex < units.length - 1) {
      fileSize /= 1024;
      unitIndex++;
    }

    return `${fileSize.toFixed(2)} ${units[unitIndex]}`;
  };

  const renderTreeNodes = (data) =>
    data.map((item) => {
      const title = item.isLeaf
        ? `${item.title} (${formatSize(item.size)})`
        : item.title;

      if (item.children) {
        return {
          title,
          key: item.key,
          children: renderTreeNodes(item.children),
          download_url: item.download_url,
        };
      }

      return {
        title,
        key: item.key,
        isLeaf: item.isLeaf,
        download_url: item.download_url,
      };
    });

  useEffect(() => {
    async function load() {
      setAccessToken(await getAccessTokenSilently());
      await loadStorageContents();
    }
    load();
  }, [taskId, connectionId]);

  const loadStorageContents = async () => {
    const api = new ConnectionsApi(
      new Configuration({
        accessToken: await getAccessTokenSilently(),
        basePath: apiBasePath,
      }),
    );
    api
      .listContentsV1ConnectionsStorageConnectionIdTaskIdGet(
        connectionId,
        taskId,
      )
      .then((response) => {
        setConnectionContents(response.data);
        const tree = buildTree(response.data);
        setTreeData(tree);
        setErrorLoadingData(false);
      })
      .catch((error) => {
        console.error("Failed to fetch storage contents: ", error);
        message.error("Failed to fetch storage contents: " + error);
        setErrorLoadingData(true);
      });
  };

  const onSelect = (keys, event) => {
    console.log("Trigger Select", keys, event.node);
    if (event.node.download_url) {
      console.log("Download URL: ", event.node.download_url);
      window.open(event.node.download_url, "_blank");
    }
  };

  const handleUploadChange = async ({ file, fileList, event }) => {
    if (file.status === "done") {
      message.success(`${file.name} file uploaded successfully`);
      // Refresh the tree data after upload
      await loadStorageContents();
    } else if (file.status === "error") {
      message.error(`${file.name} file upload failed.`);
    }
  };

  const handleDownloadZip = async () => {
    try {
      const token = await getAccessTokenSilently();
      const downloadUrl = `${apiBasePath}/v1/connections/storage/${connectionId}/${taskId}/download`;
      const fullUrl = `${downloadUrl}?token=${token}`;
      window.open(fullUrl, "_blank");
    } catch (error) {
      message.error("Failed to download ZIP");
    }
  };

  return (
    <div>
      {errorLoadingData && (
        <Typography.Text type="danger">
          Failed to load storage contents
        </Typography.Text>
      )}
      <Tree showLine treeData={renderTreeNodes(treeData)} onSelect={onSelect} />
      <Flex vertical gap="small" style={{ marginTop: 10 }}>
        <Button
          type="primary"
          icon={<CloudDownloadOutlined />}
          onClick={handleDownloadZip}
          style={{ width: 250 }}
          // style={{ marginBottom: 16 }}
        >
          Download ZIP
        </Button>
        <Upload
          action={`${apiBasePath}/v1/connections/storage/${connectionId}/${taskId}/upload_zip`}
          headers={{ Authorization: `Bearer ${accessToken}` }}
          onChange={handleUploadChange}
        >
          <Button
            icon={<UploadOutlined />}
            style={{ width: 250 }}
            // style={{ marginBottom: 16 }}
          >
            Upload ZIP
          </Button>
        </Upload>
        <Upload
          action={`${apiBasePath}/v1/connections/storage/${connectionId}/${taskId}/upload`}
          headers={{ Authorization: `Bearer ${accessToken}` }}
          onChange={handleUploadChange}
          directory
        >
          <Button
            icon={<UploadOutlined />}
            style={{ width: 250 }}
            // style={{ marginBottom: 16 }}
          >
            Upload Directory
          </Button>
        </Upload>
        <Upload
          action={`${apiBasePath}/v1/connections/storage/${connectionId}/${taskId}/upload`}
          headers={{ Authorization: `Bearer ${accessToken}` }}
          onChange={handleUploadChange}
          multiple
        >
          <Button
            icon={<UploadOutlined />}
            style={{ width: 250 }}
            // style={{ marginBottom: 16 }}
          >
            Upload Files
          </Button>
        </Upload>
      </Flex>
    </div>
  );
};

export default StorageContents;

import React, { useEffect, useState } from "react";
import { App, Button, message, Table } from "antd";
import { AgentsApi, Configuration } from "../../../../lib/src/api_client";
import { useAuth0 } from "@auth0/auth0-react";
import { apiBasePath } from "../../config";

const AgentsTable = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const { message, notification } = App.useApp();

  useEffect(() => {
    const fetchAgents = async () => {
      setLoading(true);
      const api = new AgentsApi(
        new Configuration({
          accessToken: getAccessTokenSilently,
          basePath: apiBasePath,
        }),
      );

      api
        .getAgentsV1AgentsGet()
        .then((response) => {
          setAgents(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Api error: ", error);
          setLoading(false);
          setError(error);
          message.error("Could not fetch agents: " + error);
        });
    };
    fetchAgents();
  }, []);

  const columns = [
    {
      title: "Agent ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "LLM",
      dataIndex: "llm_type",
      key: "llm_type",
    },
    {
      title: "Tools",
      dataIndex: "tool_ids",
      key: "tool_ids",
      // render: (tool_ids) => tool_ids.map((agent) => agent.tool_ids).join(", "),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => <></>,
    },
  ];

  if (error) {
    return <div>Failed to load agents: {error.message}</div>;
  }

  return (
    <>
      <div
        style={{
          padding: "0px",
          height: "100vh",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Table
          columns={columns}
          dataSource={agents}
          rowKey="id"
          loading={loading}
          pagination={false}
          // pagination={{
          //     pageSize: 10, // Adjust number of items per page according to your preference
          // }}
        />
      </div>
    </>
  );
};

export default AgentsTable;

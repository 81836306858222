import React, { useEffect, useState, useRef } from "react";
import { Modal, Input, Form, message, App } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AiSystemsApi,
  AiTasksApi,
  Configuration,
} from "../../../../lib/src/api_client";
import { apiBasePath } from "../../config";

const CloneSystemModal = ({ visible, onCancel, onOk, system }) => {
  const [systemId, setSystemId] = useState("");
  const navigate = useNavigate();
  const inputRef = useRef(null); // Reference for the input text area
  const { getAccessTokenSilently, user } = useAuth0();
  const { message, notification } = App.useApp();

  // Effect to set default taskId and focus the input when modal opens
  useEffect(() => {
    if (visible) {
      if (!systemId) {
        setSystemId(system.id + "-clone");
      }
      setTimeout(() => inputRef.current?.focus(), 100); // Focus the input text area slightly after modal appears
    }
  }, [visible, systemId]);

  const handleCreate = async () => {
    if (!systemId) {
      message.error("Please enter a System ID");
      return;
    }

    const accessToken = await getAccessTokenSilently();
    const api = new AiSystemsApi(
      new Configuration({
        accessToken: accessToken,
        basePath: apiBasePath,
      }),
    );

    api
      .upsertAiSystemV1AiSystemsPost({
        id: systemId,
        name: system.name,
        description: system.description,
        system: system.system,
        agents: [],
        tools: [],
        connections: [],
      })
      .then((response) => {
        message.success("AI System cloned!");
        if (typeof onOk === "function") {
          onOk();
        }
      })
      .catch((error) => {
        message.error("Error cloning AI system: " + error.message);
        console.error("Error:", error);
      });
  };

  return (
    <Modal
      title="Clone AI System"
      open={visible}
      onOk={handleCreate}
      onCancel={onCancel}
      okText="Clone"
      cancelText="Cancel"
    >
      <Form
        layout="vertical"
        onFinish={handleCreate} // Submit the form when the Enter key is pressed
      >
        <Form.Item label="New ID">
          <Input
            value={systemId}
            onChange={(e) => setSystemId(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CloneSystemModal;

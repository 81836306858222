import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// @ts-ignore
import AppMain from "../../lib/src/components/appMain";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://6809f419e94553be47d52f654f3cce18@o4507566594785280.ingest.de.sentry.io/4507910578962512",
  // integrations: [
  //   Sentry.replayIntegration(),
  // ],
  // // Session Replay
  // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(<AppMain />);

import React, { useEffect, useState } from "react";
import { Card, Descriptions, Table, Select, Spin, Statistic, App } from "antd";
import { Bar } from "react-chartjs-2";
import { BillingApi, Configuration } from "../../../lib/src/api_client";
import { apiBasePath } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

// Register necessary chart.js components
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const { Option } = Select;

const UsageDetails = () => {
  const [account, setAccount] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [aggregatedTransactions, setAggregatedTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groupBy, setGroupBy] = useState("day");
  const { getAccessTokenSilently } = useAuth0();
  const { message, notification } = App.useApp();

  useEffect(() => {
    const fetchData = async () => {
      const api = new BillingApi(
        new Configuration({
          accessToken: await getAccessTokenSilently(),
          basePath: apiBasePath,
        }),
      );

      try {
        const accountResponse = await api.getAccountV1BillingAccountGet();
        const transactionsResponse =
          await api.getTransactionsV1BillingTransactionsGet();

        let aggregatedResponse;
        try {
          aggregatedResponse =
            await api.getAggregatedTransactionsV1BillingTransactionsAggregatedGet(
              groupBy,
              30,
            );
          setAggregatedTransactions(aggregatedResponse.data);
        } catch (aggregatedError) {
          if (
            aggregatedError.response &&
            aggregatedError.response.status === 404
          ) {
            // If 404, set aggregated transactions to an empty array
            setAggregatedTransactions([]);
          } else {
            throw aggregatedError; // Rethrow if it's another type of error
          }
        }

        setAccount(accountResponse.data);
        setTransactions(transactionsResponse.data);
        setLoading(false);
      } catch (error) {
        message.error("Failed to fetch billing details");
        setLoading(false);
      }
    };

    fetchData();
  }, [groupBy]);

  const handleGroupByChange = (value) => {
    setGroupBy(value);
  };

  const renderBarPlot = () => {
    if (!aggregatedTransactions || aggregatedTransactions.length === 0) {
      return <p>No aggregated transactions data available.</p>;
    }

    const labels = aggregatedTransactions.map((item) => item.interval);
    const data = aggregatedTransactions.map((item) => item.total_amount);

    const chartData = {
      labels,
      datasets: [
        {
          label: "Aggregated Transactions",
          data,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };

    return (
      <Bar
        data={chartData}
        options={{
          scales: {
            x: {
              type: "time",
              time: {
                unit: groupBy === "day" ? "day" : "hour",
                tooltipFormat: "PPpp",
                displayFormats: {
                  day: "MMM dd",
                  hour: "MMM dd HH:mm",
                },
              },
              title: {
                display: true,
                text: groupBy === "day" ? "Days" : "Hours",
              },
            },
            y: {
              title: {
                display: true,
                text: "Transaction Amount",
              },
            },
          },
        }}
      />
    );
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div style={{ height: "100vh", overflowY: "auto", padding: "16px" }}>
      <Card title="Account Information">
        <Descriptions bordered>
          <Descriptions.Item label="Balance">
            <Statistic
              value={account?.balance}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              prefix=""
              suffix="credits"
            />
          </Descriptions.Item>
          <Descriptions.Item label="Account ID">
            {account?.id}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card title="Aggregated Transactions" style={{ marginTop: 16 }}>
        <Select
          value={groupBy}
          onChange={handleGroupByChange}
          style={{ marginBottom: 16 }}
        >
          <Option value="day">Day</Option>
          <Option value="hour">Hour</Option>
        </Select>
        {renderBarPlot()}
      </Card>

      <Card title="Transactions" style={{ marginTop: 16 }}>
        <Table
          dataSource={transactions}
          rowKey={"id"}
          pagination={{ pageSize: 50 }}
          columns={[
            {
              title: "Date",
              dataIndex: "timestamp",
              key: "date",
              sorter: (a, b) =>
                new Date(b.timestamp).valueOf() -
                new Date(a.timestamp).valueOf(),
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              sorter: (a, b) => b.amount - a.amount,
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
          ]}
        />
      </Card>
    </div>
  );
};

export default UsageDetails;

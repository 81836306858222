import React, { useState, useRef, useEffect } from "react";
import { Card, Collapse, Descriptions } from "antd";
import { Handle, Position } from "reactflow";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import yaml from "react-syntax-highlighter/dist/esm/languages/hljs/yaml";

SyntaxHighlighter.registerLanguage("yaml", yaml);

const EndNodeComponent = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const relevantNode = data.system.nodes.find(
    (node) => node.name === data.nodeId,
  );
  // const relevantAgent = data.agents.find(agent => agent.id === relevantNode.agent_id)
  // const relevantTools = data.tools.filter(tool => relevantAgent?.tool_ids.includes(tool.id))
  // const relevantConnections = data.connections.filter(connection => relevantTools.some(tool => tool.connection_ids.includes(connection.id)))

  // Style for the output panel
  const outputStyle = {
    height: "250px",
    overflowY: "auto",
    padding: "4px",
  };

  return (
    <Card title="END" bordered={true} style={{ width: 200 }}>
      <Handle type="target" position={Position.Top} />
    </Card>
  );
};

export default EndNodeComponent;

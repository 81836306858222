import React, { useEffect, useState } from "react";
import { Content, Header } from "antd/es/layout/layout";
import SpotlightComponent from "./views/spotlight";

export const Home = () => {
  return (
    <Content>
      <SpotlightComponent></SpotlightComponent>
    </Content>
  );
};

export default Home;

import React, { useEffect, useState, useRef } from "react";
import { Modal, Input, Form, message, App } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AiTasksApi, Configuration } from "../../../../lib/src/api_client";
import { apiBasePath } from "../../config";

const TaskInvocationModal = ({
  visible,
  onCancel,
  systemId,
  initInputText,
  initTaskId,
}) => {
  const [taskId, setTaskId] = useState("");
  const [inputText, setInputText] = useState("");
  const navigate = useNavigate();
  const inputRef = useRef(null); // Reference for the input text area
  const { getAccessTokenSilently } = useAuth0();
  const { message } = App.useApp();

  // Effect to set default taskId and focus the input when modal opens
  useEffect(() => {
    if (visible && systemId) {
      if (initTaskId) {
        setTaskId(initTaskId);
      }
      setInputText(initInputText);
      setTimeout(() => inputRef.current?.focus(), 100); // Focus the input text area slightly after modal appears
    }
  }, [visible, systemId]);

  const handleInvokeTask = () => {
    if (!taskId) {
      message.error("Please enter a Task ID");
      return;
    }
    if (!inputText) {
      message.error("Please enter the input text");
      return;
    }

    // Invoke the task
    const api = new AiTasksApi(
      new Configuration({
        accessToken: getAccessTokenSilently,
        basePath: apiBasePath,
      }),
    );

    api
      .invokeV1AiTasksTaskIdInvokePost(taskId, {
        messages: [
          {
            role: "user",
            content: inputText,
            type: "chat",
          },
        ],
      })
      .then((response) => {
        message.success("Task invoked successfully");

        navigate("/ai_systems/graph", {
          state: {
            taskId: taskId,
            aiSystemId: systemId,
          },
        });
      })
      .catch((error) => {
        if (error.response.status === 402) {
          message.error(
            "Can not invoke task: insufficient credit balance. Please contact support.",
          );
          console.error("Error:", error);
        } else {
          message.error("Error invoking task: " + error.message);
          console.error("Error:", error);
        }
      });
  };

  return (
    <Modal
      title="Run Task"
      open={visible}
      onOk={handleInvokeTask}
      onCancel={onCancel}
      okText="Invoke Task"
      cancelText="Cancel"
    >
      <Form
        layout="vertical"
        onFinish={handleInvokeTask} // Submit the form when the Enter key is pressed
      >
        <Form.Item label="Task ID">
          <Input
            value={taskId}
            onChange={(e) => setTaskId(e.target.value)}
            placeholder="Enter the Task ID"
          />
        </Form.Item>
        <Form.Item label="Input Text for Task">
          <Input.TextArea
            ref={inputRef} // Attach the ref to the Input.TextArea
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            rows={4}
            placeholder="Enter the text to be processed by the task"
            onPressEnter={handleInvokeTask} // Handle submission on Enter key press
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TaskInvocationModal;

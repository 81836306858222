/* tslint:disable */
/* eslint-disable */
/**
 * AetherxCore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface AISystem
 */
export interface AISystem {
  /**
   *
   * @type {string}
   * @memberof AISystem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AISystem
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AISystem
   */
  description?: string;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof AISystem
   */
  groups?: Array<Array<string>> | null;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof AISystem
   */
  users?: Array<Array<string>> | null;
  /**
   *
   * @type {AISystemSchema}
   * @memberof AISystem
   */
  system: AISystemSchema;
  /**
   *
   * @type {object}
   * @memberof AISystem
   */
  extra_inputs_schema?: object | null;
}
/**
 *
 * @export
 * @interface AISystemSchema
 */
export interface AISystemSchema {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof AISystemSchema
   */
  id?: string;
  /**
   *
   * @type {{ [key: string]: { [key: string]: StateSchemaValueValue; }; }}
   * @memberof AISystemSchema
   */
  state_schema: { [key: string]: { [key: string]: StateSchemaValueValue } };
  /**
   *
   * @type {Array<NodesInner>}
   * @memberof AISystemSchema
   */
  nodes: Array<NodesInner>;
  /**
   *
   * @type {Array<EdgesInner>}
   * @memberof AISystemSchema
   */
  edges: Array<EdgesInner>;
  /**
   *
   * @type {string}
   * @memberof AISystemSchema
   */
  entrypoint_node_name: string;
}
/**
 *
 * @export
 * @interface AccountModel
 */
export interface AccountModel {
  /**
   *
   * @type {string}
   * @memberof AccountModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AccountModel
   */
  balance: string;
}
/**
 * @type Agent
 * @export
 */
export type Agent =
  | ({ type: "OpenAILLMAgent" } & OpenAILLMAgent)
  | ({
      type: "OpenAILLMStructuredOutputAgent";
    } & OpenAILLMStructuredOutputAgent)
  | ({ type: "OpenAILLMSupervisorAgent" } & OpenAILLMSupervisorAgent);

/**
 * @type AgentsInner
 * @export
 */
export type AgentsInner =
  | ({ type: "OpenAILLMAgent" } & OpenAILLMAgent)
  | ({
      type: "OpenAILLMStructuredOutputAgent";
    } & OpenAILLMStructuredOutputAgent)
  | ({ type: "OpenAILLMSupervisorAgent" } & OpenAILLMSupervisorAgent);

/**
 *
 * @export
 * @interface AggregatedTransactionModel
 */
export interface AggregatedTransactionModel {
  /**
   *
   * @type {string}
   * @memberof AggregatedTransactionModel
   */
  interval: string;
  /**
   *
   * @type {string}
   * @memberof AggregatedTransactionModel
   */
  total_amount: string;
}
/**
 *
 * @export
 * @interface BodyCreateAiTaskV1AiTasksPost
 */
export interface BodyCreateAiTaskV1AiTasksPost {
  /**
   *
   * @type {string}
   * @memberof BodyCreateAiTaskV1AiTasksPost
   */
  ai_task_id: string;
  /**
   *
   * @type {string}
   * @memberof BodyCreateAiTaskV1AiTasksPost
   */
  ai_system_id: string;
  /**
   *
   * @type {ChatPromptValueConcreteWithVars}
   * @memberof BodyCreateAiTaskV1AiTasksPost
   */
  input?: ChatPromptValueConcreteWithVars | null;
}
/**
 *
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
  /**
   *
   * @type {Content}
   * @memberof ChatMessage
   */
  content: Content;
  /**
   *
   * @type {object}
   * @memberof ChatMessage
   */
  additional_kwargs?: object | null;
  /**
   *
   * @type {object}
   * @memberof ChatMessage
   */
  response_metadata?: object | null;
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  type: ChatMessageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  role?: string;
}

export const ChatMessageTypeEnum = {
  Chat: "chat",
} as const;

export type ChatMessageTypeEnum =
  (typeof ChatMessageTypeEnum)[keyof typeof ChatMessageTypeEnum];

/**
 *
 * @export
 * @interface ChatPromptValueConcreteWithVars
 */
export interface ChatPromptValueConcreteWithVars {
  /**
   *
   * @type {Array<ChatPromptValueConcreteWithVarsMessagesInner>}
   * @memberof ChatPromptValueConcreteWithVars
   */
  messages?: Array<ChatPromptValueConcreteWithVarsMessagesInner>;
  /**
   *
   * @type {string}
   * @memberof ChatPromptValueConcreteWithVars
   */
  type?: ChatPromptValueConcreteWithVarsTypeEnum;
  /**
   *
   * @type {object}
   * @memberof ChatPromptValueConcreteWithVars
   */
  variables?: object;
}

export const ChatPromptValueConcreteWithVarsTypeEnum = {
  ChatPromptValueConcrete: "ChatPromptValueConcrete",
} as const;

export type ChatPromptValueConcreteWithVarsTypeEnum =
  (typeof ChatPromptValueConcreteWithVarsTypeEnum)[keyof typeof ChatPromptValueConcreteWithVarsTypeEnum];

/**
 * @type ChatPromptValueConcreteWithVarsMessagesInner
 * @export
 */
export type ChatPromptValueConcreteWithVarsMessagesInner =
  | ({ type: "chat" } & ChatMessage)
  | ({ type: "human" } & HumanMessage)
  | ({ type: "system" } & SystemMessage);

/**
 *
 * @export
 * @interface CloudStorageTool
 */
export interface CloudStorageTool {
  /**
   *
   * @type {string}
   * @memberof CloudStorageTool
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CloudStorageTool
   */
  type: CloudStorageToolTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof CloudStorageTool
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {object}
   * @memberof CloudStorageTool
   */
  config: object;
}

export const CloudStorageToolTypeEnum = {
  CloudStorageTool: "CloudStorageTool",
} as const;

export type CloudStorageToolTypeEnum =
  (typeof CloudStorageToolTypeEnum)[keyof typeof CloudStorageToolTypeEnum];

/**
 *
 * @export
 * @interface CompleteAISystemSchemaInput
 */
export interface CompleteAISystemSchemaInput {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof CompleteAISystemSchemaInput
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CompleteAISystemSchemaInput
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteAISystemSchemaInput
   */
  description?: string;
  /**
   *
   * @type {Groups}
   * @memberof CompleteAISystemSchemaInput
   */
  groups?: Groups;
  /**
   *
   * @type {Users}
   * @memberof CompleteAISystemSchemaInput
   */
  users?: Users;
  /**
   *
   * @type {AISystemSchema}
   * @memberof CompleteAISystemSchemaInput
   */
  system: AISystemSchema;
  /**
   *
   * @type {ExtraInputsSchema}
   * @memberof CompleteAISystemSchemaInput
   */
  extra_inputs_schema?: ExtraInputsSchema;
  /**
   *
   * @type {Array<AgentsInner>}
   * @memberof CompleteAISystemSchemaInput
   */
  agents: Array<AgentsInner>;
  /**
   *
   * @type {Array<ToolsInner>}
   * @memberof CompleteAISystemSchemaInput
   */
  tools: Array<ToolsInner>;
  /**
   *
   * @type {Array<ConnectionsInner>}
   * @memberof CompleteAISystemSchemaInput
   */
  connections: Array<ConnectionsInner>;
}
/**
 *
 * @export
 * @interface CompleteAISystemSchemaOutput
 */
export interface CompleteAISystemSchemaOutput {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof CompleteAISystemSchemaOutput
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CompleteAISystemSchemaOutput
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteAISystemSchemaOutput
   */
  description?: string;
  /**
   *
   * @type {Groups}
   * @memberof CompleteAISystemSchemaOutput
   */
  groups?: Groups;
  /**
   *
   * @type {Users}
   * @memberof CompleteAISystemSchemaOutput
   */
  users?: Users;
  /**
   *
   * @type {AISystemSchema}
   * @memberof CompleteAISystemSchemaOutput
   */
  system: AISystemSchema;
  /**
   *
   * @type {ExtraInputsSchema}
   * @memberof CompleteAISystemSchemaOutput
   */
  extra_inputs_schema?: ExtraInputsSchema;
  /**
   *
   * @type {Array<AgentsInner>}
   * @memberof CompleteAISystemSchemaOutput
   */
  agents: Array<AgentsInner>;
  /**
   *
   * @type {Array<ToolsInner1>}
   * @memberof CompleteAISystemSchemaOutput
   */
  tools: Array<ToolsInner1>;
  /**
   *
   * @type {Array<ConnectionsInner>}
   * @memberof CompleteAISystemSchemaOutput
   */
  connections: Array<ConnectionsInner>;
}
/**
 *
 * @export
 * @interface ConditionalEdge
 */
export interface ConditionalEdge {
  /**
   *
   * @type {string}
   * @memberof ConditionalEdge
   */
  edge_type?: ConditionalEdgeEdgeTypeEnum;
  /**
   *
   * @type {StartNode}
   * @memberof ConditionalEdge
   */
  start_node: StartNode;
  /**
   * A valid python expression of a condition to check. Takes dict with state as input variable: `state` and returns the next node.
   * @type {string}
   * @memberof ConditionalEdge
   */
  condition: string;
  /**
   * Mapping of the condition output to the next node.
   * @type {{ [key: string]: string; }}
   * @memberof ConditionalEdge
   */
  mapping: { [key: string]: string };
}

export const ConditionalEdgeEdgeTypeEnum = {
  ConditionalEdge: "ConditionalEdge",
} as const;

export type ConditionalEdgeEdgeTypeEnum =
  (typeof ConditionalEdgeEdgeTypeEnum)[keyof typeof ConditionalEdgeEdgeTypeEnum];

/**
 *
 * @export
 * @interface ConfirmationHITL
 */
export interface ConfirmationHITL {
  /**
   *
   * @type {string}
   * @memberof ConfirmationHITL
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ConfirmationHITL
   */
  type: ConfirmationHITLTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ConfirmationHITL
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {object}
   * @memberof ConfirmationHITL
   */
  config: object;
}

export const ConfirmationHITLTypeEnum = {
  ConfirmationHitl: "ConfirmationHITL",
} as const;

export type ConfirmationHITLTypeEnum =
  (typeof ConfirmationHITLTypeEnum)[keyof typeof ConfirmationHITLTypeEnum];

/**
 * @type Connection
 * @export
 */
export type Connection =
  | ({ type: "DEVLocalStorageConnector" } & DEVLocalStorageConnector)
  | ({ type: "KubernetesPodWithSSH" } & KubernetesPodWithSSH)
  | ({
      type: "KubernetesPodWithSSHWithS3CSIMount";
    } & KubernetesPodWithSSHWithS3CSIMount)
  | ({ type: "LocalDockerContainerWithSSH" } & LocalDockerContainerWithSSH)
  | ({
      type: "PersistedCloudStorageConnector";
    } & PersistedCloudStorageConnector)
  | ({ type: "RedisHITLConnector" } & RedisHITLConnector)
  | ({ type: "SingleUserHITLConnector" } & SingleUserHITLConnector)
  | ({ type: "TaskCloudStorageConnector" } & TaskCloudStorageConnector);

/**
 * @type ConnectionsInner
 * @export
 */
export type ConnectionsInner =
  | ({ type: "DEVLocalStorageConnector" } & DEVLocalStorageConnector)
  | ({ type: "KubernetesPodWithSSH" } & KubernetesPodWithSSH)
  | ({
      type: "KubernetesPodWithSSHWithS3CSIMount";
    } & KubernetesPodWithSSHWithS3CSIMount)
  | ({ type: "LocalDockerContainerWithSSH" } & LocalDockerContainerWithSSH)
  | ({
      type: "PersistedCloudStorageConnector";
    } & PersistedCloudStorageConnector)
  | ({ type: "RedisHITLConnector" } & RedisHITLConnector)
  | ({ type: "SingleUserHITLConnector" } & SingleUserHITLConnector)
  | ({ type: "TaskCloudStorageConnector" } & TaskCloudStorageConnector);

/**
 *
 * @export
 * @interface Content
 */
export interface Content {}
/**
 *
 * @export
 * @interface ContentAnyOfInner
 */
export interface ContentAnyOfInner {}
/**
 *
 * @export
 * @interface CreditTransactionModel
 */
export interface CreditTransactionModel {
  /**
   *
   * @type {number}
   * @memberof CreditTransactionModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CreditTransactionModel
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof CreditTransactionModel
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof CreditTransactionModel
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof CreditTransactionModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CreditTransactionModel
   */
  from_account_id: string;
  /**
   *
   * @type {string}
   * @memberof CreditTransactionModel
   */
  to_account_id: string;
}
/**
 *
 * @export
 * @interface DEVLocalStorageConnectionConfig
 */
export interface DEVLocalStorageConnectionConfig {
  /**
   *
   * @type {string}
   * @memberof DEVLocalStorageConnectionConfig
   */
  base_path: string;
  /**
   *
   * @type {string}
   * @memberof DEVLocalStorageConnectionConfig
   */
  initialize_from: string | null;
}
/**
 *
 * @export
 * @interface DEVLocalStorageConnector
 */
export interface DEVLocalStorageConnector {
  /**
   *
   * @type {string}
   * @memberof DEVLocalStorageConnector
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DEVLocalStorageConnector
   */
  type: DEVLocalStorageConnectorTypeEnum;
  /**
   *
   * @type {DEVLocalStorageConnectionConfig}
   * @memberof DEVLocalStorageConnector
   */
  config: DEVLocalStorageConnectionConfig;
}

export const DEVLocalStorageConnectorTypeEnum = {
  DevLocalStorageConnector: "DEVLocalStorageConnector",
} as const;

export type DEVLocalStorageConnectorTypeEnum =
  (typeof DEVLocalStorageConnectorTypeEnum)[keyof typeof DEVLocalStorageConnectorTypeEnum];

/**
 *
 * @export
 * @interface DockerShellTool
 */
export interface DockerShellTool {
  /**
   *
   * @type {string}
   * @memberof DockerShellTool
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DockerShellTool
   */
  type: DockerShellToolTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof DockerShellTool
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {DockerShellToolConfig}
   * @memberof DockerShellTool
   */
  config: DockerShellToolConfig;
}

export const DockerShellToolTypeEnum = {
  DockerShellTool: "DockerShellTool",
} as const;

export type DockerShellToolTypeEnum =
  (typeof DockerShellToolTypeEnum)[keyof typeof DockerShellToolTypeEnum];

/**
 *
 * @export
 * @interface DockerShellToolConfig
 */
export interface DockerShellToolConfig {
  /**
   *
   * @type {string}
   * @memberof DockerShellToolConfig
   */
  init_dir?: string | null;
  /**
   * Maximum number of characters in the tool output
   * @type {number}
   * @memberof DockerShellToolConfig
   */
  tool_output_limit?: number;
  /**
   * Whether to allow running arbitrary shell commands
   * @type {boolean}
   * @memberof DockerShellToolConfig
   */
  let_run_arbitrary_commands?: boolean;
  /**
   * Commands to run on container initialization after copying the directory
   * @type {Array<string>}
   * @memberof DockerShellToolConfig
   */
  init_commands?: Array<string>;
  /**
   * Block the root directory listing command
   * @type {boolean}
   * @memberof DockerShellToolConfig
   */
  block_root_ls?: boolean;
}
/**
 *
 * @export
 * @interface DummyHITL
 */
export interface DummyHITL {
  /**
   *
   * @type {string}
   * @memberof DummyHITL
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DummyHITL
   */
  type: DummyHITLTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof DummyHITL
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {object}
   * @memberof DummyHITL
   */
  config: object;
}

export const DummyHITLTypeEnum = {
  DummyHitl: "DummyHITL",
} as const;

export type DummyHITLTypeEnum =
  (typeof DummyHITLTypeEnum)[keyof typeof DummyHITLTypeEnum];

/**
 * @type EdgesInner
 * @export
 */
export type EdgesInner =
  | ({ edge_type: "ConditionalEdge" } & ConditionalEdge)
  | ({ edge_type: "StaticEdge" } & StaticEdge);

/**
 *
 * @export
 * @enum {string}
 */

export const EmbeddingModel = {
  Ada002: "text-embedding-ada-002",
  _3Large: "text-embedding-3-large",
  _3Small: "text-embedding-3-small",
} as const;

export type EmbeddingModel =
  (typeof EmbeddingModel)[keyof typeof EmbeddingModel];

/**
 *
 * @export
 * @interface EndNode
 */
export interface EndNode {
  /**
   *
   * @type {string}
   * @memberof EndNode
   */
  node_type?: EndNodeNodeTypeEnum;
  /**
   *
   * @type {string}
   * @memberof EndNode
   */
  name: string;
}

export const EndNodeNodeTypeEnum = {
  End: "END",
} as const;

export type EndNodeNodeTypeEnum =
  (typeof EndNodeNodeTypeEnum)[keyof typeof EndNodeNodeTypeEnum];

/**
 *
 * @export
 * @interface ExtraInputsSchema
 */
export interface ExtraInputsSchema {}
/**
 *
 * @export
 * @interface Groups
 */
export interface Groups {}
/**
 *
 * @export
 * @interface HITLConfirmationRequest
 */
export interface HITLConfirmationRequest {
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationRequest
   */
  connection_id: string;
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationRequest
   */
  task_id: string;
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationRequest
   */
  request_id?: string;
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationRequest
   */
  request_type?: HITLConfirmationRequestRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationRequest
   */
  timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationRequest
   */
  text: string;
}

export const HITLConfirmationRequestRequestTypeEnum = {
  Confirmation: "confirmation",
} as const;

export type HITLConfirmationRequestRequestTypeEnum =
  (typeof HITLConfirmationRequestRequestTypeEnum)[keyof typeof HITLConfirmationRequestRequestTypeEnum];

/**
 *
 * @export
 * @interface HITLConfirmationResponse
 */
export interface HITLConfirmationResponse {
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationResponse
   */
  connection_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationResponse
   */
  request_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationResponse
   */
  request_type?: HITLConfirmationResponseRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof HITLConfirmationResponse
   */
  timestamp?: string;
  /**
   *
   * @type {boolean}
   * @memberof HITLConfirmationResponse
   */
  accepted?: boolean;
}

export const HITLConfirmationResponseRequestTypeEnum = {
  Confirmation: "confirmation",
} as const;

export type HITLConfirmationResponseRequestTypeEnum =
  (typeof HITLConfirmationResponseRequestTypeEnum)[keyof typeof HITLConfirmationResponseRequestTypeEnum];

/**
 *
 * @export
 * @interface HITLQuestionsListRequest
 */
export interface HITLQuestionsListRequest {
  /**
   *
   * @type {string}
   * @memberof HITLQuestionsListRequest
   */
  connection_id: string;
  /**
   *
   * @type {string}
   * @memberof HITLQuestionsListRequest
   */
  task_id: string;
  /**
   *
   * @type {string}
   * @memberof HITLQuestionsListRequest
   */
  request_id?: string;
  /**
   *
   * @type {string}
   * @memberof HITLQuestionsListRequest
   */
  request_type?: HITLQuestionsListRequestRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof HITLQuestionsListRequest
   */
  timestamp?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof HITLQuestionsListRequest
   */
  questions: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof HITLQuestionsListRequest
   */
  default_answers: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof HITLQuestionsListRequest
   */
  allow_additional?: boolean;
}

export const HITLQuestionsListRequestRequestTypeEnum = {
  QuestionsList: "questions_list",
} as const;

export type HITLQuestionsListRequestRequestTypeEnum =
  (typeof HITLQuestionsListRequestRequestTypeEnum)[keyof typeof HITLQuestionsListRequestRequestTypeEnum];

/**
 *
 * @export
 * @interface HITLQuestionsListResponse
 */
export interface HITLQuestionsListResponse {
  /**
   *
   * @type {string}
   * @memberof HITLQuestionsListResponse
   */
  connection_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof HITLQuestionsListResponse
   */
  request_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof HITLQuestionsListResponse
   */
  request_type?: HITLQuestionsListResponseRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof HITLQuestionsListResponse
   */
  timestamp?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof HITLQuestionsListResponse
   */
  answers: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof HITLQuestionsListResponse
   */
  accepted?: boolean;
}

export const HITLQuestionsListResponseRequestTypeEnum = {
  QuestionsList: "questions_list",
} as const;

export type HITLQuestionsListResponseRequestTypeEnum =
  (typeof HITLQuestionsListResponseRequestTypeEnum)[keyof typeof HITLQuestionsListResponseRequestTypeEnum];

/**
 *
 * @export
 * @interface HITLRequest
 */
export interface HITLRequest {
  /**
   *
   * @type {Request}
   * @memberof HITLRequest
   */
  request: Request;
  /**
   * JSON schema of the response, used to respond to request in the endpoint id:`hitl_respond_v1_hitl__hitl_connection_id___request_id__post` (`/v1/hitl/{hitl_connection_id}/{request_id}`)
   * @type {object}
   * @memberof HITLRequest
   */
  response_schema: object;
}
/**
 *
 * @export
 * @interface HITLTextInputRequest
 */
export interface HITLTextInputRequest {
  /**
   *
   * @type {string}
   * @memberof HITLTextInputRequest
   */
  connection_id: string;
  /**
   *
   * @type {string}
   * @memberof HITLTextInputRequest
   */
  task_id: string;
  /**
   *
   * @type {string}
   * @memberof HITLTextInputRequest
   */
  request_id?: string;
  /**
   *
   * @type {string}
   * @memberof HITLTextInputRequest
   */
  request_type?: HITLTextInputRequestRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof HITLTextInputRequest
   */
  timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof HITLTextInputRequest
   */
  text: string;
}

export const HITLTextInputRequestRequestTypeEnum = {
  TextInput: "text_input",
} as const;

export type HITLTextInputRequestRequestTypeEnum =
  (typeof HITLTextInputRequestRequestTypeEnum)[keyof typeof HITLTextInputRequestRequestTypeEnum];

/**
 *
 * @export
 * @interface HITLTextInputResponse
 */
export interface HITLTextInputResponse {
  /**
   *
   * @type {string}
   * @memberof HITLTextInputResponse
   */
  connection_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof HITLTextInputResponse
   */
  request_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof HITLTextInputResponse
   */
  request_type?: HITLTextInputResponseRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof HITLTextInputResponse
   */
  timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof HITLTextInputResponse
   */
  text: string;
  /**
   *
   * @type {boolean}
   * @memberof HITLTextInputResponse
   */
  accepted?: boolean;
}

export const HITLTextInputResponseRequestTypeEnum = {
  TextInput: "text_input",
} as const;

export type HITLTextInputResponseRequestTypeEnum =
  (typeof HITLTextInputResponseRequestTypeEnum)[keyof typeof HITLTextInputResponseRequestTypeEnum];

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface HumanMessage
 */
export interface HumanMessage {
  /**
   *
   * @type {Content}
   * @memberof HumanMessage
   */
  content: Content;
  /**
   *
   * @type {object}
   * @memberof HumanMessage
   */
  additional_kwargs?: object | null;
  /**
   *
   * @type {object}
   * @memberof HumanMessage
   */
  response_metadata?: object | null;
  /**
   *
   * @type {string}
   * @memberof HumanMessage
   */
  type: HumanMessageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof HumanMessage
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof HumanMessage
   */
  id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof HumanMessage
   */
  example?: boolean | null;
}

export const HumanMessageTypeEnum = {
  Human: "human",
} as const;

export type HumanMessageTypeEnum =
  (typeof HumanMessageTypeEnum)[keyof typeof HumanMessageTypeEnum];

/**
 *
 * @export
 * @interface KubernetesPodWithSSH
 */
export interface KubernetesPodWithSSH {
  /**
   *
   * @type {string}
   * @memberof KubernetesPodWithSSH
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof KubernetesPodWithSSH
   */
  type: KubernetesPodWithSSHTypeEnum;
  /**
   *
   * @type {KubernetesPodWithSSHConfig}
   * @memberof KubernetesPodWithSSH
   */
  config: KubernetesPodWithSSHConfig;
}

export const KubernetesPodWithSSHTypeEnum = {
  KubernetesPodWithSsh: "KubernetesPodWithSSH",
} as const;

export type KubernetesPodWithSSHTypeEnum =
  (typeof KubernetesPodWithSSHTypeEnum)[keyof typeof KubernetesPodWithSSHTypeEnum];

/**
 *
 * @export
 * @interface KubernetesPodWithSSHConfig
 */
export interface KubernetesPodWithSSHConfig {
  /**
   *
   * @type {string}
   * @memberof KubernetesPodWithSSHConfig
   */
  image_name?: string;
  /**
   *
   * @type {string}
   * @memberof KubernetesPodWithSSHConfig
   */
  volume_path_container?: string;
  /**
   *
   * @type {boolean}
   * @memberof KubernetesPodWithSSHConfig
   */
  clean_up_volume?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof KubernetesPodWithSSHConfig
   */
  init_commands?: Array<string>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof KubernetesPodWithSSHConfig
   */
  resource_requests?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof KubernetesPodWithSSHConfig
   */
  resource_limits?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface KubernetesPodWithSSHWithS3CSIMount
 */
export interface KubernetesPodWithSSHWithS3CSIMount {
  /**
   *
   * @type {string}
   * @memberof KubernetesPodWithSSHWithS3CSIMount
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof KubernetesPodWithSSHWithS3CSIMount
   */
  type: KubernetesPodWithSSHWithS3CSIMountTypeEnum;
  /**
   *
   * @type {KubernetesPodWithSSHConfig}
   * @memberof KubernetesPodWithSSHWithS3CSIMount
   */
  config: KubernetesPodWithSSHConfig;
}

export const KubernetesPodWithSSHWithS3CSIMountTypeEnum = {
  KubernetesPodWithSshWithS3CsiMount: "KubernetesPodWithSSHWithS3CSIMount",
} as const;

export type KubernetesPodWithSSHWithS3CSIMountTypeEnum =
  (typeof KubernetesPodWithSSHWithS3CSIMountTypeEnum)[keyof typeof KubernetesPodWithSSHWithS3CSIMountTypeEnum];

/**
 *
 * @export
 * @interface LLMNode
 */
export interface LLMNode {
  /**
   *
   * @type {string}
   * @memberof LLMNode
   */
  node_type?: LLMNodeNodeTypeEnum;
  /**
   *
   * @type {string}
   * @memberof LLMNode
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof LLMNode
   */
  agent_id: string;
}

export const LLMNodeNodeTypeEnum = {
  Llm: "LLM",
} as const;

export type LLMNodeNodeTypeEnum =
  (typeof LLMNodeNodeTypeEnum)[keyof typeof LLMNodeNodeTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const LLMTypes = {
  _35Turbo0125: "gpt-3.5-turbo-0125",
  _4Turbo: "gpt-4-turbo",
  _4o: "gpt-4o",
  _4oMini: "gpt-4o-mini",
} as const;

export type LLMTypes = (typeof LLMTypes)[keyof typeof LLMTypes];

/**
 *
 * @export
 * @interface LocalDockerContainerWithSSH
 */
export interface LocalDockerContainerWithSSH {
  /**
   *
   * @type {string}
   * @memberof LocalDockerContainerWithSSH
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof LocalDockerContainerWithSSH
   */
  type: LocalDockerContainerWithSSHTypeEnum;
  /**
   *
   * @type {LocalDockerContainerWithSSHConfig}
   * @memberof LocalDockerContainerWithSSH
   */
  config: LocalDockerContainerWithSSHConfig;
}

export const LocalDockerContainerWithSSHTypeEnum = {
  LocalDockerContainerWithSsh: "LocalDockerContainerWithSSH",
} as const;

export type LocalDockerContainerWithSSHTypeEnum =
  (typeof LocalDockerContainerWithSSHTypeEnum)[keyof typeof LocalDockerContainerWithSSHTypeEnum];

/**
 *
 * @export
 * @interface LocalDockerContainerWithSSHConfig
 */
export interface LocalDockerContainerWithSSHConfig {
  /**
   *
   * @type {string}
   * @memberof LocalDockerContainerWithSSHConfig
   */
  image_name?: string;
  /**
   *
   * @type {string}
   * @memberof LocalDockerContainerWithSSHConfig
   */
  volume_path_container?: string;
  /**
   *
   * @type {boolean}
   * @memberof LocalDockerContainerWithSSHConfig
   */
  clean_up_volume?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof LocalDockerContainerWithSSHConfig
   */
  init_commands?: Array<string>;
}
/**
 * @type NodesInner
 * @export
 */
export type NodesInner =
  | ({ node_type: "END" } & EndNode)
  | ({ node_type: "LLM" } & LLMNode);

/**
 *
 * @export
 * @interface OpenAILLMAgent
 */
export interface OpenAILLMAgent {
  /**
   *
   * @type {string}
   * @memberof OpenAILLMAgent
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OpenAILLMAgent
   */
  type: OpenAILLMAgentTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof OpenAILLMAgent
   */
  tool_ids: Array<string>;
  /**
   *
   * @type {LLMTypes}
   * @memberof OpenAILLMAgent
   */
  llm_type: LLMTypes;
  /**
   *
   * @type {OpenAILLMAgentConfig}
   * @memberof OpenAILLMAgent
   */
  config: OpenAILLMAgentConfig;
}

export const OpenAILLMAgentTypeEnum = {
  OpenAillmAgent: "OpenAILLMAgent",
} as const;

export type OpenAILLMAgentTypeEnum =
  (typeof OpenAILLMAgentTypeEnum)[keyof typeof OpenAILLMAgentTypeEnum];

/**
 *
 * @export
 * @interface OpenAILLMAgentConfig
 */
export interface OpenAILLMAgentConfig {
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof OpenAILLMAgentConfig
   */
  prompt_template: Array<Array<string>>;
  /**
   *
   * @type {OutputKey}
   * @memberof OpenAILLMAgentConfig
   */
  output_key?: OutputKey;
}
/**
 *
 * @export
 * @interface OpenAILLMStructuredOutputAgent
 */
export interface OpenAILLMStructuredOutputAgent {
  /**
   *
   * @type {string}
   * @memberof OpenAILLMStructuredOutputAgent
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OpenAILLMStructuredOutputAgent
   */
  type: OpenAILLMStructuredOutputAgentTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof OpenAILLMStructuredOutputAgent
   */
  tool_ids: Array<string>;
  /**
   *
   * @type {LLMTypes}
   * @memberof OpenAILLMStructuredOutputAgent
   */
  llm_type: LLMTypes;
  /**
   *
   * @type {OpenAILLMStructuredOutputAgentConfig}
   * @memberof OpenAILLMStructuredOutputAgent
   */
  config: OpenAILLMStructuredOutputAgentConfig;
}

export const OpenAILLMStructuredOutputAgentTypeEnum = {
  OpenAillmStructuredOutputAgent: "OpenAILLMStructuredOutputAgent",
} as const;

export type OpenAILLMStructuredOutputAgentTypeEnum =
  (typeof OpenAILLMStructuredOutputAgentTypeEnum)[keyof typeof OpenAILLMStructuredOutputAgentTypeEnum];

/**
 *
 * @export
 * @interface OpenAILLMStructuredOutputAgentConfig
 */
export interface OpenAILLMStructuredOutputAgentConfig {
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof OpenAILLMStructuredOutputAgentConfig
   */
  prompt_template: Array<Array<string>>;
  /**
   * The output schema of the agent in JSON Schema format. The output will be added to the graph\'s state, therefore use keys matching the state schema.
   * @type {object}
   * @memberof OpenAILLMStructuredOutputAgentConfig
   */
  output_schema: object;
}
/**
 *
 * @export
 * @interface OpenAILLMSupervisorAgent
 */
export interface OpenAILLMSupervisorAgent {
  /**
   *
   * @type {string}
   * @memberof OpenAILLMSupervisorAgent
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OpenAILLMSupervisorAgent
   */
  type: OpenAILLMSupervisorAgentTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof OpenAILLMSupervisorAgent
   */
  tool_ids: Array<string>;
  /**
   *
   * @type {LLMTypes}
   * @memberof OpenAILLMSupervisorAgent
   */
  llm_type: LLMTypes;
  /**
   *
   * @type {OpenAILLMSupervisorAgentConfig}
   * @memberof OpenAILLMSupervisorAgent
   */
  config: OpenAILLMSupervisorAgentConfig;
}

export const OpenAILLMSupervisorAgentTypeEnum = {
  OpenAillmSupervisorAgent: "OpenAILLMSupervisorAgent",
} as const;

export type OpenAILLMSupervisorAgentTypeEnum =
  (typeof OpenAILLMSupervisorAgentTypeEnum)[keyof typeof OpenAILLMSupervisorAgentTypeEnum];

/**
 *
 * @export
 * @interface OpenAILLMSupervisorAgentConfig
 */
export interface OpenAILLMSupervisorAgentConfig {
  /**
   *
   * @type {Array<string>}
   * @memberof OpenAILLMSupervisorAgentConfig
   */
  options: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof OpenAILLMSupervisorAgentConfig
   */
  prompt_template: Array<Array<string>>;
}
/**
 *
 * @export
 * @interface OutputKey
 */
export interface OutputKey {}
/**
 *
 * @export
 * @interface PersistedCloudStorageConnector
 */
export interface PersistedCloudStorageConnector {
  /**
   *
   * @type {string}
   * @memberof PersistedCloudStorageConnector
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PersistedCloudStorageConnector
   */
  type: PersistedCloudStorageConnectorTypeEnum;
  /**
   *
   * @type {object}
   * @memberof PersistedCloudStorageConnector
   */
  config: object;
}

export const PersistedCloudStorageConnectorTypeEnum = {
  PersistedCloudStorageConnector: "PersistedCloudStorageConnector",
} as const;

export type PersistedCloudStorageConnectorTypeEnum =
  (typeof PersistedCloudStorageConnectorTypeEnum)[keyof typeof PersistedCloudStorageConnectorTypeEnum];

/**
 *
 * @export
 * @interface PythonDevDockerShellTool
 */
export interface PythonDevDockerShellTool {
  /**
   *
   * @type {string}
   * @memberof PythonDevDockerShellTool
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PythonDevDockerShellTool
   */
  type: PythonDevDockerShellToolTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof PythonDevDockerShellTool
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {DockerShellToolConfig}
   * @memberof PythonDevDockerShellTool
   */
  config: DockerShellToolConfig;
}

export const PythonDevDockerShellToolTypeEnum = {
  PythonDevDockerShellTool: "PythonDevDockerShellTool",
} as const;

export type PythonDevDockerShellToolTypeEnum =
  (typeof PythonDevDockerShellToolTypeEnum)[keyof typeof PythonDevDockerShellToolTypeEnum];

/**
 *
 * @export
 * @interface QuestionsListHITL
 */
export interface QuestionsListHITL {
  /**
   *
   * @type {string}
   * @memberof QuestionsListHITL
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof QuestionsListHITL
   */
  type: QuestionsListHITLTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof QuestionsListHITL
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {object}
   * @memberof QuestionsListHITL
   */
  config: object;
}

export const QuestionsListHITLTypeEnum = {
  QuestionsListHitl: "QuestionsListHITL",
} as const;

export type QuestionsListHITLTypeEnum =
  (typeof QuestionsListHITLTypeEnum)[keyof typeof QuestionsListHITLTypeEnum];

/**
 *
 * @export
 * @interface RedisHITLConnector
 */
export interface RedisHITLConnector {
  /**
   *
   * @type {string}
   * @memberof RedisHITLConnector
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RedisHITLConnector
   */
  type: RedisHITLConnectorTypeEnum;
  /**
   *
   * @type {object}
   * @memberof RedisHITLConnector
   */
  config: object;
}

export const RedisHITLConnectorTypeEnum = {
  RedisHitlConnector: "RedisHITLConnector",
} as const;

export type RedisHITLConnectorTypeEnum =
  (typeof RedisHITLConnectorTypeEnum)[keyof typeof RedisHITLConnectorTypeEnum];

/**
 * @type Request
 * @export
 */
export type Request =
  | ({ request_type: "confirmation" } & HITLConfirmationRequest)
  | ({ request_type: "questions_list" } & HITLQuestionsListRequest)
  | ({ request_type: "text_input" } & HITLTextInputRequest);

/**
 * @type Response
 * @export
 */
export type Response =
  | ({ request_type: "confirmation" } & HITLConfirmationResponse)
  | ({ request_type: "questions_list" } & HITLQuestionsListResponse)
  | ({ request_type: "text_input" } & HITLTextInputResponse);

/**
 *
 * @export
 * @interface ResponseUpsertAgentV1AgentsPost
 */
export interface ResponseUpsertAgentV1AgentsPost {
  /**
   *
   * @type {string}
   * @memberof ResponseUpsertAgentV1AgentsPost
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ResponseUpsertAgentV1AgentsPost
   */
  type: ResponseUpsertAgentV1AgentsPostTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ResponseUpsertAgentV1AgentsPost
   */
  tool_ids: Array<string>;
  /**
   *
   * @type {LLMTypes}
   * @memberof ResponseUpsertAgentV1AgentsPost
   */
  llm_type: LLMTypes;
  /**
   *
   * @type {OpenAILLMSupervisorAgentConfig}
   * @memberof ResponseUpsertAgentV1AgentsPost
   */
  config: OpenAILLMSupervisorAgentConfig;
}

export const ResponseUpsertAgentV1AgentsPostTypeEnum = {
  OpenAillmAgent: "OpenAILLMAgent",
  OpenAillmStructuredOutputAgent: "OpenAILLMStructuredOutputAgent",
  OpenAillmSupervisorAgent: "OpenAILLMSupervisorAgent",
} as const;

export type ResponseUpsertAgentV1AgentsPostTypeEnum =
  (typeof ResponseUpsertAgentV1AgentsPostTypeEnum)[keyof typeof ResponseUpsertAgentV1AgentsPostTypeEnum];

/**
 *
 * @export
 * @interface ResponseUpsertConnectionV1ConnectionsPost
 */
export interface ResponseUpsertConnectionV1ConnectionsPost {
  /**
   *
   * @type {string}
   * @memberof ResponseUpsertConnectionV1ConnectionsPost
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ResponseUpsertConnectionV1ConnectionsPost
   */
  type: ResponseUpsertConnectionV1ConnectionsPostTypeEnum;
  /**
   *
   * @type {object}
   * @memberof ResponseUpsertConnectionV1ConnectionsPost
   */
  config: object;
}

export const ResponseUpsertConnectionV1ConnectionsPostTypeEnum = {
  DevLocalStorageConnector: "DEVLocalStorageConnector",
  RedisHitlConnector: "RedisHITLConnector",
  SingleUserHitlConnector: "SingleUserHITLConnector",
  LocalDockerContainerWithSsh: "LocalDockerContainerWithSSH",
  KubernetesPodWithSsh: "KubernetesPodWithSSH",
  KubernetesPodWithSshWithS3CsiMount: "KubernetesPodWithSSHWithS3CSIMount",
  TaskCloudStorageConnector: "TaskCloudStorageConnector",
  PersistedCloudStorageConnector: "PersistedCloudStorageConnector",
} as const;

export type ResponseUpsertConnectionV1ConnectionsPostTypeEnum =
  (typeof ResponseUpsertConnectionV1ConnectionsPostTypeEnum)[keyof typeof ResponseUpsertConnectionV1ConnectionsPostTypeEnum];

/**
 *
 * @export
 * @interface ResponseUpsertToolV1ToolsPost
 */
export interface ResponseUpsertToolV1ToolsPost {
  /**
   *
   * @type {string}
   * @memberof ResponseUpsertToolV1ToolsPost
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ResponseUpsertToolV1ToolsPost
   */
  type: ResponseUpsertToolV1ToolsPostTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ResponseUpsertToolV1ToolsPost
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {object}
   * @memberof ResponseUpsertToolV1ToolsPost
   */
  config: object;
}

export const ResponseUpsertToolV1ToolsPostTypeEnum = {
  DummyHitl: "DummyHITL",
  ConfirmationHitl: "ConfirmationHITL",
  TextInputHitl: "TextInputHITL",
  QuestionsListHitl: "QuestionsListHITL",
  WebResearchTool: "WebResearchTool",
  DockerShellTool: "DockerShellTool",
  PythonDevDockerShellTool: "PythonDevDockerShellTool",
  TaskStorageTool: "TaskStorageTool",
  CloudStorageTool: "CloudStorageTool",
} as const;

export type ResponseUpsertToolV1ToolsPostTypeEnum =
  (typeof ResponseUpsertToolV1ToolsPostTypeEnum)[keyof typeof ResponseUpsertToolV1ToolsPostTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const Role = {
  Owner: "OWNER",
  Viewer: "VIEWER",
} as const;

export type Role = (typeof Role)[keyof typeof Role];

/**
 *
 * @export
 * @interface ShareRequest
 */
export interface ShareRequest {
  /**
   *
   * @type {string}
   * @memberof ShareRequest
   */
  user_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShareRequest
   */
  group_id?: string | null;
  /**
   *
   * @type {Role}
   * @memberof ShareRequest
   */
  role: Role;
}

/**
 *
 * @export
 * @interface SingleUserHITLConnector
 */
export interface SingleUserHITLConnector {
  /**
   *
   * @type {string}
   * @memberof SingleUserHITLConnector
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SingleUserHITLConnector
   */
  type: SingleUserHITLConnectorTypeEnum;
  /**
   *
   * @type {object}
   * @memberof SingleUserHITLConnector
   */
  config: object;
}

export const SingleUserHITLConnectorTypeEnum = {
  SingleUserHitlConnector: "SingleUserHITLConnector",
} as const;

export type SingleUserHITLConnectorTypeEnum =
  (typeof SingleUserHITLConnectorTypeEnum)[keyof typeof SingleUserHITLConnectorTypeEnum];

/**
 *
 * @export
 * @interface StartNode
 */
export interface StartNode {}
/**
 *
 * @export
 * @interface StateSchemaValueValue
 */
export interface StateSchemaValueValue {}
/**
 *
 * @export
 * @interface StaticEdge
 */
export interface StaticEdge {
  /**
   *
   * @type {string}
   * @memberof StaticEdge
   */
  edge_type?: StaticEdgeEdgeTypeEnum;
  /**
   *
   * @type {StartNode}
   * @memberof StaticEdge
   */
  start_node: StartNode;
  /**
   *
   * @type {string}
   * @memberof StaticEdge
   */
  end_node: string;
}

export const StaticEdgeEdgeTypeEnum = {
  StaticEdge: "StaticEdge",
} as const;

export type StaticEdgeEdgeTypeEnum =
  (typeof StaticEdgeEdgeTypeEnum)[keyof typeof StaticEdgeEdgeTypeEnum];

/**
 *
 * @export
 * @interface StorageConnectorFile
 */
export interface StorageConnectorFile {
  /**
   *
   * @type {string}
   * @memberof StorageConnectorFile
   */
  key: string;
  /**
   *
   * @type {number}
   * @memberof StorageConnectorFile
   */
  size: number;
  /**
   *
   * @type {string}
   * @memberof StorageConnectorFile
   */
  last_modified: string;
  /**
   *
   * @type {string}
   * @memberof StorageConnectorFile
   */
  download_url?: string | null;
}
/**
 *
 * @export
 * @interface SystemMessage
 */
export interface SystemMessage {
  /**
   *
   * @type {Content}
   * @memberof SystemMessage
   */
  content: Content;
  /**
   *
   * @type {object}
   * @memberof SystemMessage
   */
  additional_kwargs?: object | null;
  /**
   *
   * @type {object}
   * @memberof SystemMessage
   */
  response_metadata?: object | null;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  type: SystemMessageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  id?: string | null;
}

export const SystemMessageTypeEnum = {
  System: "system",
} as const;

export type SystemMessageTypeEnum =
  (typeof SystemMessageTypeEnum)[keyof typeof SystemMessageTypeEnum];

/**
 *
 * @export
 * @interface TaskCloudStorageConnector
 */
export interface TaskCloudStorageConnector {
  /**
   *
   * @type {string}
   * @memberof TaskCloudStorageConnector
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TaskCloudStorageConnector
   */
  type: TaskCloudStorageConnectorTypeEnum;
  /**
   *
   * @type {object}
   * @memberof TaskCloudStorageConnector
   */
  config: object;
}

export const TaskCloudStorageConnectorTypeEnum = {
  TaskCloudStorageConnector: "TaskCloudStorageConnector",
} as const;

export type TaskCloudStorageConnectorTypeEnum =
  (typeof TaskCloudStorageConnectorTypeEnum)[keyof typeof TaskCloudStorageConnectorTypeEnum];

/**
 *
 * @export
 * @interface TaskStorageTool
 */
export interface TaskStorageTool {
  /**
   *
   * @type {string}
   * @memberof TaskStorageTool
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TaskStorageTool
   */
  type: TaskStorageToolTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof TaskStorageTool
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {TaskStorageToolConfig}
   * @memberof TaskStorageTool
   */
  config: TaskStorageToolConfig;
}

export const TaskStorageToolTypeEnum = {
  TaskStorageTool: "TaskStorageTool",
} as const;

export type TaskStorageToolTypeEnum =
  (typeof TaskStorageToolTypeEnum)[keyof typeof TaskStorageToolTypeEnum];

/**
 *
 * @export
 * @interface TaskStorageToolConfig
 */
export interface TaskStorageToolConfig {
  /**
   *
   * @type {Array<string>}
   * @memberof TaskStorageToolConfig
   */
  preparation_commands?: Array<string>;
}
/**
 *
 * @export
 * @interface TextInputHITL
 */
export interface TextInputHITL {
  /**
   *
   * @type {string}
   * @memberof TextInputHITL
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TextInputHITL
   */
  type: TextInputHITLTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof TextInputHITL
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {object}
   * @memberof TextInputHITL
   */
  config: object;
}

export const TextInputHITLTypeEnum = {
  TextInputHitl: "TextInputHITL",
} as const;

export type TextInputHITLTypeEnum =
  (typeof TextInputHITLTypeEnum)[keyof typeof TextInputHITLTypeEnum];

/**
 * @type Tool
 * @export
 */
export type Tool =
  | ({ type: "CloudStorageTool" } & CloudStorageTool)
  | ({ type: "ConfirmationHITL" } & ConfirmationHITL)
  | ({ type: "DockerShellTool" } & DockerShellTool)
  | ({ type: "DummyHITL" } & DummyHITL)
  | ({ type: "PythonDevDockerShellTool" } & PythonDevDockerShellTool)
  | ({ type: "QuestionsListHITL" } & QuestionsListHITL)
  | ({ type: "TaskStorageTool" } & TaskStorageTool)
  | ({ type: "TextInputHITL" } & TextInputHITL)
  | ({ type: "WebResearchTool" } & WebResearchToolInput);

/**
 * @type ToolsInner
 * @export
 */
export type ToolsInner =
  | ({ type: "CloudStorageTool" } & CloudStorageTool)
  | ({ type: "ConfirmationHITL" } & ConfirmationHITL)
  | ({ type: "DockerShellTool" } & DockerShellTool)
  | ({ type: "DummyHITL" } & DummyHITL)
  | ({ type: "PythonDevDockerShellTool" } & PythonDevDockerShellTool)
  | ({ type: "QuestionsListHITL" } & QuestionsListHITL)
  | ({ type: "TaskStorageTool" } & TaskStorageTool)
  | ({ type: "TextInputHITL" } & TextInputHITL)
  | ({ type: "WebResearchTool" } & WebResearchToolInput);

/**
 * @type ToolsInner1
 * @export
 */
export type ToolsInner1 =
  | ({ type: "CloudStorageTool" } & CloudStorageTool)
  | ({ type: "ConfirmationHITL" } & ConfirmationHITL)
  | ({ type: "DockerShellTool" } & DockerShellTool)
  | ({ type: "DummyHITL" } & DummyHITL)
  | ({ type: "PythonDevDockerShellTool" } & PythonDevDockerShellTool)
  | ({ type: "QuestionsListHITL" } & QuestionsListHITL)
  | ({ type: "TaskStorageTool" } & TaskStorageTool)
  | ({ type: "TextInputHITL" } & TextInputHITL)
  | ({ type: "WebResearchTool" } & WebResearchToolOutput);

/**
 *
 * @export
 * @interface Users
 */
export interface Users {}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<ValidationErrorLocInner>}
   * @memberof ValidationError
   */
  loc: Array<ValidationErrorLocInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {}
/**
 *
 * @export
 * @interface WebResearchToolConfig
 */
export interface WebResearchToolConfig {
  /**
   * Number of search results to retrieve per query
   * @type {number}
   * @memberof WebResearchToolConfig
   */
  num_search_results?: number;
  /**
   * Number of questions to generate
   * @type {number}
   * @memberof WebResearchToolConfig
   */
  n_questions_to_generate?: number;
  /**
   * Embedding model to use
   * @type {EmbeddingModel}
   * @memberof WebResearchToolConfig
   */
  embedding_model?: EmbeddingModel;
}

/**
 *
 * @export
 * @interface WebResearchToolInput
 */
export interface WebResearchToolInput {
  /**
   *
   * @type {string}
   * @memberof WebResearchToolInput
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof WebResearchToolInput
   */
  type: WebResearchToolInputTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof WebResearchToolInput
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {WebResearchToolConfig}
   * @memberof WebResearchToolInput
   */
  config: WebResearchToolConfig;
}

export const WebResearchToolInputTypeEnum = {
  WebResearchTool: "WebResearchTool",
} as const;

export type WebResearchToolInputTypeEnum =
  (typeof WebResearchToolInputTypeEnum)[keyof typeof WebResearchToolInputTypeEnum];

/**
 *
 * @export
 * @interface WebResearchToolOutput
 */
export interface WebResearchToolOutput {
  /**
   *
   * @type {string}
   * @memberof WebResearchToolOutput
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof WebResearchToolOutput
   */
  type: WebResearchToolOutputTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof WebResearchToolOutput
   */
  connection_ids: Array<string>;
  /**
   *
   * @type {WebResearchToolConfig}
   * @memberof WebResearchToolOutput
   */
  config: WebResearchToolConfig;
}

export const WebResearchToolOutputTypeEnum = {
  WebResearchTool: "WebResearchTool",
} as const;

export type WebResearchToolOutputTypeEnum =
  (typeof WebResearchToolOutputTypeEnum)[keyof typeof WebResearchToolOutputTypeEnum];

/**
 * AgentsApi - axios parameter creator
 * @export
 */
export const AgentsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * List all agents.
     * @summary Get Agents
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAgentsV1AgentsGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/agents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all agents available agent types.
     * @summary Get Available Agents
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableAgentsV1AgentsTypesGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/agents/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Share the Agent with user or group.
     * @summary Share Agent
     * @param {string} agentId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareAgentV1AgentsAgentIdSharePost: async (
      agentId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'agentId' is not null or undefined
      assertParamExists(
        "shareAgentV1AgentsAgentIdSharePost",
        "agentId",
        agentId,
      );
      // verify required parameter 'shareRequest' is not null or undefined
      assertParamExists(
        "shareAgentV1AgentsAgentIdSharePost",
        "shareRequest",
        shareRequest,
      );
      const localVarPath = `/v1/agents/{agent_id}/share`.replace(
        `{${"agent_id"}}`,
        encodeURIComponent(String(agentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shareRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upsert agent.
     * @summary Upsert Agent
     * @param {Agent} agent
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertAgentV1AgentsPost: async (
      agent: Agent,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'agent' is not null or undefined
      assertParamExists("upsertAgentV1AgentsPost", "agent", agent);
      const localVarPath = `/v1/agents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        agent,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AgentsApi - functional programming interface
 * @export
 */
export const AgentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AgentsApiAxiosParamCreator(configuration);
  return {
    /**
     * List all agents.
     * @summary Get Agents
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAgentsV1AgentsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAgentsV1AgentsGet(token, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AgentsApi.getAgentsV1AgentsGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all agents available agent types.
     * @summary Get Available Agents
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableAgentsV1AgentsTypesGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAvailableAgentsV1AgentsTypesGet(
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AgentsApi.getAvailableAgentsV1AgentsTypesGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Share the Agent with user or group.
     * @summary Share Agent
     * @param {string} agentId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shareAgentV1AgentsAgentIdSharePost(
      agentId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shareAgentV1AgentsAgentIdSharePost(
          agentId,
          shareRequest,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AgentsApi.shareAgentV1AgentsAgentIdSharePost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Upsert agent.
     * @summary Upsert Agent
     * @param {Agent} agent
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertAgentV1AgentsPost(
      agent: Agent,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseUpsertAgentV1AgentsPost>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.upsertAgentV1AgentsPost(
          agent,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AgentsApi.upsertAgentV1AgentsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AgentsApi - factory interface
 * @export
 */
export const AgentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AgentsApiFp(configuration);
  return {
    /**
     * List all agents.
     * @summary Get Agents
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAgentsV1AgentsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getAgentsV1AgentsGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List all agents available agent types.
     * @summary Get Available Agents
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableAgentsV1AgentsTypesGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getAvailableAgentsV1AgentsTypesGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Share the Agent with user or group.
     * @summary Share Agent
     * @param {string} agentId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareAgentV1AgentsAgentIdSharePost(
      agentId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .shareAgentV1AgentsAgentIdSharePost(
          agentId,
          shareRequest,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Upsert agent.
     * @summary Upsert Agent
     * @param {Agent} agent
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertAgentV1AgentsPost(
      agent: Agent,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ResponseUpsertAgentV1AgentsPost> {
      return localVarFp
        .upsertAgentV1AgentsPost(agent, token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AgentsApi - object-oriented interface
 * @export
 * @class AgentsApi
 * @extends {BaseAPI}
 */
export class AgentsApi extends BaseAPI {
  /**
   * List all agents.
   * @summary Get Agents
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentsApi
   */
  public getAgentsV1AgentsGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AgentsApiFp(this.configuration)
      .getAgentsV1AgentsGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List all agents available agent types.
   * @summary Get Available Agents
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentsApi
   */
  public getAvailableAgentsV1AgentsTypesGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AgentsApiFp(this.configuration)
      .getAvailableAgentsV1AgentsTypesGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Share the Agent with user or group.
   * @summary Share Agent
   * @param {string} agentId
   * @param {ShareRequest} shareRequest
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentsApi
   */
  public shareAgentV1AgentsAgentIdSharePost(
    agentId: string,
    shareRequest: ShareRequest,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AgentsApiFp(this.configuration)
      .shareAgentV1AgentsAgentIdSharePost(agentId, shareRequest, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Upsert agent.
   * @summary Upsert Agent
   * @param {Agent} agent
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentsApi
   */
  public upsertAgentV1AgentsPost(
    agent: Agent,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AgentsApiFp(this.configuration)
      .upsertAgentV1AgentsPost(agent, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AiSystemsApi - axios parameter creator
 * @export
 */
export const AiSystemsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get AI system by id, including nested objects.
     * @summary Get Ai System Complete
     * @param {string} aiSystemId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet: async (
      aiSystemId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'aiSystemId' is not null or undefined
      assertParamExists(
        "getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet",
        "aiSystemId",
        aiSystemId,
      );
      const localVarPath = `/v1/ai_systems/{ai_system_id}/complete`.replace(
        `{${"ai_system_id"}}`,
        encodeURIComponent(String(aiSystemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get AI system by id.
     * @summary Get Ai System
     * @param {string} aiSystemId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiSystemV1AiSystemsAiSystemIdGet: async (
      aiSystemId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'aiSystemId' is not null or undefined
      assertParamExists(
        "getAiSystemV1AiSystemsAiSystemIdGet",
        "aiSystemId",
        aiSystemId,
      );
      const localVarPath = `/v1/ai_systems/{ai_system_id}`.replace(
        `{${"ai_system_id"}}`,
        encodeURIComponent(String(aiSystemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get AI system by id.
     * @summary Get Ai Systems
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiSystemsV1AiSystemsGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/ai_systems`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Share the AI System with user or group.
     * @summary Share Ai System
     * @param {string} aiSystemId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareAiSystemV1AiSystemsAiSystemIdSharePost: async (
      aiSystemId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'aiSystemId' is not null or undefined
      assertParamExists(
        "shareAiSystemV1AiSystemsAiSystemIdSharePost",
        "aiSystemId",
        aiSystemId,
      );
      // verify required parameter 'shareRequest' is not null or undefined
      assertParamExists(
        "shareAiSystemV1AiSystemsAiSystemIdSharePost",
        "shareRequest",
        shareRequest,
      );
      const localVarPath = `/v1/ai_systems/{ai_system_id}/share`.replace(
        `{${"ai_system_id"}}`,
        encodeURIComponent(String(aiSystemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shareRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upsert AI system.
     * @summary Upsert Ai System
     * @param {CompleteAISystemSchemaInput} completeAISystemSchemaInput
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertAiSystemV1AiSystemsPost: async (
      completeAISystemSchemaInput: CompleteAISystemSchemaInput,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'completeAISystemSchemaInput' is not null or undefined
      assertParamExists(
        "upsertAiSystemV1AiSystemsPost",
        "completeAISystemSchemaInput",
        completeAISystemSchemaInput,
      );
      const localVarPath = `/v1/ai_systems`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        completeAISystemSchemaInput,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AiSystemsApi - functional programming interface
 * @export
 */
export const AiSystemsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AiSystemsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get AI system by id, including nested objects.
     * @summary Get Ai System Complete
     * @param {string} aiSystemId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(
      aiSystemId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompleteAISystemSchemaOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(
          aiSystemId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AiSystemsApi.getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get AI system by id.
     * @summary Get Ai System
     * @param {string} aiSystemId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAiSystemV1AiSystemsAiSystemIdGet(
      aiSystemId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AISystem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAiSystemV1AiSystemsAiSystemIdGet(
          aiSystemId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AiSystemsApi.getAiSystemV1AiSystemsAiSystemIdGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get AI system by id.
     * @summary Get Ai Systems
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAiSystemsV1AiSystemsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AISystem>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAiSystemsV1AiSystemsGet(
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AiSystemsApi.getAiSystemsV1AiSystemsGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Share the AI System with user or group.
     * @summary Share Ai System
     * @param {string} aiSystemId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shareAiSystemV1AiSystemsAiSystemIdSharePost(
      aiSystemId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shareAiSystemV1AiSystemsAiSystemIdSharePost(
          aiSystemId,
          shareRequest,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AiSystemsApi.shareAiSystemV1AiSystemsAiSystemIdSharePost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Upsert AI system.
     * @summary Upsert Ai System
     * @param {CompleteAISystemSchemaInput} completeAISystemSchemaInput
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertAiSystemV1AiSystemsPost(
      completeAISystemSchemaInput: CompleteAISystemSchemaInput,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompleteAISystemSchemaOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.upsertAiSystemV1AiSystemsPost(
          completeAISystemSchemaInput,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AiSystemsApi.upsertAiSystemV1AiSystemsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AiSystemsApi - factory interface
 * @export
 */
export const AiSystemsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AiSystemsApiFp(configuration);
  return {
    /**
     * Get AI system by id, including nested objects.
     * @summary Get Ai System Complete
     * @param {string} aiSystemId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(
      aiSystemId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CompleteAISystemSchemaOutput> {
      return localVarFp
        .getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(
          aiSystemId,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get AI system by id.
     * @summary Get Ai System
     * @param {string} aiSystemId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiSystemV1AiSystemsAiSystemIdGet(
      aiSystemId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AISystem> {
      return localVarFp
        .getAiSystemV1AiSystemsAiSystemIdGet(aiSystemId, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get AI system by id.
     * @summary Get Ai Systems
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiSystemsV1AiSystemsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<AISystem>> {
      return localVarFp
        .getAiSystemsV1AiSystemsGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Share the AI System with user or group.
     * @summary Share Ai System
     * @param {string} aiSystemId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareAiSystemV1AiSystemsAiSystemIdSharePost(
      aiSystemId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .shareAiSystemV1AiSystemsAiSystemIdSharePost(
          aiSystemId,
          shareRequest,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Upsert AI system.
     * @summary Upsert Ai System
     * @param {CompleteAISystemSchemaInput} completeAISystemSchemaInput
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertAiSystemV1AiSystemsPost(
      completeAISystemSchemaInput: CompleteAISystemSchemaInput,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CompleteAISystemSchemaOutput> {
      return localVarFp
        .upsertAiSystemV1AiSystemsPost(
          completeAISystemSchemaInput,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AiSystemsApi - object-oriented interface
 * @export
 * @class AiSystemsApi
 * @extends {BaseAPI}
 */
export class AiSystemsApi extends BaseAPI {
  /**
   * Get AI system by id, including nested objects.
   * @summary Get Ai System Complete
   * @param {string} aiSystemId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiSystemsApi
   */
  public getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(
    aiSystemId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiSystemsApiFp(this.configuration)
      .getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(
        aiSystemId,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get AI system by id.
   * @summary Get Ai System
   * @param {string} aiSystemId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiSystemsApi
   */
  public getAiSystemV1AiSystemsAiSystemIdGet(
    aiSystemId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiSystemsApiFp(this.configuration)
      .getAiSystemV1AiSystemsAiSystemIdGet(aiSystemId, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get AI system by id.
   * @summary Get Ai Systems
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiSystemsApi
   */
  public getAiSystemsV1AiSystemsGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiSystemsApiFp(this.configuration)
      .getAiSystemsV1AiSystemsGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Share the AI System with user or group.
   * @summary Share Ai System
   * @param {string} aiSystemId
   * @param {ShareRequest} shareRequest
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiSystemsApi
   */
  public shareAiSystemV1AiSystemsAiSystemIdSharePost(
    aiSystemId: string,
    shareRequest: ShareRequest,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiSystemsApiFp(this.configuration)
      .shareAiSystemV1AiSystemsAiSystemIdSharePost(
        aiSystemId,
        shareRequest,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Upsert AI system.
   * @summary Upsert Ai System
   * @param {CompleteAISystemSchemaInput} completeAISystemSchemaInput
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiSystemsApi
   */
  public upsertAiSystemV1AiSystemsPost(
    completeAISystemSchemaInput: CompleteAISystemSchemaInput,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiSystemsApiFp(this.configuration)
      .upsertAiSystemV1AiSystemsPost(
        completeAISystemSchemaInput,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AiTasksApi - axios parameter creator
 * @export
 */
export const AiTasksApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Cancel Task
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTaskV1AiTasksTaskIdCancelPost: async (
      taskId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists(
        "cancelTaskV1AiTasksTaskIdCancelPost",
        "taskId",
        taskId,
      );
      const localVarPath = `/v1/ai_tasks/{task_id}/cancel`.replace(
        `{${"task_id"}}`,
        encodeURIComponent(String(taskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create AI task.
     * @summary Create Ai Task
     * @param {BodyCreateAiTaskV1AiTasksPost} bodyCreateAiTaskV1AiTasksPost
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAiTaskV1AiTasksPost: async (
      bodyCreateAiTaskV1AiTasksPost: BodyCreateAiTaskV1AiTasksPost,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyCreateAiTaskV1AiTasksPost' is not null or undefined
      assertParamExists(
        "createAiTaskV1AiTasksPost",
        "bodyCreateAiTaskV1AiTasksPost",
        bodyCreateAiTaskV1AiTasksPost,
      );
      const localVarPath = `/v1/ai_tasks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyCreateAiTaskV1AiTasksPost,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete AI task by id.
     * @summary Delete Ai Task
     * @param {string} aiTaskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAiTaskV1AiTasksAiTaskIdDelete: async (
      aiTaskId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'aiTaskId' is not null or undefined
      assertParamExists(
        "deleteAiTaskV1AiTasksAiTaskIdDelete",
        "aiTaskId",
        aiTaskId,
      );
      const localVarPath = `/v1/ai_tasks/{ai_task_id}`.replace(
        `{${"ai_task_id"}}`,
        encodeURIComponent(String(aiTaskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get AI task by id.
     * @summary Get Ai Task
     * @param {string} aiTaskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiTaskV1AiTasksAiTaskIdGet: async (
      aiTaskId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'aiTaskId' is not null or undefined
      assertParamExists("getAiTaskV1AiTasksAiTaskIdGet", "aiTaskId", aiTaskId);
      const localVarPath = `/v1/ai_tasks/{ai_task_id}`.replace(
        `{${"ai_task_id"}}`,
        encodeURIComponent(String(aiTaskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get AI task by id.
     * @summary Get Ai Tasks
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiTasksV1AiTasksGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/ai_tasks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Handle a request.
     * @summary Invoke
     * @param {string} taskId
     * @param {ChatPromptValueConcreteWithVars} chatPromptValueConcreteWithVars
     * @param {boolean} [wait]
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invokeV1AiTasksTaskIdInvokePost: async (
      taskId: string,
      chatPromptValueConcreteWithVars: ChatPromptValueConcreteWithVars,
      wait?: boolean,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists("invokeV1AiTasksTaskIdInvokePost", "taskId", taskId);
      // verify required parameter 'chatPromptValueConcreteWithVars' is not null or undefined
      assertParamExists(
        "invokeV1AiTasksTaskIdInvokePost",
        "chatPromptValueConcreteWithVars",
        chatPromptValueConcreteWithVars,
      );
      const localVarPath = `/v1/ai_tasks/{task_id}/invoke`.replace(
        `{${"task_id"}}`,
        encodeURIComponent(String(taskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (wait !== undefined) {
        localVarQueryParameter["wait"] = wait;
      }

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        chatPromptValueConcreteWithVars,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Share the Task with user or group.
     * @summary Share Task
     * @param {string} taskId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareTaskV1AiTasksTaskIdSharePost: async (
      taskId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists("shareTaskV1AiTasksTaskIdSharePost", "taskId", taskId);
      // verify required parameter 'shareRequest' is not null or undefined
      assertParamExists(
        "shareTaskV1AiTasksTaskIdSharePost",
        "shareRequest",
        shareRequest,
      );
      const localVarPath = `/v1/ai_tasks/{task_id}/share`.replace(
        `{${"task_id"}}`,
        encodeURIComponent(String(taskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shareRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Show Graph
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showGraphV1AiTasksTaskIdGraphImagePost: async (
      taskId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists(
        "showGraphV1AiTasksTaskIdGraphImagePost",
        "taskId",
        taskId,
      );
      const localVarPath = `/v1/ai_tasks/{task_id}/graph_image`.replace(
        `{${"task_id"}}`,
        encodeURIComponent(String(taskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stream Task Output
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    streamTaskOutputV1AiTasksTaskIdStreamGet: async (
      taskId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists(
        "streamTaskOutputV1AiTasksTaskIdStreamGet",
        "taskId",
        taskId,
      );
      const localVarPath = `/v1/ai_tasks/{task_id}/stream`.replace(
        `{${"task_id"}}`,
        encodeURIComponent(String(taskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stream Task Usage
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    streamTaskUsageV1AiTasksTaskIdStreamUsageGet: async (
      taskId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists(
        "streamTaskUsageV1AiTasksTaskIdStreamUsageGet",
        "taskId",
        taskId,
      );
      const localVarPath = `/v1/ai_tasks/{task_id}/stream_usage`.replace(
        `{${"task_id"}}`,
        encodeURIComponent(String(taskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AiTasksApi - functional programming interface
 * @export
 */
export const AiTasksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AiTasksApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Cancel Task
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelTaskV1AiTasksTaskIdCancelPost(
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelTaskV1AiTasksTaskIdCancelPost(
          taskId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AiTasksApi.cancelTaskV1AiTasksTaskIdCancelPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create AI task.
     * @summary Create Ai Task
     * @param {BodyCreateAiTaskV1AiTasksPost} bodyCreateAiTaskV1AiTasksPost
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAiTaskV1AiTasksPost(
      bodyCreateAiTaskV1AiTasksPost: BodyCreateAiTaskV1AiTasksPost,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createAiTaskV1AiTasksPost(
          bodyCreateAiTaskV1AiTasksPost,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AiTasksApi.createAiTaskV1AiTasksPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete AI task by id.
     * @summary Delete Ai Task
     * @param {string} aiTaskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAiTaskV1AiTasksAiTaskIdDelete(
      aiTaskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAiTaskV1AiTasksAiTaskIdDelete(
          aiTaskId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AiTasksApi.deleteAiTaskV1AiTasksAiTaskIdDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get AI task by id.
     * @summary Get Ai Task
     * @param {string} aiTaskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAiTaskV1AiTasksAiTaskIdGet(
      aiTaskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAiTaskV1AiTasksAiTaskIdGet(
          aiTaskId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AiTasksApi.getAiTaskV1AiTasksAiTaskIdGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get AI task by id.
     * @summary Get Ai Tasks
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAiTasksV1AiTasksGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAiTasksV1AiTasksGet(token, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AiTasksApi.getAiTasksV1AiTasksGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Handle a request.
     * @summary Invoke
     * @param {string} taskId
     * @param {ChatPromptValueConcreteWithVars} chatPromptValueConcreteWithVars
     * @param {boolean} [wait]
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async invokeV1AiTasksTaskIdInvokePost(
      taskId: string,
      chatPromptValueConcreteWithVars: ChatPromptValueConcreteWithVars,
      wait?: boolean,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.invokeV1AiTasksTaskIdInvokePost(
          taskId,
          chatPromptValueConcreteWithVars,
          wait,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AiTasksApi.invokeV1AiTasksTaskIdInvokePost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Share the Task with user or group.
     * @summary Share Task
     * @param {string} taskId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shareTaskV1AiTasksTaskIdSharePost(
      taskId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shareTaskV1AiTasksTaskIdSharePost(
          taskId,
          shareRequest,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AiTasksApi.shareTaskV1AiTasksTaskIdSharePost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Show Graph
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showGraphV1AiTasksTaskIdGraphImagePost(
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showGraphV1AiTasksTaskIdGraphImagePost(
          taskId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AiTasksApi.showGraphV1AiTasksTaskIdGraphImagePost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Stream Task Output
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async streamTaskOutputV1AiTasksTaskIdStreamGet(
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.streamTaskOutputV1AiTasksTaskIdStreamGet(
          taskId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AiTasksApi.streamTaskOutputV1AiTasksTaskIdStreamGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Stream Task Usage
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async streamTaskUsageV1AiTasksTaskIdStreamUsageGet(
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.streamTaskUsageV1AiTasksTaskIdStreamUsageGet(
          taskId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AiTasksApi.streamTaskUsageV1AiTasksTaskIdStreamUsageGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AiTasksApi - factory interface
 * @export
 */
export const AiTasksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AiTasksApiFp(configuration);
  return {
    /**
     *
     * @summary Cancel Task
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTaskV1AiTasksTaskIdCancelPost(
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .cancelTaskV1AiTasksTaskIdCancelPost(taskId, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create AI task.
     * @summary Create Ai Task
     * @param {BodyCreateAiTaskV1AiTasksPost} bodyCreateAiTaskV1AiTasksPost
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAiTaskV1AiTasksPost(
      bodyCreateAiTaskV1AiTasksPost: BodyCreateAiTaskV1AiTasksPost,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .createAiTaskV1AiTasksPost(
          bodyCreateAiTaskV1AiTasksPost,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete AI task by id.
     * @summary Delete Ai Task
     * @param {string} aiTaskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAiTaskV1AiTasksAiTaskIdDelete(
      aiTaskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deleteAiTaskV1AiTasksAiTaskIdDelete(aiTaskId, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get AI task by id.
     * @summary Get Ai Task
     * @param {string} aiTaskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiTaskV1AiTasksAiTaskIdGet(
      aiTaskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getAiTaskV1AiTasksAiTaskIdGet(aiTaskId, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get AI task by id.
     * @summary Get Ai Tasks
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAiTasksV1AiTasksGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getAiTasksV1AiTasksGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Handle a request.
     * @summary Invoke
     * @param {string} taskId
     * @param {ChatPromptValueConcreteWithVars} chatPromptValueConcreteWithVars
     * @param {boolean} [wait]
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invokeV1AiTasksTaskIdInvokePost(
      taskId: string,
      chatPromptValueConcreteWithVars: ChatPromptValueConcreteWithVars,
      wait?: boolean,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .invokeV1AiTasksTaskIdInvokePost(
          taskId,
          chatPromptValueConcreteWithVars,
          wait,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Share the Task with user or group.
     * @summary Share Task
     * @param {string} taskId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareTaskV1AiTasksTaskIdSharePost(
      taskId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .shareTaskV1AiTasksTaskIdSharePost(taskId, shareRequest, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Show Graph
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showGraphV1AiTasksTaskIdGraphImagePost(
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .showGraphV1AiTasksTaskIdGraphImagePost(taskId, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Stream Task Output
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    streamTaskOutputV1AiTasksTaskIdStreamGet(
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .streamTaskOutputV1AiTasksTaskIdStreamGet(taskId, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Stream Task Usage
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    streamTaskUsageV1AiTasksTaskIdStreamUsageGet(
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .streamTaskUsageV1AiTasksTaskIdStreamUsageGet(taskId, token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AiTasksApi - object-oriented interface
 * @export
 * @class AiTasksApi
 * @extends {BaseAPI}
 */
export class AiTasksApi extends BaseAPI {
  /**
   *
   * @summary Cancel Task
   * @param {string} taskId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public cancelTaskV1AiTasksTaskIdCancelPost(
    taskId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .cancelTaskV1AiTasksTaskIdCancelPost(taskId, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create AI task.
   * @summary Create Ai Task
   * @param {BodyCreateAiTaskV1AiTasksPost} bodyCreateAiTaskV1AiTasksPost
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public createAiTaskV1AiTasksPost(
    bodyCreateAiTaskV1AiTasksPost: BodyCreateAiTaskV1AiTasksPost,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .createAiTaskV1AiTasksPost(bodyCreateAiTaskV1AiTasksPost, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete AI task by id.
   * @summary Delete Ai Task
   * @param {string} aiTaskId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public deleteAiTaskV1AiTasksAiTaskIdDelete(
    aiTaskId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .deleteAiTaskV1AiTasksAiTaskIdDelete(aiTaskId, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get AI task by id.
   * @summary Get Ai Task
   * @param {string} aiTaskId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public getAiTaskV1AiTasksAiTaskIdGet(
    aiTaskId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .getAiTaskV1AiTasksAiTaskIdGet(aiTaskId, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get AI task by id.
   * @summary Get Ai Tasks
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public getAiTasksV1AiTasksGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .getAiTasksV1AiTasksGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Handle a request.
   * @summary Invoke
   * @param {string} taskId
   * @param {ChatPromptValueConcreteWithVars} chatPromptValueConcreteWithVars
   * @param {boolean} [wait]
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public invokeV1AiTasksTaskIdInvokePost(
    taskId: string,
    chatPromptValueConcreteWithVars: ChatPromptValueConcreteWithVars,
    wait?: boolean,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .invokeV1AiTasksTaskIdInvokePost(
        taskId,
        chatPromptValueConcreteWithVars,
        wait,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Share the Task with user or group.
   * @summary Share Task
   * @param {string} taskId
   * @param {ShareRequest} shareRequest
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public shareTaskV1AiTasksTaskIdSharePost(
    taskId: string,
    shareRequest: ShareRequest,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .shareTaskV1AiTasksTaskIdSharePost(taskId, shareRequest, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Show Graph
   * @param {string} taskId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public showGraphV1AiTasksTaskIdGraphImagePost(
    taskId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .showGraphV1AiTasksTaskIdGraphImagePost(taskId, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stream Task Output
   * @param {string} taskId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public streamTaskOutputV1AiTasksTaskIdStreamGet(
    taskId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .streamTaskOutputV1AiTasksTaskIdStreamGet(taskId, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stream Task Usage
   * @param {string} taskId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiTasksApi
   */
  public streamTaskUsageV1AiTasksTaskIdStreamUsageGet(
    taskId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return AiTasksApiFp(this.configuration)
      .streamTaskUsageV1AiTasksTaskIdStreamUsageGet(taskId, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Account
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountV1BillingAccountGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/billing/account`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get aggregated transactions for a specific user, grouped by hour or day, within a time limit.
     * @summary Get Aggregated Transactions
     * @param {string} [groupBy] Group by either \&#39;hour\&#39; or \&#39;day\&#39;
     * @param {number} [timeLimit] Time limit in days
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAggregatedTransactionsV1BillingTransactionsAggregatedGet: async (
      groupBy?: string,
      timeLimit?: number,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/billing/transactions/aggregated`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (groupBy !== undefined) {
        localVarQueryParameter["group_by"] = groupBy;
      }

      if (timeLimit !== undefined) {
        localVarQueryParameter["time_limit"] = timeLimit;
      }

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Transactions
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionsV1BillingTransactionsGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/billing/transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Account
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountV1BillingAccountGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAccountV1BillingAccountGet(
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["BillingApi.getAccountV1BillingAccountGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get aggregated transactions for a specific user, grouped by hour or day, within a time limit.
     * @summary Get Aggregated Transactions
     * @param {string} [groupBy] Group by either \&#39;hour\&#39; or \&#39;day\&#39;
     * @param {number} [timeLimit] Time limit in days
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAggregatedTransactionsV1BillingTransactionsAggregatedGet(
      groupBy?: string,
      timeLimit?: number,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AggregatedTransactionModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAggregatedTransactionsV1BillingTransactionsAggregatedGet(
          groupBy,
          timeLimit,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "BillingApi.getAggregatedTransactionsV1BillingTransactionsAggregatedGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Transactions
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionsV1BillingTransactionsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CreditTransactionModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionsV1BillingTransactionsGet(
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "BillingApi.getTransactionsV1BillingTransactionsGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BillingApiFp(configuration);
  return {
    /**
     *
     * @summary Get Account
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountV1BillingAccountGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AccountModel> {
      return localVarFp
        .getAccountV1BillingAccountGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get aggregated transactions for a specific user, grouped by hour or day, within a time limit.
     * @summary Get Aggregated Transactions
     * @param {string} [groupBy] Group by either \&#39;hour\&#39; or \&#39;day\&#39;
     * @param {number} [timeLimit] Time limit in days
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAggregatedTransactionsV1BillingTransactionsAggregatedGet(
      groupBy?: string,
      timeLimit?: number,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<AggregatedTransactionModel>> {
      return localVarFp
        .getAggregatedTransactionsV1BillingTransactionsAggregatedGet(
          groupBy,
          timeLimit,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Transactions
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionsV1BillingTransactionsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<CreditTransactionModel>> {
      return localVarFp
        .getTransactionsV1BillingTransactionsGet(token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
  /**
   *
   * @summary Get Account
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public getAccountV1BillingAccountGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .getAccountV1BillingAccountGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get aggregated transactions for a specific user, grouped by hour or day, within a time limit.
   * @summary Get Aggregated Transactions
   * @param {string} [groupBy] Group by either \&#39;hour\&#39; or \&#39;day\&#39;
   * @param {number} [timeLimit] Time limit in days
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public getAggregatedTransactionsV1BillingTransactionsAggregatedGet(
    groupBy?: string,
    timeLimit?: number,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .getAggregatedTransactionsV1BillingTransactionsAggregatedGet(
        groupBy,
        timeLimit,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Transactions
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public getTransactionsV1BillingTransactionsGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .getTransactionsV1BillingTransactionsGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ConnectionsApi - axios parameter creator
 * @export
 */
export const ConnectionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Download Directory
     * @param {string} connectionId
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet: async (
      connectionId: string,
      taskId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists(
        "downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet",
        "connectionId",
        connectionId,
      );
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists(
        "downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet",
        "taskId",
        taskId,
      );
      const localVarPath =
        `/v1/connections/storage/{connection_id}/{task_id}/download`
          .replace(
            `{${"connection_id"}}`,
            encodeURIComponent(String(connectionId)),
          )
          .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all available connector types.
     * @summary Get Available Connectors
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableConnectorsV1ConnectionsTypesGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/connections/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all connections.
     * @summary Get Connections
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectionsV1ConnectionsGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/connections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List contents of a storage connection.
     * @summary List Contents
     * @param {string} connectionId
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listContentsV1ConnectionsStorageConnectionIdTaskIdGet: async (
      connectionId: string,
      taskId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists(
        "listContentsV1ConnectionsStorageConnectionIdTaskIdGet",
        "connectionId",
        connectionId,
      );
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists(
        "listContentsV1ConnectionsStorageConnectionIdTaskIdGet",
        "taskId",
        taskId,
      );
      const localVarPath = `/v1/connections/storage/{connection_id}/{task_id}`
        .replace(
          `{${"connection_id"}}`,
          encodeURIComponent(String(connectionId)),
        )
        .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Share the Connection with user or group.
     * @summary Share Connection
     * @param {string} connectionId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareConnectionV1ConnectionsConnectionIdSharePost: async (
      connectionId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists(
        "shareConnectionV1ConnectionsConnectionIdSharePost",
        "connectionId",
        connectionId,
      );
      // verify required parameter 'shareRequest' is not null or undefined
      assertParamExists(
        "shareConnectionV1ConnectionsConnectionIdSharePost",
        "shareRequest",
        shareRequest,
      );
      const localVarPath = `/v1/connections/{connection_id}/share`.replace(
        `{${"connection_id"}}`,
        encodeURIComponent(String(connectionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shareRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upload a file to a storage connection.
     * @summary Upload File
     * @param {string} connectionId
     * @param {string} taskId
     * @param {File} file
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost: async (
      connectionId: string,
      taskId: string,
      file: File,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists(
        "uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost",
        "connectionId",
        connectionId,
      );
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists(
        "uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost",
        "taskId",
        taskId,
      );
      // verify required parameter 'file' is not null or undefined
      assertParamExists(
        "uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost",
        "file",
        file,
      );
      const localVarPath =
        `/v1/connections/storage/{connection_id}/{task_id}/upload`
          .replace(
            `{${"connection_id"}}`,
            encodeURIComponent(String(connectionId)),
          )
          .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List contents of a storage connection.
     * @summary Upload Zip
     * @param {string} connectionId
     * @param {string} taskId
     * @param {File} file
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost: async (
      connectionId: string,
      taskId: string,
      file: File,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists(
        "uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost",
        "connectionId",
        connectionId,
      );
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists(
        "uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost",
        "taskId",
        taskId,
      );
      // verify required parameter 'file' is not null or undefined
      assertParamExists(
        "uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost",
        "file",
        file,
      );
      const localVarPath =
        `/v1/connections/storage/{connection_id}/{task_id}/upload_zip`
          .replace(
            `{${"connection_id"}}`,
            encodeURIComponent(String(connectionId)),
          )
          .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upsert connection.
     * @summary Upsert Connection
     * @param {Connection} connection
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertConnectionV1ConnectionsPost: async (
      connection: Connection,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connection' is not null or undefined
      assertParamExists(
        "upsertConnectionV1ConnectionsPost",
        "connection",
        connection,
      );
      const localVarPath = `/v1/connections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        connection,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ConnectionsApi - functional programming interface
 * @export
 */
export const ConnectionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ConnectionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Download Directory
     * @param {string} connectionId
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet(
      connectionId: string,
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet(
          connectionId,
          taskId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ConnectionsApi.downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all available connector types.
     * @summary Get Available Connectors
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableConnectorsV1ConnectionsTypesGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAvailableConnectorsV1ConnectionsTypesGet(
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ConnectionsApi.getAvailableConnectorsV1ConnectionsTypesGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all connections.
     * @summary Get Connections
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConnectionsV1ConnectionsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getConnectionsV1ConnectionsGet(
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ConnectionsApi.getConnectionsV1ConnectionsGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List contents of a storage connection.
     * @summary List Contents
     * @param {string} connectionId
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listContentsV1ConnectionsStorageConnectionIdTaskIdGet(
      connectionId: string,
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<StorageConnectorFile>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listContentsV1ConnectionsStorageConnectionIdTaskIdGet(
          connectionId,
          taskId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ConnectionsApi.listContentsV1ConnectionsStorageConnectionIdTaskIdGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Share the Connection with user or group.
     * @summary Share Connection
     * @param {string} connectionId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shareConnectionV1ConnectionsConnectionIdSharePost(
      connectionId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shareConnectionV1ConnectionsConnectionIdSharePost(
          connectionId,
          shareRequest,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ConnectionsApi.shareConnectionV1ConnectionsConnectionIdSharePost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Upload a file to a storage connection.
     * @summary Upload File
     * @param {string} connectionId
     * @param {string} taskId
     * @param {File} file
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost(
      connectionId: string,
      taskId: string,
      file: File,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost(
          connectionId,
          taskId,
          file,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ConnectionsApi.uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List contents of a storage connection.
     * @summary Upload Zip
     * @param {string} connectionId
     * @param {string} taskId
     * @param {File} file
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost(
      connectionId: string,
      taskId: string,
      file: File,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost(
          connectionId,
          taskId,
          file,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ConnectionsApi.uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Upsert connection.
     * @summary Upsert Connection
     * @param {Connection} connection
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertConnectionV1ConnectionsPost(
      connection: Connection,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseUpsertConnectionV1ConnectionsPost>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.upsertConnectionV1ConnectionsPost(
          connection,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ConnectionsApi.upsertConnectionV1ConnectionsPost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ConnectionsApi - factory interface
 * @export
 */
export const ConnectionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ConnectionsApiFp(configuration);
  return {
    /**
     *
     * @summary Download Directory
     * @param {string} connectionId
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet(
      connectionId: string,
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet(
          connectionId,
          taskId,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * List all available connector types.
     * @summary Get Available Connectors
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableConnectorsV1ConnectionsTypesGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getAvailableConnectorsV1ConnectionsTypesGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List all connections.
     * @summary Get Connections
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectionsV1ConnectionsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getConnectionsV1ConnectionsGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List contents of a storage connection.
     * @summary List Contents
     * @param {string} connectionId
     * @param {string} taskId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listContentsV1ConnectionsStorageConnectionIdTaskIdGet(
      connectionId: string,
      taskId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<StorageConnectorFile>> {
      return localVarFp
        .listContentsV1ConnectionsStorageConnectionIdTaskIdGet(
          connectionId,
          taskId,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Share the Connection with user or group.
     * @summary Share Connection
     * @param {string} connectionId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareConnectionV1ConnectionsConnectionIdSharePost(
      connectionId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .shareConnectionV1ConnectionsConnectionIdSharePost(
          connectionId,
          shareRequest,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Upload a file to a storage connection.
     * @summary Upload File
     * @param {string} connectionId
     * @param {string} taskId
     * @param {File} file
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost(
      connectionId: string,
      taskId: string,
      file: File,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost(
          connectionId,
          taskId,
          file,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * List contents of a storage connection.
     * @summary Upload Zip
     * @param {string} connectionId
     * @param {string} taskId
     * @param {File} file
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost(
      connectionId: string,
      taskId: string,
      file: File,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost(
          connectionId,
          taskId,
          file,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Upsert connection.
     * @summary Upsert Connection
     * @param {Connection} connection
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertConnectionV1ConnectionsPost(
      connection: Connection,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ResponseUpsertConnectionV1ConnectionsPost> {
      return localVarFp
        .upsertConnectionV1ConnectionsPost(connection, token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ConnectionsApi - object-oriented interface
 * @export
 * @class ConnectionsApi
 * @extends {BaseAPI}
 */
export class ConnectionsApi extends BaseAPI {
  /**
   *
   * @summary Download Directory
   * @param {string} connectionId
   * @param {string} taskId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet(
    connectionId: string,
    taskId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ConnectionsApiFp(this.configuration)
      .downloadDirectoryV1ConnectionsStorageConnectionIdTaskIdDownloadGet(
        connectionId,
        taskId,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List all available connector types.
   * @summary Get Available Connectors
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public getAvailableConnectorsV1ConnectionsTypesGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ConnectionsApiFp(this.configuration)
      .getAvailableConnectorsV1ConnectionsTypesGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List all connections.
   * @summary Get Connections
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public getConnectionsV1ConnectionsGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ConnectionsApiFp(this.configuration)
      .getConnectionsV1ConnectionsGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List contents of a storage connection.
   * @summary List Contents
   * @param {string} connectionId
   * @param {string} taskId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public listContentsV1ConnectionsStorageConnectionIdTaskIdGet(
    connectionId: string,
    taskId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ConnectionsApiFp(this.configuration)
      .listContentsV1ConnectionsStorageConnectionIdTaskIdGet(
        connectionId,
        taskId,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Share the Connection with user or group.
   * @summary Share Connection
   * @param {string} connectionId
   * @param {ShareRequest} shareRequest
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public shareConnectionV1ConnectionsConnectionIdSharePost(
    connectionId: string,
    shareRequest: ShareRequest,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ConnectionsApiFp(this.configuration)
      .shareConnectionV1ConnectionsConnectionIdSharePost(
        connectionId,
        shareRequest,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Upload a file to a storage connection.
   * @summary Upload File
   * @param {string} connectionId
   * @param {string} taskId
   * @param {File} file
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost(
    connectionId: string,
    taskId: string,
    file: File,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ConnectionsApiFp(this.configuration)
      .uploadFileV1ConnectionsStorageConnectionIdTaskIdUploadPost(
        connectionId,
        taskId,
        file,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List contents of a storage connection.
   * @summary Upload Zip
   * @param {string} connectionId
   * @param {string} taskId
   * @param {File} file
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost(
    connectionId: string,
    taskId: string,
    file: File,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ConnectionsApiFp(this.configuration)
      .uploadZipV1ConnectionsStorageConnectionIdTaskIdUploadZipPost(
        connectionId,
        taskId,
        file,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Upsert connection.
   * @summary Upsert Connection
   * @param {Connection} connection
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public upsertConnectionV1ConnectionsPost(
    connection: Connection,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ConnectionsApiFp(this.configuration)
      .upsertConnectionV1ConnectionsPost(connection, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * List all groups.
     * @summary Get Groups
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupsV1GroupsGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration);
  return {
    /**
     * List all groups.
     * @summary Get Groups
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupsV1GroupsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGroupsV1GroupsGet(token, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["GroupsApi.getGroupsV1GroupsGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GroupsApiFp(configuration);
  return {
    /**
     * List all groups.
     * @summary Get Groups
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupsV1GroupsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getGroupsV1GroupsGet(token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
  /**
   * List all groups.
   * @summary Get Groups
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public getGroupsV1GroupsGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return GroupsApiFp(this.configuration)
      .getGroupsV1GroupsGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Healthz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthzHealthzGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/healthz`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Statusz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statuszStatuszGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/statusz`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Healthz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthzHealthzGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.healthzHealthzGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["HealthApi.healthzHealthzGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Statusz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statuszStatuszGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.statuszStatuszGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["HealthApi.statuszStatuszGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthApiFp(configuration);
  return {
    /**
     *
     * @summary Healthz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthzHealthzGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
      return localVarFp
        .healthzHealthzGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Statusz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statuszStatuszGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
      return localVarFp
        .statuszStatuszGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
  /**
   *
   * @summary Healthz
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthApi
   */
  public healthzHealthzGet(options?: RawAxiosRequestConfig) {
    return HealthApiFp(this.configuration)
      .healthzHealthzGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Statusz
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthApi
   */
  public statuszStatuszGet(options?: RawAxiosRequestConfig) {
    return HealthApiFp(this.configuration)
      .statuszStatuszGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HitlApi - axios parameter creator
 * @export
 */
export const HitlApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get all HITL requests for the user.
     * @summary Get Hitl Requests All
     * @param {string | null} [taskId]
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHitlRequestsAllV1HitlRequestsGet: async (
      taskId?: string | null,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/hitl/requests/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (taskId !== undefined) {
        localVarQueryParameter["task_id"] = taskId;
      }

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Hitl Respond
     * @param {string} hitlConnectionId
     * @param {string} requestId
     * @param {Response} response
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost: async (
      hitlConnectionId: string,
      requestId: string,
      response: Response,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hitlConnectionId' is not null or undefined
      assertParamExists(
        "hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost",
        "hitlConnectionId",
        hitlConnectionId,
      );
      // verify required parameter 'requestId' is not null or undefined
      assertParamExists(
        "hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost",
        "requestId",
        requestId,
      );
      // verify required parameter 'response' is not null or undefined
      assertParamExists(
        "hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost",
        "response",
        response,
      );
      const localVarPath = `/v1/hitl/requests/{hitl_connection_id}/{request_id}`
        .replace(
          `{${"hitl_connection_id"}}`,
          encodeURIComponent(String(hitlConnectionId)),
        )
        .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        response,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stream Hitl Requests All
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    streamHitlRequestsAllV1HitlRequestsStreamGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/hitl/requests/stream`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stream Hitl Requests
     * @param {string} hitlConnectionId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    streamHitlRequestsV1HitlRequestsHitlConnectionIdStreamGet: async (
      hitlConnectionId: string,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hitlConnectionId' is not null or undefined
      assertParamExists(
        "streamHitlRequestsV1HitlRequestsHitlConnectionIdStreamGet",
        "hitlConnectionId",
        hitlConnectionId,
      );
      const localVarPath =
        `/v1/hitl/requests/{hitl_connection_id}/stream`.replace(
          `{${"hitl_connection_id"}}`,
          encodeURIComponent(String(hitlConnectionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HitlApi - functional programming interface
 * @export
 */
export const HitlApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HitlApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all HITL requests for the user.
     * @summary Get Hitl Requests All
     * @param {string | null} [taskId]
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHitlRequestsAllV1HitlRequestsGet(
      taskId?: string | null,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getHitlRequestsAllV1HitlRequestsGet(
          taskId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["HitlApi.getHitlRequestsAllV1HitlRequestsGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Hitl Respond
     * @param {string} hitlConnectionId
     * @param {string} requestId
     * @param {Response} response
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost(
      hitlConnectionId: string,
      requestId: string,
      response: Response,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost(
          hitlConnectionId,
          requestId,
          response,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "HitlApi.hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Stream Hitl Requests All
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async streamHitlRequestsAllV1HitlRequestsStreamGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.streamHitlRequestsAllV1HitlRequestsStreamGet(
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "HitlApi.streamHitlRequestsAllV1HitlRequestsStreamGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Stream Hitl Requests
     * @param {string} hitlConnectionId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async streamHitlRequestsV1HitlRequestsHitlConnectionIdStreamGet(
      hitlConnectionId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HITLRequest>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.streamHitlRequestsV1HitlRequestsHitlConnectionIdStreamGet(
          hitlConnectionId,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "HitlApi.streamHitlRequestsV1HitlRequestsHitlConnectionIdStreamGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HitlApi - factory interface
 * @export
 */
export const HitlApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HitlApiFp(configuration);
  return {
    /**
     * Get all HITL requests for the user.
     * @summary Get Hitl Requests All
     * @param {string | null} [taskId]
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHitlRequestsAllV1HitlRequestsGet(
      taskId?: string | null,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getHitlRequestsAllV1HitlRequestsGet(taskId, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Hitl Respond
     * @param {string} hitlConnectionId
     * @param {string} requestId
     * @param {Response} response
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost(
      hitlConnectionId: string,
      requestId: string,
      response: Response,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost(
          hitlConnectionId,
          requestId,
          response,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Stream Hitl Requests All
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    streamHitlRequestsAllV1HitlRequestsStreamGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .streamHitlRequestsAllV1HitlRequestsStreamGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Stream Hitl Requests
     * @param {string} hitlConnectionId
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    streamHitlRequestsV1HitlRequestsHitlConnectionIdStreamGet(
      hitlConnectionId: string,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HITLRequest> {
      return localVarFp
        .streamHitlRequestsV1HitlRequestsHitlConnectionIdStreamGet(
          hitlConnectionId,
          token,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HitlApi - object-oriented interface
 * @export
 * @class HitlApi
 * @extends {BaseAPI}
 */
export class HitlApi extends BaseAPI {
  /**
   * Get all HITL requests for the user.
   * @summary Get Hitl Requests All
   * @param {string | null} [taskId]
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HitlApi
   */
  public getHitlRequestsAllV1HitlRequestsGet(
    taskId?: string | null,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return HitlApiFp(this.configuration)
      .getHitlRequestsAllV1HitlRequestsGet(taskId, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Hitl Respond
   * @param {string} hitlConnectionId
   * @param {string} requestId
   * @param {Response} response
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HitlApi
   */
  public hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost(
    hitlConnectionId: string,
    requestId: string,
    response: Response,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return HitlApiFp(this.configuration)
      .hitlRespondV1HitlRequestsHitlConnectionIdRequestIdPost(
        hitlConnectionId,
        requestId,
        response,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stream Hitl Requests All
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HitlApi
   */
  public streamHitlRequestsAllV1HitlRequestsStreamGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return HitlApiFp(this.configuration)
      .streamHitlRequestsAllV1HitlRequestsStreamGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stream Hitl Requests
   * @param {string} hitlConnectionId
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HitlApi
   */
  public streamHitlRequestsV1HitlRequestsHitlConnectionIdStreamGet(
    hitlConnectionId: string,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return HitlApiFp(this.configuration)
      .streamHitlRequestsV1HitlRequestsHitlConnectionIdStreamGet(
        hitlConnectionId,
        token,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ToolsApi - axios parameter creator
 * @export
 */
export const ToolsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * List all available tool types.
     * @summary Get Available Tools
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableToolsV1ToolsTypesGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/tools/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all tools.
     * @summary Get Tools
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToolsV1ToolsGet: async (
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/tools`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Share the Tool with user or group.
     * @summary Share Tool
     * @param {string} toolId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareToolV1ToolsToolIdSharePost: async (
      toolId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'toolId' is not null or undefined
      assertParamExists("shareToolV1ToolsToolIdSharePost", "toolId", toolId);
      // verify required parameter 'shareRequest' is not null or undefined
      assertParamExists(
        "shareToolV1ToolsToolIdSharePost",
        "shareRequest",
        shareRequest,
      );
      const localVarPath = `/v1/tools/{tool_id}/share`.replace(
        `{${"tool_id"}}`,
        encodeURIComponent(String(toolId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shareRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upsert tool.
     * @summary Upsert Tool
     * @param {Tool} tool
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertToolV1ToolsPost: async (
      tool: Tool,
      token?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tool' is not null or undefined
      assertParamExists("upsertToolV1ToolsPost", "tool", tool);
      const localVarPath = `/v1/tools`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tool,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ToolsApi - functional programming interface
 * @export
 */
export const ToolsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ToolsApiAxiosParamCreator(configuration);
  return {
    /**
     * List all available tool types.
     * @summary Get Available Tools
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableToolsV1ToolsTypesGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAvailableToolsV1ToolsTypesGet(
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ToolsApi.getAvailableToolsV1ToolsTypesGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all tools.
     * @summary Get Tools
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToolsV1ToolsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getToolsV1ToolsGet(token, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ToolsApi.getToolsV1ToolsGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Share the Tool with user or group.
     * @summary Share Tool
     * @param {string} toolId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shareToolV1ToolsToolIdSharePost(
      toolId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shareToolV1ToolsToolIdSharePost(
          toolId,
          shareRequest,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ToolsApi.shareToolV1ToolsToolIdSharePost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Upsert tool.
     * @summary Upsert Tool
     * @param {Tool} tool
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertToolV1ToolsPost(
      tool: Tool,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseUpsertToolV1ToolsPost>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.upsertToolV1ToolsPost(
          tool,
          token,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ToolsApi.upsertToolV1ToolsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ToolsApi - factory interface
 * @export
 */
export const ToolsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ToolsApiFp(configuration);
  return {
    /**
     * List all available tool types.
     * @summary Get Available Tools
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableToolsV1ToolsTypesGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getAvailableToolsV1ToolsTypesGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List all tools.
     * @summary Get Tools
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToolsV1ToolsGet(
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getToolsV1ToolsGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Share the Tool with user or group.
     * @summary Share Tool
     * @param {string} toolId
     * @param {ShareRequest} shareRequest
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareToolV1ToolsToolIdSharePost(
      toolId: string,
      shareRequest: ShareRequest,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .shareToolV1ToolsToolIdSharePost(toolId, shareRequest, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Upsert tool.
     * @summary Upsert Tool
     * @param {Tool} tool
     * @param {string | null} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertToolV1ToolsPost(
      tool: Tool,
      token?: string | null,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ResponseUpsertToolV1ToolsPost> {
      return localVarFp
        .upsertToolV1ToolsPost(tool, token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ToolsApi - object-oriented interface
 * @export
 * @class ToolsApi
 * @extends {BaseAPI}
 */
export class ToolsApi extends BaseAPI {
  /**
   * List all available tool types.
   * @summary Get Available Tools
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ToolsApi
   */
  public getAvailableToolsV1ToolsTypesGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ToolsApiFp(this.configuration)
      .getAvailableToolsV1ToolsTypesGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List all tools.
   * @summary Get Tools
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ToolsApi
   */
  public getToolsV1ToolsGet(
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ToolsApiFp(this.configuration)
      .getToolsV1ToolsGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Share the Tool with user or group.
   * @summary Share Tool
   * @param {string} toolId
   * @param {ShareRequest} shareRequest
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ToolsApi
   */
  public shareToolV1ToolsToolIdSharePost(
    toolId: string,
    shareRequest: ShareRequest,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ToolsApiFp(this.configuration)
      .shareToolV1ToolsToolIdSharePost(toolId, shareRequest, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Upsert tool.
   * @summary Upsert Tool
   * @param {Tool} tool
   * @param {string | null} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ToolsApi
   */
  public upsertToolV1ToolsPost(
    tool: Tool,
    token?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return ToolsApiFp(this.configuration)
      .upsertToolV1ToolsPost(tool, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

import React from "react";
import { Layout, App, Flex } from "antd";

import "./spotlight.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import { Typography } from "antd";

const { Content } = Layout;

const ChangelogComponent = () => {
  const navigate = useNavigate();

  const changelog = `
  
  # v0.0.2 | Initial Private Alpha Preview Release
  ## October 2024 
  **Key features:**
  - Account balance management
  - Configurable UI views - enables hiding advanced features for non-technical users
  - Performance improvements
  - Bug fixes
  - Added an option to delete AI tasks
  
  # v0.0.1 | Initial Private Alpha Preview Release
  ## August 2024 
  **Key features:**
  - AI Task Management
  - Distributed AI agent execution environment
  - Graph-based workflows for AI pipelines, including cyclical workflows
  - Human-in-the-loop - Enables AI agents to request human input for decision-making. UI generated on the fly based on the request. 
  - Configurable task input and output schemas
  - Dynamic task input UI generation based on the schema
  - Billing and usage tracking
  - Output streaming
  - Sandboxed execution environment for AI agents
  - Read/write cloud storage integration for AI agents
  - Access control
    - User management
    - Group management
    - Role-based access control
  - OCR service for image and PDF processing, including tabular data extraction
  - Web-based editor for AI system development
  ...
  
  `;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content
        style={{
          display: "flex",
          // flexDirection: "column",
          // justifyContent: "space-around",
          alignItems: "center",
          // minHeight: "50vh",
        }}
      >
        <Flex
          vertical
          // flexDirection="column"
          align="center"
          style={{
            paddingLeft: 100,
            width: "100%",
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          <div style={{ width: 600 }}>
            <Typography.Title>Changelog</Typography.Title>
            <Markdown>{changelog}</Markdown>
          </div>
        </Flex>
      </Content>
    </Layout>
  );
};

export default ChangelogComponent;

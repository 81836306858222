import React, { useState, useRef, useEffect, createRef } from "react";
import {
  Layout,
  Input,
  Select,
  App,
  Image,
  Button,
  Typography,
  Space,
  Spin,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import "./spotlight.css";
import TextArea from "antd/es/input/TextArea";
import { AiSystemsApi, AiTasksApi, Configuration } from "../../api_client";
import { apiBasePath } from "../../config";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import logo_white from "../../assets/logo_white.png";
import Form, { generateWidgets } from "@rjsf/antd";
import validator from "@rjsf/validator-ajv8";
import getLogo from "../misc/logo";
import { parseJwt } from "../../utils/jwt";
const formRef = createRef();

const { Content } = Layout;

const TaskLauncherComponent = () => {
  const [query, setQuery] = useState("");
  const [selectedSystem, setSelectedSystem] = useState([]);
  const [selectedSystemObject, setSelectedSystemObject] = useState(null);
  const [selectedSystemInputSchema, setSelectedSystemInputSchema] =
    useState(null);
  const [selectOpen, setSelectOpen] = useState(false);
  const [aiSystems, setSystems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently, user } = useAuth0();
  const { message, notification } = App.useApp();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      // decode and get obj
      const decodedToken = parseJwt(token);

      // get email from aetherx.ai/claims/email
      setUserEmail(decodedToken["aetherx.ai/claims/email"]);
    });
  }, []);

  // Function to generate taskId
  const generateTaskId = (systemId) => {
    const now = new Date();
    const datePart = `${now.getFullYear()}${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}`;
    const timePart = `${now.getHours().toString().padStart(2, "0")}${now
      .getMinutes()
      .toString()
      .padStart(2, "0")}${now.getSeconds().toString().padStart(2, "0")}`;
    return `${userEmail}.${systemId}-${datePart}-${timePart}`;
  };

  const inputRef = useRef(null);
  const selectRef = useRef(null);

  const fetchSelectedSystem = async (system) => {
    const accessToken = await getAccessTokenSilently();
    const api = new AiSystemsApi(
      new Configuration({
        accessToken: accessToken,
        basePath: apiBasePath,
      }),
    );

    try {
      const response =
        await api.getAiSystemCompleteV1AiSystemsAiSystemIdCompleteGet(system);
      setSelectedSystemObject(response.data);
      setSelectedSystemInputSchema(response.data.extra_inputs_schema);
      console.log(selectedSystemInputSchema);
    } catch (e) {
      message.error("Error fetching AI System");
      console.error(e);
    }
  };

  useEffect(() => {
    const fetchSystems = async () => {
      const accessToken = await getAccessTokenSilently();
      const api = new AiSystemsApi(
        new Configuration({
          accessToken: accessToken,
          basePath: apiBasePath,
        }),
      );

      try {
        const response = await api.getAiSystemsV1AiSystemsGet();
        setSystems(response.data);
        setLoading(false);
      } catch (error) {
        console.log("Api error: ", error);
        message.error("Could not fetch systems: " + error);
        setLoading(false);
      }
    };
    fetchSystems();
  }, []);

  const handleCreateAndInvoke = async (systemId, variables) => {
    const accessToken = await getAccessTokenSilently();
    const api = new AiTasksApi(
      new Configuration({
        accessToken: accessToken,
        basePath: apiBasePath,
      }),
    );

    const taskId = generateTaskId(systemId);

    try {
      await api.createAiTaskV1AiTasksPost({
        ai_task_id: taskId,
        ai_system_id: systemId,
      });
      await api.invokeV1AiTasksTaskIdInvokePost(taskId, {
        messages: [
          {
            role: "user",
            content: query,
            type: "chat",
          },
        ],
        variables: variables,
      });
      notification.info({
        message: `Task for "${selectedSystemObject.name}" created successfully`,
        description: "Click here to view it",
        key: taskId,
        onClick: () => {
          navigate("/tasks/view", { state: { taskId: taskId } });
          notification.destroy(taskId);
        },
      });
      // Clear the form and query after successful submission
      setQuery("");
      setFormData({});
    } catch (error) {
      if (error.response?.status === 402) {
        message.error(
          "Cannot invoke task: insufficient credit balance. Please contact support.",
        );
        console.error("Error:", error);
      } else {
        console.error("Error:", error);
      }
    }
  };

  const handleSelectChange = (value) => {
    setSelectedSystem(value);
    fetchSelectedSystem(value);
  };

  const handleSubmitForm = async ({ formData }) => {
    console.log(formData);
    await handleCreateAndInvoke(selectedSystem, formData);
  };

  const handleSubmitNoForm = async () => {
    await handleCreateAndInvoke(selectedSystem, {});
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      if (selectedSystemInputSchema) {
        handleSubmitForm({ formData });
      } else {
        handleSubmitNoForm();
      }
    }
  };

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      if (formRef.current.validateForm()) {
        handleSubmitForm({ formData });
      }
    }
  };

  const widgets = generateWidgets();
  widgets.TextWidget = widgets.TextareaWidget;

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <div style={{ height: "100vh", overflowY: "auto", padding: "16px" }}>
        <Content
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Image
            style={{ width: 400, marginTop: "5vh", marginBottom: "40px" }}
            preview={false}
            src={getLogo()}
          />
          <div
            style={{ width: "100%", maxWidth: "600px", marginBottom: "45vh" }}
          >
            <Space
              direction={"vertical"}
              justify={"space-around"}
              style={{ width: "100%" }}
              size={"large"}
            >
              {loading ? (
                <>
                  <Typography.Text>Loading AI Systems...</Typography.Text>
                  <Spin />
                </>
              ) : (
                <>
                  <Select
                    showSearch
                    placeholder={"Select AI System"}
                    open={selectOpen}
                    onSelect={handleSelectChange}
                    onDropdownVisibleChange={setSelectOpen}
                    ref={selectRef}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={aiSystems.map((system) => ({
                      label: system.name,
                      value: system.id,
                    }))}
                    style={{ width: "100%" }}
                  />
                  <Typography.Text>
                    {selectedSystemObject?.description}
                  </Typography.Text>
                  {selectedSystemObject && (
                    <>
                      <TextArea
                        placeholder="Type the input for your task here..."
                        autoSize={{ minRows: 2 }}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        style={{ marginTop: "20px" }}
                      />
                    </>
                  )}
                  <div>
                    {selectedSystemInputSchema && (
                      <>
                        <Typography.Title
                          level={5}
                          style={{ marginTop: "20px" }}
                        >
                          Additional Inputs
                        </Typography.Title>
                        {/* Wrap the Form with a div that has the onKeyDown handler */}
                        <div onKeyDown={handleFormKeyDown}>
                          <Form
                            schema={selectedSystemInputSchema}
                            id={"task-start-form"}
                            onSubmit={handleSubmitForm}
                            formData={formData}
                            onChange={(e) => setFormData(e.formData)}
                            validator={validator}
                            widgets={widgets}
                            ref={formRef}
                            uiSchema={{
                              accepted: {},
                              request_id: {
                                "ui:disabled": true,
                                "ui:widget": "hidden",
                              },
                              connection_id: {
                                "ui:disabled": true,
                                "ui:widget": "hidden",
                              },
                              request_type: {
                                "ui:disabled": true,
                                "ui:widget": "hidden",
                              },
                              timestamp: {
                                "ui:disabled": true,
                                "ui:widget": "hidden",
                              },
                              text: {
                                "ui:title": "Response text",
                                "ui:widget": "textarea",
                                "ui:autoFocus": true,
                                "ui:placeholder": "Enter your response here",
                              },
                              answers: {
                                "ui:title": "Answers",
                              },
                            }}
                          >
                            <Typography.Text
                              type="secondary"
                              style={{ fontSize: 12 }}
                            >
                              Press <strong>⌘+Enter</strong> or{" "}
                              <strong>CTRL+Enter</strong> to submit.
                            </Typography.Text>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SendOutlined />}
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </>
                    )}
                    {selectedSystemObject && !selectedSystemInputSchema && (
                      <>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: 12 }}
                        >
                          Press <strong>CMD+Enter</strong> or{" "}
                          <strong>CTRL+Enter</strong> to submit.
                        </Typography.Text>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "10px",
                          }}
                        >
                          <Button
                            onClick={handleSubmitNoForm}
                            type="primary"
                            icon={<SendOutlined />}
                          >
                            Submit
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </Space>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default TaskLauncherComponent;
